import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '@app/hooks';

import { InfoBlock, Input } from '@app/ui-kit';
import { useServiceState } from '@app/common/state';
import { apiService, userService } from '@app/services';
import {
  BackButton,
  BottomButton,
  ConfirmNicknameBottomSheet,
} from '@app/components';

import s from './ChangeNicknamePage.module.scss';

export const ChangeNicknamePage: React.FC = () => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const [nickname, setNickname] = useState<string | undefined>(
    userProfile?.nickname,
  );
  const [isAvailable, setIsAvailable] = useState(false);
  const [errors, setErrors] = useState({ client: '', server: '' });
  const [isChecking, setIsChecking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const hasErrors = Object.values(errors).some((value) => !!value);
  const submitDisabled =
    hasErrors ||
    isChecking ||
    isSubmitting ||
    !isAvailable ||
    nickname === userProfile?.nickname;

  const debouncedCheckNickname = useDebounce(async () => {
    if (!nickname || nickname === userProfile?.nickname) {
      return;
    }

    setIsChecking(true);
    setIsAvailable(false);

    try {
      const available = await userService.checkNicknameAvailability(nickname);

      setIsAvailable(available);
      setInfoMessage(`Cool! <b>${nickname}</b> is available`);

      setErrors((prev) => ({
        ...prev,
        server: available
          ? ''
          : `Account with nickname <b>${nickname}</b> already exists, please choose another one`,
      }));
    } catch (e) {
      const { formatted } = apiService.getErrorMessage(e);

      setErrors((prev) => ({
        ...prev,
        server: formatted,
      }));
    } finally {
      setIsChecking(false);
    }
  });

  const handleChange = (val: string) => {
    const trimmed = val.trim();

    setIsAvailable(false);

    if (trimmed === userProfile?.nickname) {
      setNickname(trimmed);
      setErrors({ client: '', server: '' });

      return;
    }

    setNickname(trimmed);

    const errorMessage = validateNickname(trimmed);

    setErrors({ server: '', client: errorMessage });

    if (errorMessage) {
      return;
    }

    debouncedCheckNickname();
  };

  const handleSubmit = async () => {
    if (
      hasErrors ||
      !nickname ||
      !isAvailable ||
      nickname === userProfile?.nickname
    ) {
      return;
    }

    setIsSubmitting(true);

    try {
      await userService.changeNickname(nickname);
      setErrors((prev) => ({ ...prev, server: '' }));

      navigate(-1);
    } catch (e) {
      const { formatted } = apiService.getErrorMessage(e);

      setErrors((prev) => ({
        ...prev,
        server: formatted,
      }));

      console.error(e);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (hasErrors) {
      setIsAvailable(false);
      setInfoMessage('');
    }
  }, [hasErrors]);

  return (
    <div className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.padding}>
        <Input
          value={nickname}
          errorMessage={errors.client || errors.server}
          infoMessage={
            isAvailable && nickname && !hasErrors && infoMessage
              ? infoMessage
              : undefined
          }
          className={s.input}
          onChange={handleChange}
        />
        <InfoBlock
          message={
            <div>
              <b>Your nickname will be visible to other players</b>
              <ul className={s.list}>
                <li>Use only letters, numbers, - and _</li>
                <li>Minimum 3 characters</li>
                <li>Maximum 12 characters</li>
                <li>Can’t begin with - or _</li>
              </ul>
            </div>
          }
          variant="info"
          backgroundColor="var(--action-secondary-base-default)"
          className={s.info}
        />
      </div>

      <BottomButton
        label="Change nickname"
        disabled={submitDisabled}
        className={s.bottomButton}
        onClick={() => setIsOpen(true)}
      />
      <ConfirmNicknameBottomSheet
        nickname={nickname}
        onClose={setIsOpen}
        onConfirm={handleSubmit}
        isLoading={isSubmitting}
        isOpen={isOpen}
      />
    </div>
  );
};

const validateNickname = (val: string): string => {
  if (val.trim() === '') {
    return 'Can’t be empty!';
  }

  if (val.length < 3) {
    return 'Must be at least 3 characters!';
  }

  if (val.length > 12) {
    return 'Must not exceed 12 characters!';
  }

  if (!/^[a-zA-Z0-9_-]+$/.test(val)) {
    return 'Only letters, numbers, hyphens, and underscores are allowed!';
  }

  if (/^[-_]/.test(val)) {
    return 'Cannot begin with - or _!';
  }

  return '';
};
