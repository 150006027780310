import cn from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { customScrollTo } from '@app/utils';
import {
  PhaseSelectCard,
  TournamentsHeroBlock,
  WalletConnectBottomSheet,
  WithdrawCryptoPrizeWidget,
} from '@components';
import { ReactComponent as ArrowIcon } from '@icons/arrow-right.svg';
import { useServiceState } from '@app/common/state';
import { userService, versusTournamentService } from '@app/services';
import { useTonConnect } from '@app/hooks';

import s from './TournamentOverviewContent.module.scss';

interface TournamentOverviewContentProps {
  className?: string;
}

const SCROLL_START_DELAY_MS = 300;
const SCROLL_TIME_MS = 3500;
const TARGET_ID = 'target-phase';

export const TournamentOverviewContent: React.FC<
  TournamentOverviewContentProps
> = ({ className }) => {
  const { tournamentData, currentStage, availableRewards } = useServiceState(
    versusTournamentService,
    ['tournamentData', 'currentStage', 'availableRewards'],
  );
  const { userProfile } = useServiceState(userService, ['userProfile']);

  const { wallet } = useTonConnect();
  const [showConnect, setShowConnect] = useState(false);
  const stages = tournamentData?.stages;
  const navigate = useNavigate();

  useEffect(() => {
    if (!wallet?.account.address) {
      setShowConnect(true);
    } else {
      setShowConnect(false);
    }
  }, [wallet]);

  useEffect(() => {
    const storageKeyName = 'tournamentOverviewScrolled';
    const hasVisited = localStorage.getItem(storageKeyName);
    const shouldScroll = tournamentData && wallet;

    if (!shouldScroll) {
      return;
    }

    const scrollWithDelay = (scrollTime: number) => {
      const timer = setTimeout(() => {
        const targetElement = document.getElementById(TARGET_ID);

        if (targetElement) {
          customScrollTo(targetElement, scrollTime);

          if (!hasVisited) {
            localStorage.setItem(storageKeyName, 'true');
          }
        }
      }, SCROLL_START_DELAY_MS);

      return timer;
    };

    const scrollTime = hasVisited
      ? 600
      : Math.round(SCROLL_TIME_MS / (currentStage || 1));

    const timer = scrollWithDelay(scrollTime);

    return () => clearTimeout(timer);
  }, [currentStage, tournamentData, wallet]);

  return (
    <div className={cn(s.root, className)}>
      <TournamentsHeroBlock />

      <WithdrawCryptoPrizeWidget
        items={availableRewards}
        isSuspicious={userProfile?.isSuspicious}
      />

      {(!tournamentData || !tournamentData.stages) && (
        <h1 className={cn(s.title, s.noTournament)}>NO ACTIVE TOURNAMENT</h1>
      )}
      <div className={s.phases}>
        {stages
          ?.sort((a, b) => b.stage - a.stage)
          .map((el, i) => (
            <Fragment key={el.title}>
              <PhaseSelectCard
                data={el}
                canChangeChar={tournamentData?.canChangeCharacter}
                scrollTargetId={TARGET_ID}
                claimableTonAmount={tournamentData?.claimableTonAmount || 0}
              />
              {i !== stages.length - 1 && <ArrowIcon className={s.arrow} />}
            </Fragment>
          ))}
      </div>
      <WalletConnectBottomSheet
        title="Connect wallet"
        description="Connect your TON wallet to participate in Slap Tournaments"
        backButton="Go back"
        isOpen={showConnect}
        onClose={() => navigate('/')}
        onBack={() => navigate('/')}
      />
    </div>
  );
};
