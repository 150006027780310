import cn from 'classnames';

import { VersusPriceType } from '@app/types';
import { VERSUS_ITEM_PRICE } from '@app/constants/versus';
import { numberWithCommas } from '@app/utils';
import { fromNano } from '@ton/core';

import s from './VersusPriceTag.module.scss';

interface VersusPriceTagProps {
  price: number;
  priceFull?: number;
  priceTon?: string;
  priceType?: VersusPriceType;
  size?: 'normal' | 'big';
  iconSize?: number;
  className?: string;
  tagClassName?: string;
}

export const VersusPriceTag: React.FC<VersusPriceTagProps> = ({
  price,
  priceFull,
  priceTon,
  priceType = VersusPriceType.Ton,
  size = 'normal',
  iconSize = 16,
  className,
  tagClassName,
}) => {
  const { icon, textColor, backgroundColor } = VERSUS_ITEM_PRICE[priceType];

  return (
    <div
      className={cn(
        s.root,
        { [s.stars]: priceType === VersusPriceType.Stars },
        s[size],
        className,
      )}
    >
      {priceFull !== undefined && (
        <div
          className={cn(s.tag, s.discounted, tagClassName)}
          style={{ backgroundColor, color: textColor }}
        >
          <img
            src={icon}
            height={`${iconSize}px`}
            width={`${iconSize}px`}
            alt={priceType}
          />
          <div>{numberWithCommas(priceFull)}</div>
        </div>
      )}
      {priceType === VersusPriceType.Stars && <div>or</div>}
      <div
        className={cn(s.tag, tagClassName)}
        style={{ backgroundColor, color: textColor }}
      >
        <img
          src={icon}
          height={`${iconSize}px`}
          width={`${iconSize}px`}
          alt={priceType}
        />
        <div>{numberWithCommas(priceTon ? fromNano(priceTon) : price)}</div>
      </div>
    </div>
  );
};
