import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { BadgePoints, VersusAvatar, VersusLeagueTag } from '@components';
import { userService, versusCharacterService } from '@app/services';
import { useServiceState } from '@app/common/state';
import { VersusUserAssetType } from '@app/types';
import { ReactComponent as SettingsIcon } from '@icons/settings.svg';

import s from './VersusHeader.module.scss';

interface VersusHeaderProps {
  withShadow?: boolean;
  variant?: 'default' | 'tournament' | 'shop-medals';
  className?: string;
}

export const VersusHeader: React.FC<VersusHeaderProps> = ({
  withShadow = true,
  variant = 'default',
  className,
}) => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const userName = userProfile?.nickname ?? userProfile?.username;
  const tomatoes = userProfile?.score ?? 0;
  const versus = userProfile?.versus;
  const cucumbers = versus?.score ?? 0;
  const league = versus?.rating.leagueName ?? '';
  const leagueBadge = versus?.rating.leagueIcon ?? '';
  const leagueRank = versus?.rating.leagueRank ?? 0;

  const playerAssets = versusCharacterService.generateCharAssets(
    versus?.character.code,
  );

  const medals =
    versus?.assets.find((el) => el.type === VersusUserAssetType.Medals)
      ?.amount || 0;

  const tickets =
    versus?.assets.find((el) => el.type === VersusUserAssetType.SilverTicket)
      ?.amount || 0;

  return (
    <div className={cn(s.root, { [s.withShadow]: withShadow }, className)}>
      <VersusAvatar
        wormImage={playerAssets?.avatarSm || ''}
        leagueImage={leagueBadge}
        className={s.avatar}
      />
      <div className={s.info}>
        <div className={s.userName}>@{userName}</div>
        <VersusLeagueTag
          place={leagueRank}
          leagueName={league}
          onClick={() => navigate('/league-leaderboard')}
        />
      </div>
      <div className={s.points}>
        {variant === 'default' && (
          <>
            <BadgePoints value={tomatoes} variant="header-tomato" />
            <BadgePoints value={cucumbers} variant="header-cucumber" />
          </>
        )}
        {variant === 'tournament' && (
          <>
            <BadgePoints value={tickets} variant="header-silver-tickets" />
            <BadgePoints value={medals} variant="header-medals" />
          </>
        )}
        {variant === 'shop-medals' && (
          <>
            <BadgePoints value={tomatoes} variant="header-tomato" />
            <BadgePoints value={medals} variant="header-medals" />
          </>
        )}
      </div>
      <SettingsIcon
        width={24}
        height={24}
        color="var(--text-secondary)"
        onClick={() => navigate('/profile-settings')}
      />
    </div>
  );
};
