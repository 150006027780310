import { useNavigate } from 'react-router-dom';

import {
  Page,
  SlapVersusMenu,
  BackButton,
  VersusHeader,
  PlayWormAvatar,
} from '@components';
import { configService, onboardingService } from '@app/services';
import { Button } from '@app/ui-kit';
import { isIOS } from '@app/utils';
import { useHideBackButton } from '@app/hooks';

import s from './SlapVersusPage.module.scss';

export const SlapVersusPage = () => {
  useHideBackButton();

  const navigate = useNavigate();

  return (
    <Page className={s.root} style={{ paddingBottom: isIOS() ? 110 : 95 }}>
      <BackButton onClick={() => navigate('/')} />
      <VersusHeader />
      {configService.env !== 'production' && (
        <div className={s.devHeader}>
          <Button
            variant="unfilled"
            onClick={() => onboardingService.devResetVersusData()}
          >
            Reset
          </Button>
          <Button
            variant="unfilled"
            onClick={() => onboardingService.devCompleteOnboarding()}
          >
            Complete onboarding
          </Button>
          <Button
            variant="unfilled"
            onClick={() => onboardingService.devAddRating(100)}
          >
            Add rank progress
          </Button>
        </div>
      )}
      <PlayWormAvatar className={s.wormAvatar} />
      <SlapVersusMenu className={s.menu} />
    </Page>
  );
};
