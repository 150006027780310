import cn from 'classnames';

import { TournamentLeaderBoardTable, TournamentWinner } from '@components';
import comingHeroImage from '@media/tournament-results-coming.png';
import { useServiceState } from '@app/common/state';
import { versusTournamentService } from '@app/services';

import s from './TournamentResultContent.module.scss';
import { useEffect } from 'react';
import { Loader, Spinner } from '@app/ui-kit';

interface TournamentResultContentProps {
  className?: string;
}

export const TournamentResultContent: React.FC<
  TournamentResultContentProps
> = ({ className }) => {
  const { pastWinners, cryptoRewardAmount } = useServiceState(
    versusTournamentService,
    ['pastWinners', 'cryptoRewardAmount'],
  );

  useEffect(() => {
    if (!pastWinners.length) {
      versusTournamentService.getTournamentPastWinnersStat();
    }
  }, [pastWinners]);

  const firstPlace = pastWinners[0];
  const secondPlace = pastWinners[1];
  const thirdPlace = pastWinners[2];

  return (
    <div className={cn(s.root, className)}>
      {!pastWinners.length ? (
        <Spinner className={s.loader} thickness={10} />
      ) : (
        <>
          <h1 className={s.title}>Yesterday’s Winners</h1>
          <p className={s.description}>
            Meet the champions who grabbed {cryptoRewardAmount} TON and more in
            Slap Tournaments!
          </p>
          <div className={s.winners}>
            {secondPlace && (
              <TournamentWinner
                charCode={secondPlace.character.code}
                accessoryCode={secondPlace.character.accessories?.[0]}
                cryptoAmount={secondPlace.tonAmount}
                place={2}
                name={secondPlace.name}
              />
            )}
            {firstPlace && (
              <TournamentWinner
                charCode={firstPlace.character.code}
                accessoryCode={firstPlace.character.accessories?.[0]}
                cryptoAmount={firstPlace.tonAmount}
                place={1}
                name={firstPlace.name}
              />
            )}
            {thirdPlace && (
              <TournamentWinner
                charCode={thirdPlace.character.code}
                accessoryCode={thirdPlace.character.accessories?.[0]}
                cryptoAmount={thirdPlace.tonAmount}
                place={3}
                name={thirdPlace.name}
              />
            )}
          </div>
          {pastWinners && pastWinners.length > 0 && (
            <TournamentLeaderBoardTable
              winners={pastWinners}
              className={s.table}
            />
          )}
        </>
      )}
    </div>
  );
};
