import cn from 'classnames';

import s from './Spinner.module.scss';

interface SpinnerProps {
  color?: string;
  thickness?: number;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color = 'var(--text-primary)',
  thickness = 4,
  className,
}) => {
  return (
    <div
      className={cn(s.root, className)}
      style={{ color, borderWidth: thickness }}
    >
      <div style={{ borderWidth: thickness }} />
      <div style={{ borderWidth: thickness }} />
    </div>
  );
};
