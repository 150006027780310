import iconHome from '@app/assets/icons/nav/home.png';
import iconQuests from '@app/assets/icons/nav/quests.png';
import iconPlay from '@app/assets/icons/nav/play.png';
import iconShop from '@app/assets/icons/nav/shop.png';
import iconWin from '@assets/icons/nav/win.png';
import iconSettings from '@app/assets/icons/nav/settings.svg';

import { ShopPageTabs } from '@app/pages/shop/ShopPage';

export const BottomNavItem = [
  {
    label: 'Home',
    icon: iconHome,
    path: '/',
    marginTop: '16px',
  },
  {
    label: 'Shop',
    icon: iconShop,
    path: `/shop?tab=${ShopPageTabs.default}`,
    marginTop: '8px',
  },
  {
    label: 'Play',
    icon: iconPlay,
    path: '/play',
    marginTop: '4px',
  },
  {
    label: 'Win!',
    icon: iconWin,
    path: '/tournament?tab=phase',
    marginTop: '8px',
  },
  {
    label: 'Quests',
    icon: iconQuests,
    path: '/quests',
    marginTop: '16px',
  },
];

export const SideNavItem = [
  {
    label: '',
    icon: iconSettings,
    path: '/',
    bgColor: '#322A25',
  },
];
