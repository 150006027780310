import cn from 'classnames';

import {
  ShopItemType,
  VersusPriceType,
  VersusSkillUpgradable,
} from '@app/types';
import {
  DoubleProgressBar,
  ShopPurchaseButtons,
  VersusPriceTag,
  WormPreviewSkills,
} from '@components';

import { VERSUS_SKILLS } from '@app/constants/versus';

import s from './PurchaseWormBlock.module.scss';

interface PurchaseWormBlockProps {
  code: string;
  logo: string;
  price: number;
  priceFull?: number;
  priceTon: string | undefined;
  priceStars?: number;
  priceType: VersusPriceType;
  startSkills: VersusSkillUpgradable[];
  className?: string;
}

export const PurchaseWormBlock: React.FC<PurchaseWormBlockProps> = ({
  code,
  logo,
  startSkills,
  price,
  priceStars,
  priceFull,
  priceTon,
  priceType,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <img src={logo} alt={code} className={s.logo} />
      <WormPreviewSkills skills={startSkills} className={s.skills} />
      <VersusPriceTag
        price={price}
        priceTon={priceTon}
        priceFull={priceFull}
        priceType={priceType}
        size="big"
      />
      <ShopPurchaseButtons
        code={code}
        price={price}
        priceTon={priceTon}
        priceType={priceType}
        priceStars={priceStars}
        className={s.buttons}
        itemType={ShopItemType.worm}
      />
    </div>
  );
};
