import { useNavigate } from 'react-router-dom';

import {
  BackButton,
  ProfileSettingsNicknameItem,
  ProfileSettingsWalletItem,
  SettingsListCard,
  SettingsListItem,
} from '@app/components';

import tomatoIcon from '@media/tomato_coin.png';
import cucumbersIcon from '@media/cucumber-icon.png';
import ticketIcon from '@media/phase-silver-ticket.png';
import medalIcon from '@media/medal.png';
import otherIcon from '@icons/nav/play.png';
import { numberWithCommas } from '@app/utils';
import { useServiceState } from '@app/common/state';
import { userService } from '@app/services';
import { VersusUserAssetType } from '@app/types';
import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg';

import s from './ProfileSettingsPage.module.scss';

export const ProfileSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const tomatoes = userProfile?.score || 0;
  const versus = userProfile?.versus;
  const cucumbers = versus?.score || 0;
  const medals =
    versus?.assets.find((el) => el.type === VersusUserAssetType.Medals)
      ?.amount || 0;
  const tickets =
    versus?.assets.find((el) => el.type === VersusUserAssetType.SilverTicket)
      ?.amount || 0;

  const accountSettings = [
    <ProfileSettingsNicknameItem nickname={userProfile?.nickname} />,
    <ProfileSettingsWalletItem />,
  ];

  const balances = [
    <SettingsListItem
      image={tomatoIcon}
      title={'Tomatoes'}
      value={numberWithCommas(tomatoes)}
    />,
    <SettingsListItem
      image={cucumbersIcon}
      title={'Cucumbers'}
      value={numberWithCommas(cucumbers)}
    />,
    <SettingsListItem
      image={ticketIcon}
      title={'Tournament Tickets'}
      value={numberWithCommas(tickets)}
    />,
    <SettingsListItem
      image={medalIcon}
      title={'Medals'}
      value={numberWithCommas(medals)}
    />,
  ];

  return (
    <div className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <SettingsListCard title="Account Settings" children={accountSettings} />
      <SettingsListCard title="Balances" children={balances} />
    </div>
  );
};
