import {
  PhaseEntryTicket,
  TournamentRewardType,
  VersusAccessoryBonusType,
  VersusPriceType,
  VersusWssSkill,
} from '@app/types';
import iconPower from '@media/skills/power.png';
import iconReaction from '@media/skills/reaction.png';
import iconResilience from '@media/skills/resilience.png';
import tomatoIcon from '@media/tomato_coin.png';
import cucumberIcon from '@media/cucumber-icon.png';
import tonIcon from '@media/ton-icon.png';
import tgStarIcon from '@media/telegram-star.png';
import goldenTicket from '@media/phase-golden-ticket.png';
import silverTicket from '@media/phase-silver-ticket.png';
import diamondTicket from '@media/phase-diamond-ticket.png';
import rewardMedals from '@media/phase-reward-medals.png';
import medalIcon from '@media/medal.png';
import cryptoCoinReward from '@media/ton-phase-reward.png';

export const VERSUS_SKILLS: Record<
  VersusWssSkill,
  { icon: string; name: string }
> = {
  [VersusWssSkill.Damage]: { icon: iconPower, name: 'Power' },
  [VersusWssSkill.Reaction]: {
    icon: iconReaction,
    name: 'Reaction',
  },
  [VersusWssSkill.Resilience]: {
    icon: iconResilience,
    name: 'Resilience',
  },
};

export const VERSUS_ITEM_BONUS = {
  [VersusAccessoryBonusType.Cucumbers]: { image: cucumberIcon },
  [VersusAccessoryBonusType.Tomatoes]: { image: tomatoIcon },
};

export const VERSUS_ITEM_PRICE = {
  [VersusPriceType.Tomato]: {
    icon: tomatoIcon,
    textColor: 'var(--text-primary)',
    backgroundColor: 'var(--decorative-action-red)',
  },
  [VersusPriceType.Ton]: {
    icon: tonIcon,
    textColor: '#fff',
    backgroundColor: '#08C',
  },
  [VersusPriceType.Stars]: {
    icon: tgStarIcon,
    textColor: 'var(--text-secondary)',
    backgroundColor: 'transparent',
  },
  [VersusPriceType.Medals]: {
    icon: medalIcon,
    textColor: 'var(--text-on-actions-dark)',
    backgroundColor: 'var(--text-accent-orange)',
  },
};

export const VERSUS_PHASE_REWARDS: Record<
  TournamentRewardType,
  { title: string; image: string }
> = {
  [TournamentRewardType.DiamondTicket]: {
    title: 'Diamond Ticket',
    image: diamondTicket,
  },
  [TournamentRewardType.GoldenTicket]: {
    title: 'Golden Ticket',
    image: goldenTicket,
  },
  [TournamentRewardType.Medals]: { title: 'Medals', image: rewardMedals },
  [TournamentRewardType.Ton]: { title: 'TON', image: cryptoCoinReward },
};

export const VERSUS_PHASE_ENTRY: Record<
  PhaseEntryTicket,
  { title: string; image: string; shopCode: string }
> = {
  [PhaseEntryTicket.SilverTicket]: {
    image: silverTicket,
    title: 'Silver Ticket',
    shopCode: 'silvertickets',
  },
  [PhaseEntryTicket.GoldenTicket]: {
    image: goldenTicket,
    title: 'Golden Ticket',
    shopCode: 'goldentickets',
  },
  [PhaseEntryTicket.DiamondTicket]: {
    image: diamondTicket,
    title: 'Diamond Ticket',
    shopCode: 'diamondtickets',
  },
};
