import cn from 'classnames';

import { ShopItemType, VersusPriceType } from '@app/types';
import { ShopPurchaseButtons, VersusPriceTag } from '@components';

import s from './PurchaseItemBlock.module.scss';

interface PurchaseItemBlockProps {
  itemId: string;
  itemName: string;
  price: number;
  priceFull?: number;
  priceTon: string | undefined;
  priceType: VersusPriceType;
  priceStars?: number;
  className?: string;
}

export const PurchaseItemBlock: React.FC<PurchaseItemBlockProps> = ({
  itemId,
  itemName,
  price,
  priceFull,
  priceTon,
  priceStars,
  priceType,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <h3 className={s.name}>{itemName}</h3>
      <VersusPriceTag
        price={price}
        priceTon={priceTon}
        priceFull={priceFull}
        priceType={VersusPriceType.Ton}
        size="big"
      />
      <ShopPurchaseButtons
        code={itemId}
        price={price}
        priceTon={priceTon}
        priceType={priceType}
        priceStars={priceStars}
        className={s.buttons}
        itemType={ShopItemType.item}
      />
    </div>
  );
};
