import React, { useMemo } from 'react';
import cn from 'classnames';

import { VersusWssSkill } from '@app/types';
import { VERSUS_SKILLS } from '@app/constants/versus';

import s from './CircularSkillProgress.module.scss';

interface CircularSkillProgressProps {
  className?: string;
  currentSkillLevel: number;
  currentMaxLevel: number;
  generalMaxLevel: number;
  skill: VersusWssSkill;
}

export const CircularSkillProgress: React.FC<CircularSkillProgressProps> = ({
  className,
  currentSkillLevel,
  currentMaxLevel,
  generalMaxLevel,
  skill,
}) => {
  const currentProgress = (currentSkillLevel / generalMaxLevel) * 100;
  const currentMaxProgress = (currentMaxLevel / generalMaxLevel) * 100;

  const gradientStyle = useMemo(() => {
    return {
      '--progress-gradient': `radial-gradient(
      closest-side,
      var(--surface-bg) 79%,
      transparent 81% 100%
    ),
    conic-gradient(
        var(--border-accent) ${currentProgress}%,
        var(--surface-secondary) ${currentProgress}% ${currentMaxProgress}%,
        var(--border-on-action) 0%
      )`,
    } as React.CSSProperties;
  }, [currentProgress, currentMaxProgress]);

  return (
    <div className={cn(s.root, className)}>
      <div className={s.circle} style={gradientStyle}>
        <img src={VERSUS_SKILLS[skill].icon} alt={skill} className={s.icon} />
      </div>
    </div>
  );
};
