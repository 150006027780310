import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  TournamentMatch,
  TournamentRewardType,
  VersusMatchOutcome,
  VersusTournament,
} from '@app/types';
import medal from '@media/medal.png';
import foregroundImg from '@media/matchmaking-foreground.png';
import unknownWormImg from '@media/unknown-worm.png';
import {
  versusCharacterService,
  versusService,
  versusShopService,
  versusTournamentService,
} from '@app/services';
import { Button } from '@app/ui-kit';
import victoryTextImg from '@media/battle-result/victory-text.png';
import defeatTextImg from '@media/battle-result/defeat-text.png';
import tieTextImg from '@media/battle-result/tie-text.png';
import { TournamentStayInPhase } from '@components';

import s from './TournamentPhaseBattleCard.module.scss';
import { useServiceState } from '@app/common/state';

interface TournamentPhaseBattleCardProps {
  index: number;
  totalMatches: number;
  currentStage: number;
  matchData: TournamentMatch;
  tournamentData: VersusTournament | null;
  isActive: boolean;
  scrollTargetId: string;
  className?: string;
}

export const TournamentPhaseBattleCard: React.FC<
  TournamentPhaseBattleCardProps
> = ({
  index,
  totalMatches,
  currentStage,
  matchData,
  tournamentData,
  isActive,
  scrollTargetId,
  className,
}) => {
  const navigate = useNavigate();
  const { purchaseInProgress } = useServiceState(versusShopService, [
    'purchaseInProgress',
  ]);

  if (!tournamentData) {
    return null;
  }

  const stageData = versusTournamentService.getStageDataById(currentStage);
  const totalMedals = stageData?.rewards.find(
    (el) => el.type === TournamentRewardType.Medals,
  )?.amount;
  const medalsPerFight = totalMedals ? totalMedals / totalMatches : 0;

  const prevCardIndex = Math.abs(index - 1);
  const userWormCode = tournamentData?.character?.code;
  const enemyWormCode = matchData?.enemy?.code;
  const shouldShowDescription = matchData?.outcome == null && !isActive;
  const isStart = isActive && !matchData?.outcome;
  const isRetry = isActive && matchData?.outcome === VersusMatchOutcome.Draw;
  const isDefeat =
    matchData?.outcome === VersusMatchOutcome.Defeat ||
    matchData?.outcome === VersusMatchOutcome.Retreat;
  const isVictory = matchData?.outcome === VersusMatchOutcome.Victory;
  const shouldShowWorms = isStart || isRetry || isDefeat || isVictory;

  const userWormAssets =
    versusCharacterService.generateCharAssets(userWormCode);
  const opponentWormAssets =
    versusCharacterService.generateCharAssets(enemyWormCode);

  const handleButtonClick = async () => {
    if (isStart || isRetry) {
      startMatch();
    }

    if (isDefeat) {
      navigate('/');
      await versusTournamentService.getTournament();
    }
  };

  const startMatch = () => {
    versusService.connectToServer(() => {
      versusService.enableTournamentMode();
      versusTournamentService.setCurrentMatchNumber(index);

      versusService.initGame();
    });
  };

  return (
    <div
      className={cn(s.root, { [s.isDefeat]: isDefeat }, className)}
      id={isActive ? scrollTargetId : undefined}
    >
      {(isDefeat || isVictory) && (
        <div
          className={cn(s.background, {
            [s.redBg]: isDefeat,
            [s.greenBg]: isVictory,
          })}
        />
      )}
      <h4 className={s.title}>Battle {index}</h4>
      <div className={s.reward}>
        <img src={medal} alt="medal" />
        <span>{medalsPerFight}</span>
      </div>
      {shouldShowDescription && (
        <div className={s.description}>
          Deal with Battle {prevCardIndex} to enter this one
        </div>
      )}

      {shouldShowWorms && (
        <>
          <img
            src={userWormAssets?.avatarLg}
            alt={`${enemyWormCode} avatar`}
            className={cn(s.avatar, s.user)}
          />
          <img
            src={opponentWormAssets?.avatarLg ?? unknownWormImg}
            alt={`${userWormCode} avatar`}
            className={cn(s.avatar, s.opponent)}
          />
          <div className={s.spacer} />
          <img
            src={foregroundImg}
            className={s.foregroundImg}
            alt="foreground"
          />
        </>
      )}
      {isVictory && (
        <img
          src={victoryTextImg}
          className={cn(s.statusImage, s.victory)}
          alt="tie"
        />
      )}
      {isDefeat && (
        <>
          <img
            src={defeatTextImg}
            className={cn(s.statusImage, s.defeat)}
            alt="tie"
          />
          <div className={s.retreatText}>
            {currentStage === 1
              ? 'You’re out, but don’t worry, you can start again'
              : 'Would you like to save your spot in this phase?'}
          </div>
          <TournamentStayInPhase className={s.stayInPhase} />
          <Button
            variant="secondary"
            disabled={purchaseInProgress}
            onClick={handleButtonClick}
            className={s.button}
          >
            Return to Home screen
          </Button>
        </>
      )}
      {isRetry && (
        <>
          <img
            src={tieTextImg}
            className={cn(s.statusImage, s.tie)}
            alt="tie"
          />
          <Button onClick={handleButtonClick} className={s.button}>
            Retry
          </Button>
        </>
      )}
      {isStart && (
        <Button onClick={handleButtonClick} className={s.button}>
          Start
        </Button>
      )}
    </div>
  );
};
