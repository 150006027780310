import React from 'react';
import clsx from 'clsx';

import { Button } from '@ui-kit';

import { ReactComponent as HelpIcon } from '@icons/help.svg';

import s from './HeroSection.module.scss';

interface HeroSectionProps {
  className?: string;
  title?: string;
  desc?: string;
  coming?: string;
  img?: string;
  imgWidth?: number | string;
  imgHeight?: number | string;
  imgMargin?: string;
  btnLable?: string;
  imageOnTop?: boolean;
  onClick?: () => void;
}

export const HeroSection: React.FC<HeroSectionProps> = ({
  className,
  title,
  desc,
  coming,
  img,
  imgWidth,
  imgHeight,
  imgMargin,
  btnLable,
  imageOnTop = false,
  onClick,
}) => {
  const customStyle = {
    '--img-margin': imgMargin,
  } as React.CSSProperties;

  const image = (
    <img
      src={img}
      width={imgWidth}
      height={imgHeight}
      className={s.img}
      alt="quests"
    />
  );

  return (
    <div className={clsx(s.root, className)} style={customStyle}>
      <div className={s.heroImgWrap}></div>

      <div className={s.contentWrap}>
        {imageOnTop && image}
        {title && <h4 className={s.title}>{title}</h4>}

        {desc && <p className={s.desc}>{desc}</p>}
        {coming && <p className={s.coming}>{coming}</p>}
        {btnLable && (
          <Button
            className={s.link}
            btnType="link"
            variant="unfilled"
            size="large"
            rightIcon={<HelpIcon width={24} height={24} />}
            gap="4px"
            onClick={onClick}
          >
            {btnLable}
          </Button>
        )}
        {!imageOnTop && image}
      </div>
    </div>
  );
};
