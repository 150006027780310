/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from 'classnames';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import {
  BackButton,
  PurchaseWormBlock,
  WormPreviewSkills,
} from '@app/components';
import { useServiceState } from '@app/common/state';
import { versusCharacterService, versusShopService } from '@app/services';
import { useSpineAnimation } from '@app/hooks';
import { ShopPageSections, ShopPageTabs } from '../shop/ShopPage';
import { VersusPriceType } from '@app/types';

import s from './ShopWormPreviewPage.module.scss';

export const ShopWormPreviewPage: React.FC = () => {
  const navigate = useNavigate();

  const { code } = useParams<{ code: string }>();
  const [searchParams] = useSearchParams();
  const variant = searchParams.get('variant') || '';

  const { userCharacters, shopCharacters } = useServiceState(
    versusShopService,
    ['shopCharacters', 'userCharacters'],
  );

  const char =
    shopCharacters?.find((char) => char.code === code) ||
    userCharacters?.find((char) => char.code === code);
  const isOwned = userCharacters?.find((el) => el.code === code);
  const isSoldOut = char?.isSoldOut;
  const assets = versusCharacterService.generateCharAssets(code);

  const showBuyBlock =
    code &&
    assets &&
    char?.price &&
    char.priceType &&
    char.startSkills &&
    !isOwned &&
    !isSoldOut &&
    variant !== 'preview';

  useEffect(() => {
    if (
      !char ||
      (variant !== 'preview' && isOwned) ||
      (variant !== 'preview' && isSoldOut)
    ) {
      setTimeout(() =>
        navigate(`/shop?tab=${ShopPageTabs.default}&section=${code}`),
      );
    }
  }, [char, code, isOwned, isSoldOut, navigate, variant]);

  const { containerRef, isLoaded, dispose } = useSpineAnimation({
    charCode: code,
    config: {
      preserveDrawingBuffer: false,
      animation: 'idle',
      alpha: true,
      viewport: {
        x: -160,
        y: -50,
        width: 500,
        height: 1100,
      },
    },
  });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!char) {
    return null;
  }

  const navBack = () => {
    if (variant === 'preview') {
      return navigate('/worm-collector');
    }

    let navBackTab = ShopPageTabs.default;
    let navBackSection = ShopPageSections.wormsDefault;

    if (char.priceType === VersusPriceType.Medals) {
      navBackTab = ShopPageTabs.medals;
      navBackSection = ShopPageSections.wormsMedals;
    }

    navigate(`/shop?tab=${navBackTab}&section=${navBackSection}`);
  };

  return (
    <div
      className={s.root}
      style={{
        backgroundColor: isLoaded ? char?.color : 'var(--Primary-BG)',
      }}
    >
      <BackButton onClick={navBack} />

      <div
        className={cn(s.worm, s[variant], { [s.loaded]: isLoaded })}
        ref={containerRef}
      />
      {variant === 'preview' && (
        <img
          src={assets?.logoLg}
          className={cn(s.logo, { [s.loaded]: isLoaded })}
          alt={code}
        />
      )}
      {(variant === 'preview' || !showBuyBlock) && (
        <WormPreviewSkills
          skills={char.startSkills}
          className={cn(s.skills, { [s.loaded]: isLoaded })}
        />
      )}
      {showBuyBlock && (
        <PurchaseWormBlock
          code={code}
          logo={assets.logoLg}
          price={char.price!}
          priceFull={char.priceFull}
          priceTon={char.priceTon}
          priceType={char.priceType!}
          startSkills={char.startSkills!}
          priceStars={char.priceStars}
          className={cn(s.block, { [s.loaded]: isLoaded })}
        />
      )}
    </div>
  );
};
