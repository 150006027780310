import { useNavigate, useParams } from 'react-router-dom';

import { BackButton, Page, Wormdrobe } from '@app/components';

import s from './EquipPage.module.scss';

export const EquipPage: React.FC = () => {
  const { mode } = useParams<{
    mode: 'default' | 'tournament';
  }>();

  const navigate = useNavigate();

  return (
    <Page
      isHaveBottomNav={false}
      className={s.root}
      contentClassName={s.content}
    >
      <BackButton onClick={() => navigate(-1)} />
      <Wormdrobe mode={mode} />
    </Page>
  );
};
