import cn from 'classnames';
import { useState } from 'react';

import { VersusSkillUpgradable, VersusWssSkill } from '@app/types';
import { VersusSkill } from '@components';
import { Button } from '@app/ui-kit';
import {
  versusAudioService,
  versusCharacterService,
  versusTournamentService,
} from '@app/services';
import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg';
import cucumberIcon from '@media/cucumber-icon.png';
import { VersusSkillProgress } from './VersusSkillProgress';

import s from './VersusSkillsList.module.scss';

interface VersusSkillsListProps {
  balance: number;
  items: VersusSkillUpgradable[];
  charCode: string;
  isTournament: boolean;
  zIndex?: number;
  className?: string;
  onSkillUpdate: (cost: number) => void;
}

export const VersusSkillsList: React.FC<VersusSkillsListProps> = ({
  balance,
  items,
  charCode,
  isTournament,
  zIndex,
  className,
  onSkillUpdate,
}) => {
  const [upgradingSkillId, setUpgradingSkillId] =
    useState<VersusWssSkill | null>(null);

  const isUpgradeAvailable = (
    upgradePrice: number,
    skillLevel: number,
    maxLevel: number,
    id: VersusWssSkill,
  ): { canUpgrade: boolean; msg: string; isMaxLvl: boolean } => {
    const isMaxLvl = skillLevel >= maxLevel;
    const canUpgrade = balance >= upgradePrice && !isMaxLvl;

    if (upgradingSkillId === id) {
      return { canUpgrade: true, msg: `${upgradePrice}`, isMaxLvl };
    }

    if (isMaxLvl) {
      return { canUpgrade, msg: 'Maxed out', isMaxLvl };
    }

    if (canUpgrade) {
      return { canUpgrade, msg: `${upgradePrice}`, isMaxLvl };
    }

    return { canUpgrade, msg: `${upgradePrice}`, isMaxLvl };
  };

  const upgrade = async (
    canUpgrade: boolean,
    id: VersusWssSkill,
    cost: number,
  ) => {
    if (!canUpgrade) {
      return;
    }

    setUpgradingSkillId(id);
    versusAudioService.playSoundEffect('skillUpgrade');

    await versusCharacterService.upgradeSkill(id, charCode, () =>
      onSkillUpdate(cost),
    );

    if (isTournament) {
      await versusTournamentService.getTournament();
    }

    setTimeout(() => {
      setUpgradingSkillId(null);
    }, 500);
  };

  return (
    <div className={cn(s.root, className)}>
      {items.map((el, i) => {
        const { id, upgradePrice, level, maxLevel } = el;
        const upgradeStatus = isUpgradeAvailable(
          upgradePrice,
          level,
          maxLevel,
          id,
        );

        return (
          <div
            key={el.level + el.id + i}
            className={cn(s.container, {
              [s.animating]: upgradingSkillId === id,
            })}
            style={{ zIndex: i === 0 ? zIndex : undefined }}
          >
            <VersusSkill data={el} variant="skillsList" />
            <VersusSkillProgress data={el} className={s.info} />
            <Button
              variant="tertiary"
              size="medium"
              bgColor={
                upgradeStatus.canUpgrade
                  ? 'var(--action-primary-default)'
                  : 'var(--action-secondary-base-disabled)'
              }
              className={s.button}
              disabled={upgradingSkillId != null}
              // TODO refactor button no accept children instead of leftIcon/rightIcon
              leftIcon={
                !upgradeStatus?.isMaxLvl ? (
                  <img
                    src={cucumberIcon}
                    alt="cucumber"
                    width={24}
                    height={24}
                    className={s.cucumber}
                    style={
                      !upgradeStatus.canUpgrade
                        ? {
                            filter: 'grayscale(100%) brightness(10%)',
                          }
                        : undefined
                    }
                  />
                ) : undefined
              }
              rightIcon={
                !upgradeStatus?.isMaxLvl ? (
                  <ChevronRight
                    width={16}
                    height={16}
                    color="var(--text-on-actions-dark)"
                    style={{
                      transform: 'rotate(-90deg)',
                    }}
                  />
                ) : undefined
              }
              onClick={() =>
                upgrade(upgradeStatus.canUpgrade, id, upgradePrice)
              }
            >
              <div>{upgradeStatus.msg}</div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};
