import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { StateIndicator } from '../StateIndicator';
import { WheelIndicatorState, HitForce } from '@app/types';
import { useServiceState } from '@app/common/state';
import {
  versusAudioService,
  versusService,
  versusTournamentService,
} from '@app/services';

import s from './Wheel.module.scss';

interface VersusWheelProps {
  className?: string;
}

export const Wheel: React.FC<VersusWheelProps> = ({ className }) => {
  const {
    roundState,
    currentRound,
    playerHitForce,
    scaleDiagramImage,
    savedHitTime,
    indicatorCover,
  } = useServiceState(versusService, [
    'roundState',
    'currentRound',
    'playerHitForce',
    'isDetectForce',
    'playerHitForce',
    'scaleDiagramImage',
    'savedHitTime',
    'indicatorCover',
  ]);

  const [firstState, setFirstState] = useState<WheelIndicatorState>();
  const [secondState, setSecondState] = useState<WheelIndicatorState>();

  useEffect(() => {
    switch (roundState) {
      case 'startRound':
        versusAudioService.playSoundEffect('sectorTurn1');
        break;
      case 'fight':
        versusAudioService.playSoundEffect('sectorTurnFight');
        break;
      case 'timeout':
        versusAudioService.playSoundEffect('sectorTurnTimeout');

        if (savedHitTime === 0) {
          versusAudioService.playSoundEffect('timeoutSlapZero');
        }

        break;
      case 'showHitForce':
        setTimeout(() => {
          if (playerHitForce !== HitForce.miss) {
            versusAudioService.playSoundEffect('sectorTurnSuccess');
          } else {
            versusAudioService.playSoundEffect('sectorTurnTimeout');
          }
        }, 400);

        break;
      case 'waiting':
        versusAudioService.playSoundEffect('sectorTurn2');
        break;
    }
  }, [playerHitForce, roundState, savedHitTime]);

  useEffect(() => {
    const firstConfig = firstStateConfig.find(
      (config) => config.stateKey === roundState,
    );
    const secondConfig = secondStateConfig.find(
      (config) => config.stateKey === roundState,
    );

    if (firstConfig) {
      setFirstState(firstConfig.nextState);
    }

    if (secondConfig) {
      setSecondState(secondConfig.nextState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundState, currentRound]);

  const hitForces = {
    [HitForce.miss]: {
      sectorColor: '#413730',
      text: `MISSED!`,
      textColor: '#C9B69C',
    },
    [HitForce.okay]: {
      sectorColor: '#FFC166',
      text: `OKAY ISH...`,
      textColor: '#181411',
    },
    [HitForce.splendid]: {
      sectorColor: '#B8FF66',
      text: `SPLENDID!`,
      textColor: '#181411',
    },
  };

  const firstStateConfig = [
    {
      stateKey: 'startRound',
      nextState: {
        sectorColor: '#26201C',
        text: `round ${currentRound}`,
        textColor: '#FFEBCC',
      },
    },
    {
      stateKey: 'waiting',
      nextState: {
        sectorColor: '#907968',
        text: 'waiting',
        textColor: '#000',
      },
    },
  ];

  const secondStateConfig = [
    {
      stateKey: 'showHitForce',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      nextState: hitForces[playerHitForce!],
      animationDir: 1,
    },
  ];

  return (
    <div className={clsx(s.root, className)}>
      <StateIndicator
        className={s.stateIndicator}
        nextState={{
          sectorColor: firstStateConfig[0].nextState.sectorColor,
        }}
        defaultAngle={roundState === 'idle' ? 0 : 180}
      />
      <StateIndicator
        className={s.stateIndicator}
        nextState={{
          sectorColor: '#907968',
          text: 'timeout',
          textColor: '#000',
        }}
        defaultAngle={roundState === 'timeout' ? 0 : -180}
      />
      <StateIndicator
        className={s.stateIndicator}
        nextState={firstState}
        animationDir={-1}
        isAnimate={roundState === 'startRound' || roundState === 'waiting'}
      />
      <StateIndicator
        className={s.stateIndicator}
        nextState={secondState}
        animationDir={1}
        isAnimate={roundState === 'showHitForce'}
      />
      <StateIndicator
        className={s.stateIndicator}
        nextState={{
          sectorColor: '#F85B4E',
          text: 'fight!!!',
          textColor: '#FFEBCC',
        }}
        animationDir={1}
        isAnimate={roundState === 'fight'}
      />
      <div className={s.sectorDiagramBackground}>
        <img
          className={clsx(s.sectorDiagram, {
            [s.visible]:
              roundState === 'showHitForce' ||
              roundState === 'preDetectForce' ||
              roundState === 'detectForce',
          })}
          src={scaleDiagramImage || ''}
          alt="wheel"
        />
      </div>
      <img className={s.cover} src={indicatorCover} alt="wheel cover" />
    </div>
  );
};
