import cn from 'classnames';
import React, { useEffect } from 'react';

import { ReactComponent as PendingIcon } from '@icons/pending.svg';
import { ReactComponent as SuccessIcon } from '@icons/success.svg';
import { ReactComponent as WithdrawIcon } from '@icons/withdraw.svg';
import { ReactComponent as ErrorIcon } from '@icons/warning.svg';

import { formatDateTime, shortTonAddress } from '@app/utils';
import { fromNano } from '@ton/core';
import { useServiceState } from '@app/common/state';
import { referralService } from '@app/services';
import { CryptoWithdrawal, CryptoWithdrawalStatus } from '@app/types';

import s from './ManagerTransactionsCard.module.scss';

interface ManagerTransactionsCardProps {
  className?: string;
}

export const ManagerTransactionsCard: React.FC<
  ManagerTransactionsCardProps
> = ({ className }) => {
  const { withdrawals } = useServiceState(referralService, ['withdrawals']);

  useEffect(() => {
    if (!withdrawals) {
      referralService.getWithdrawals();
    }
  }, [withdrawals]);

  return (
    <div className={cn(s.root, className)}>
      <h5 className={s.sectionTitle}>Transactions</h5>
      <div className={s.items}>
        {withdrawals?.length === 0 && (
          <div className={s.noTransactions}>
            You don't have transactions yet
          </div>
        )}
        {withdrawals?.map((el, i) => (
          <Transaction key={el.id} title={'Withdrawal'} {...el} />
        ))}
      </div>
    </div>
  );
};

interface TransactionProps extends CryptoWithdrawal {
  title: string;
}

const Transaction: React.FC<TransactionProps> = ({
  title,
  status,
  walletAddress,
  amount,
  createdAt,
}) => {
  const info = txStatusConfig[status];
  const StatusIcon = info.icon;

  return (
    <div className={s.transaction}>
      <div className={s.iconWrapper}>
        <WithdrawIcon className={s.withdrawIcon} />

        <div
          className={s.statusWrapper}
          style={{ backgroundColor: info.backgroundColor }}
        >
          <StatusIcon color={info.color} />
        </div>
      </div>
      <div className={s.col}>
        <span className={s.txTitle}>{title}</span>
        <span className={s.address}>
          To: {walletAddress ? shortTonAddress(walletAddress) : 'Not specified'}
        </span>
      </div>
      <div className={cn(s.col, s.amount)}>
        <span className={s.amount}>{fromNano(amount)} TON</span>
        <span className={s.date}>{formatDateTime(createdAt).date}</span>
      </div>
    </div>
  );
};

const txStatusConfig = {
  [CryptoWithdrawalStatus.Pending]: {
    icon: PendingIcon,
    color: '#2A1900',
    backgroundColor: '#F90',
  },
  [CryptoWithdrawalStatus.Completed]: {
    icon: SuccessIcon,
    color: '#EBFFD4',
    backgroundColor: '#4F9300',
  },
  [CryptoWithdrawalStatus.ProcessingError]: {
    icon: ErrorIcon,
    color: '#EBFFD4',
    backgroundColor: 'var(--decorative-background-red)',
  },
  [CryptoWithdrawalStatus.Processing]: {
    icon: PendingIcon,
    color: 'var(--text-icn-primary-active)',
    backgroundColor: '--decorative-background-green',
  },
  [CryptoWithdrawalStatus.Queued]: {
    icon: PendingIcon,
    color: 'var(--text-icn-primary-active)',
    backgroundColor: '--decorative-background-green',
  },
};
