import { VersusPriceType } from '@app/types';
import { InfoBlock } from '@app/ui-kit';

interface ShopPurchaseStatusProps {
  isCompleted: boolean;
  type: VersusPriceType | undefined;
  className?: string;
}

export const ShopPurchaseStatus: React.FC<ShopPurchaseStatusProps> = ({
  isCompleted,
  type,
  className,
}) => {
  if (!isCompleted || !type) {
    return null;
  }

  const message = messages[type];

  return <InfoBlock message={message} className={className} />;
};

const messages: Record<VersusPriceType, string> = {
  [VersusPriceType.Ton]:
    'The item will be added to your account once the transaction is confirmed on the blockchain. You may leave this page.',
  [VersusPriceType.Stars]:
    'The item will be added to your account once we process the invoice. You may leave this page.',
  [VersusPriceType.Tomato]: 'Not supported',
  [VersusPriceType.Medals]: 'Not supported',
};
