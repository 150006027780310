import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { BackButton, Page, ShopItemImagePreview } from '@app/components';
import { ShopPageSections, ShopPageTabs } from '../shop/ShopPage';
import { PurchaseItemBlock } from '@app/components/PurchaseItemBlock';
import { useServiceState } from '@app/common/state';
import { versusShopService } from '@app/services';

import s from './ShopItemPreviewPage.module.scss';

export const ShopItemPreviewPage: React.FC = () => {
  const navigate = useNavigate();
  const { shopItems } = useServiceState(versusShopService, ['shopItems']);
  const { id, navBackSection } = useParams<{
    id: string;
    navBackSection: string;
  }>();

  const item = shopItems?.find((item) => item.code === id);

  useEffect(() => {
    if (!id || !item) {
      setTimeout(() =>
        navigate(`/shop?tab=${ShopPageTabs.default}&section=${navBackSection}`),
      );
    }
  }, [navBackSection, id, item, navigate]);

  if (!id || !item) {
    return null;
  }

  const { code, name, priceTon, price, priceFull, priceStars, priceType } =
    item;

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton
        onClick={() =>
          navigate(
            `/shop?tab=${ShopPageTabs.default}&section=${ShopPageSections.ticketsDefault}`,
          )
        }
      />
      <ShopItemImagePreview code={code} />
      <PurchaseItemBlock
        itemId={code}
        itemName={name}
        price={price}
        priceTon={priceTon}
        priceStars={priceStars}
        priceFull={priceFull}
        priceType={priceType}
      />
    </Page>
  );
};
