import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';
import { EventEmitter } from '../EventEmitter';
import {
  type RoundState,
  type VersusPlayer,
  type FightState,
  VersusClientEvent,
  VersusServerEvent,
  VersusWssMatch,
  VersusWssNewRound,
  VersusWssGameOver,
  VersusWssHitForce,
  HitForce,
  VersusMatchOutcome,
  VersusWssError,
  VersusWssSkillData,
  VersusWssSkill,
  VersusWssQueuedData,
  VersusWssMatchState,
  type VersusBattleResultData,
  VersusWssVictoryReward,
} from '@app/types';
import { globalNavigate } from '@app/components';
import { SocketService } from '../SocketService';
import { notifyError } from '@app/ui-kit/ToastNotifications';
import { configService, versusTournamentService } from '..';
import { UserService } from '../UserService';
import { VersusLeagueService } from '../VersusLeagueService';
import defaultBattleBg from '@media/battle-background.png';
import defaultIndicatorCover from '@media/versus/wheel-cover.png';
import { VersusTournamentService } from '../VersusTournamentService';

@Injectable()
@MakeObservable
export class VersusService extends EventEmitter {
  @observable
  public timerProgress = 100;

  @observable
  public isTimerActive = false;

  @observable
  public currentRound = 0;

  @observable
  public detectForceTime = 0;

  @observable
  public hitAngle = 0;

  @observable
  public isDetectForce = false;

  @observable
  public scaleDiagramImage: string | null = null;

  @observable
  public roundState: RoundState = 'idle';

  @observable
  public fightState: FightState | null = null;

  @observable
  public playerHitForce: HitForce | null = null;

  @observable
  public opponentHitForce: HitForce | null = null;

  @observable
  public player: VersusPlayer | null = null;

  @observable
  public opponent: VersusPlayer | null = null;

  @observable
  public playerDamage = 0;

  @observable
  public opponentDamage = 0;

  @observable
  public indicatorPeriod = 0;

  @observable
  public matchOutcome: VersusMatchOutcome | null = null;

  @observable
  public hasActiveMatch = false;

  @observable
  public savedHitTime = 0;

  @observable
  public isConnected = false;

  @observable
  public battleResultInfo: VersusBattleResultData | null = null;

  @observable
  public shakeBackground = false;

  @observable
  public playPressed = false;

  @observable
  public isTournamentMode = false;

  @observable
  public victoryRewards: VersusWssVictoryReward[] | null = null;

  @observable
  public battleBackground = defaultBattleBg;

  @observable
  public indicatorCover = defaultIndicatorCover;

  public readonly turnChangeTime = 3700;
  public readonly hpAnimationDelay = 1000;
  public readonly progressUpdateDelay = 180;

  private readonly matchMakingTimeoutRetry = 15000;
  private readonly tournamentMatchMakingTimeoutRetry = 45000;
  private readonly startRoundPostDelay = 1300;
  private readonly preDetectForcePostDelay = 1000;
  private readonly showHitForcePostDelay = 1300;
  private readonly matchFoundRedirectDelay = 3000;
  private readonly patternChangeDelay = 4000;
  private readonly pingEmitInterval = 5000;
  private readonly cancelMatchMakingTimeout = 2000;

  private newPlayerHp = 0;
  private newOpponentHp = 0;

  private startTimeRef = 0;
  private timerDuration = 0;
  private animationFrameRef: number | null = null;
  private roundStartDate: Date | null = null;
  private roundEndDate: Date | null = null;
  private serverDate: Date | null = null;
  private clientDateOffset = 0;
  private timeoutCheckRef: NodeJS.Timeout | null = null;
  private actionTimeout: NodeJS.Timeout | null = null;
  private matchmakingTimeout: NodeJS.Timeout | undefined;

  constructor(
    private readonly apiService: ApiService,
    private readonly socketService: SocketService,
    private readonly userService: UserService,
    private readonly versusLeagueService: VersusLeagueService,
    private readonly versusTournamentService: VersusTournamentService,
  ) {
    super();
  }

  tick(delta: number) {
    if (!this.isDetectForce) {
      this.detectForceTime += delta;
      this.emit('tick', this.detectForceTime);
    } else {
      this.emit('tick', this.savedHitTime);
    }
  }

  setRoundState(state: RoundState) {
    this.roundState = state;

    switch (state) {
      case 'startRound':
        this.isDetectForce = false;
        this.isTimerActive = true;
        this.detectForceTime = 0;
        this.savedHitTime = 0;
        this.hitAngle = 0;
        this.fightState = null;
        this.playerHitForce = null;
        this.opponentHitForce = null;
        this.playerDamage = 0;
        this.opponentDamage = 0;

        this.setServiceTimeout(
          () => this.setRoundState('preDetectForce'),
          this.startRoundPostDelay,
        );
        break;
      case 'preDetectForce':
        this.setServiceTimeout(
          () => this.setRoundState('detectForce'),
          this.preDetectForcePostDelay,
        );
        break;
      case 'detectForce':
        this.startTimer();
        break;

      case 'showHitForce':
        this.isDetectForce = false;

        this.setServiceTimeout(
          () => this.setRoundState('waiting'),
          this.showHitForcePostDelay,
        );
        break;

      case 'timeout':
        this.isDetectForce = false;
        break;

      case 'waiting':
        break;
      case 'fight':
        this.hideTimer();
        this.resetTimer();
        this.setServiceTimeout(() => {
          this.startFightPhase();
        }, this.showHitForcePostDelay);
        break;
      case 'idle':
        this.isDetectForce = false;
        this.isTimerActive = true;
        this.detectForceTime = 0;
        this.savedHitTime = 0;
        this.hitAngle = 0;
        this.fightState = null;
        this.playerHitForce = null;
        this.opponentHitForce = null;
        this.playerDamage = 0;
        this.opponentDamage = 0;
        this.hideTimer();
        this.resetTimer();
        break;
    }
  }

  setPlayerHitForce(force: HitForce) {
    this.playerHitForce = force;
  }

  setOpponentHitForce(force: HitForce) {
    this.opponentHitForce = force;
  }

  startFightPhase(isOnboarding?: boolean) {
    this.updateFightState('playerTurn', isOnboarding);

    this.setServiceTimeout(() => {
      this.updateFightState('opponentTurn', isOnboarding);
    }, this.turnChangeTime);
  }

  updateFightState(fightState: FightState, isOnboarding?: boolean) {
    this.fightState = fightState;

    if (fightState === 'playerTurn') {
      this.updateHp('opponent');

      return;
    }

    if (fightState === 'opponentTurn') {
      this.updateHp('own');

      if (
        this.opponentHitForce === HitForce.splendid ||
        this.opponentHitForce === HitForce.okay
      ) {
        this.shakeBackground = true;
      }

      if (isOnboarding) {
        return;
      }

      this.setServiceTimeout(async () => {
        if (this.matchOutcome == null) {
          this.setRoundState('startRound');
        } else {
          this.goToResults();
        }
      }, this.turnChangeTime);
    }
  }

  goToResults() {
    this.setRoundState('idle');

    if (this.isTournamentMode) {
      globalNavigate('/tournament-battle-result', false);
    } else {
      globalNavigate('/battle-result', false);

      setTimeout(() => this.updateBattleInfoLeague(), 1000);
      setTimeout(() => this.updateBattleInfoBalance(), 1500);
    }
  }

  resetFightState() {
    this.opponentDamage = 0;
    this.playerDamage = 0;
    this.fightState = null;
    this.opponentHitForce = null;
    this.playerHitForce = null;
  }

  private calculateClientServerOffset() {
    if (this.serverDate) {
      const clientNow = new Date();

      this.clientDateOffset = clientNow.getTime() - this.serverDate.getTime();
    }
  }

  startTimer() {
    if (!this.roundEndDate) {
      return;
    }

    const adjustedRoundEndDate = new Date(
      this.roundEndDate.getTime() + this.clientDateOffset,
    );

    const remainingDuration = adjustedRoundEndDate.getTime() - Date.now();

    if (remainingDuration <= 0) {
      console.log('Timer has already ended.');
      this.handleTimeout();

      return;
    }

    console.log('Remaining round duration: ', remainingDuration);
    console.log('Client server time offset: ', this.clientDateOffset);

    this.isTimerActive = true;
    this.startTimeRef = performance.now();

    this.timerDuration = remainingDuration;

    this.updateTimer();
  }

  hideTimer() {
    this.isTimerActive = false;
  }

  pauseTimer() {
    if (this.animationFrameRef) {
      cancelAnimationFrame(this.animationFrameRef);
      this.animationFrameRef = null;
    }
  }

  resetTimer() {
    setTimeout(() => {
      this.timerProgress = 100;
    }, 500);

    if (this.animationFrameRef) {
      cancelAnimationFrame(this.animationFrameRef);
      this.animationFrameRef = null;
    }
  }

  private updateTimer() {
    if (!this.isTimerActive) {
      return;
    }

    const delta = performance.now() - this.startTimeRef;
    const remainingTime = this.timerDuration - delta;
    const progress = Math.max(
      0,
      Math.min(100, (remainingTime / this.timerDuration) * 100),
    );

    this.timerProgress = progress;

    if (progress > 0) {
      this.animationFrameRef = requestAnimationFrame(
        this.updateTimer.bind(this),
      );
    } else {
      this.handleTimeout();
      this.resetTimer();
    }
  }

  handleTimeout() {
    this.isTimerActive = false;
    this.setRoundState('timeout');

    this.startPostTimeoutCheck();
  }

  private startPostTimeoutCheck() {
    if (configService.env !== 'production') {
      return;
    }

    if (this.timeoutCheckRef) {
      clearInterval(this.timeoutCheckRef);
      this.timeoutCheckRef = null;
    }

    this.timeoutCheckRef = setInterval(() => {
      if (this.roundState === 'timeout') {
        this.ping();
      } else {
        if (this.timeoutCheckRef !== null) {
          clearInterval(this.timeoutCheckRef);
          this.timeoutCheckRef = null;
        }
      }
    }, this.pingEmitInterval);
  }

  setHitAngle(hitAngle: number) {
    this.hitAngle = hitAngle;
  }

  resetHitForce() {
    this.detectForceTime = 0;
    this.isDetectForce = false;
  }

  updateHp(target: 'own' | 'opponent') {
    if (target === 'opponent' && this.opponent) {
      this.opponent = {
        ...this.opponent,
        hp: {
          ...this.opponent.hp,
          current: this.newOpponentHp,
        },
      };

      return;
    }

    if (target === 'own' && this.player) {
      this.player = {
        ...this.player,
        hp: {
          ...this.player.hp,
          current: this.newPlayerHp,
        },
      };
    }
  }

  applyDamage(ownDamage: number, opponentDamage: number) {
    this.playerDamage = ownDamage;
    this.opponentDamage = opponentDamage;
  }

  enterFightState() {
    this.setServiceTimeout(() => {
      this.setRoundState('fight');
    }, this.showHitForcePostDelay);
  }

  resetGameState() {
    if (this.actionTimeout) {
      clearTimeout(this.actionTimeout);
    }

    this.currentRound = 0;
    this.detectForceTime = 0;
    this.savedHitTime = 0;
    this.hitAngle = 0;
    this.isDetectForce = false;
    this.scaleDiagramImage = null;
    this.roundState = 'idle';
    this.fightState = null;
    this.playerHitForce = null;
    this.opponentHitForce = null;
    this.player = null;
    this.opponent = null;
    this.playerDamage = 0;
    this.opponentDamage = 0;
    this.indicatorPeriod = 0;
    this.matchOutcome = null;
    this.roundStartDate = null;
    this.roundEndDate = null;
    this.serverDate = null;
    this.clientDateOffset = 0;
    this.battleResultInfo = null;
    this.playPressed = false;
    this.isTournamentMode = false;
    this.victoryRewards = null;
    this.newPlayerHp = 0;
    this.newOpponentHp = 0;
  }

  connectToServer(onConnectedCallback?: () => void) {
    this.playPressed = true;
    this.socketService.connect();

    this.socketService.on('disconnect', () => {
      if (this.actionTimeout !== null) {
        clearTimeout(this.actionTimeout);
      }

      this.setRoundState('idle');

      this.isConnected = false;
    });

    this.socketService.on('connect', () => {
      this.isConnected = true;
    });

    this.socketService.once('connect', () => {
      this.resetGameState();
      onConnectedCallback?.();
      this.playPressed = false;
    });

    const manager = this.socketService.socket?.io;

    manager?.on('reconnect', () => {
      Object.values(VersusServerEvent).forEach((eventName) => {
        this.socketService.off(eventName);
      });

      this.socketService.once('connect', () => {
        this.continueMatch();
      });
    });

    manager?.on('reconnect_failed', () => {
      notifyError("Can't connect to server, closing app..");
      setTimeout(() => {
        window.Telegram.WebApp.close();
      }, 2000);
    });
  }

  initGame() {
    if (!this.socketService.socket?.connected) {
      notifyError(`Error: Websocket client not connected`);

      return;
    }

    if (this.hasActiveMatch) {
      this.continueMatch();

      return;
    }

    globalNavigate('/match-making');
    this.initServerEventListeners();

    this.startMatchMaking();
  }

  startMatchMaking() {
    this.socketService.emit(VersusClientEvent.VersusMatchmakingQueue, {
      isTournament: this.isTournamentMode,
    });
  }

  continueMatch() {
    if (this.isConnected) {
      this.initServerEventListeners(false);
      this.socketService.emit(VersusClientEvent.VersusMatchGetState, null);
    } else {
      this.connectToServer(() => {
        this.initServerEventListeners(false);
        this.socketService.emit(VersusClientEvent.VersusMatchGetState, null);
      });
    }
  }

  startRetryMatchmaking() {
    if (this.roundState !== 'idle') {
      return;
    }

    if (this.matchmakingTimeout) {
      clearInterval(this.matchmakingTimeout);
      this.matchmakingTimeout = undefined;
    }

    console.log('Starting matchmaking retries...');

    let timeout = this.matchMakingTimeoutRetry;

    if (this.isTournamentMode) {
      timeout = this.tournamentMatchMakingTimeoutRetry;
    }

    this.matchmakingTimeout = setInterval(() => {
      if (this.roundState !== 'idle' || !this.isConnected) {
        if (this.matchmakingTimeout) {
          clearInterval(this.matchmakingTimeout);
        }

        this.matchmakingTimeout = undefined;

        return;
      }

      console.log(
        'Retrying matchmaking, is tournament: ',
        this.isTournamentMode,
      );

      this.socketService.emit(VersusClientEvent.VersusMatchMakingCancel, null);

      this.startMatchMaking();
    }, timeout);
  }

  stopRetryMatchmaking() {
    if (this.matchmakingTimeout) {
      clearInterval(this.matchmakingTimeout);
      this.matchmakingTimeout = undefined;
      console.log('Matchmaking retries stopped.');
    }
  }

  initServerEventListeners(shouldInitQueue = true) {
    if (shouldInitQueue && this.roundState === 'idle') {
      this.startRetryMatchmaking();
    }

    this.socketService.once<VersusWssQueuedData>(
      VersusServerEvent.VersusMatchmakingQueued,
      (data) => {
        this.handleQueued(data);
      },
    );

    this.socketService.once<VersusWssMatchState>(
      VersusServerEvent.VersusMatchState,
      (data) => {
        this.handleResumeMatch(data);
      },
    );

    this.socketService.once<VersusWssMatch>(
      VersusServerEvent.VersusMatchCreated,
      (matchData) => {
        this.handleMatchCreated(matchData);

        if (this.matchmakingTimeout) {
          clearTimeout(this.matchmakingTimeout);
          this.matchmakingTimeout = undefined;
        }
      },
    );

    this.socketService.on<VersusWssHitForce>(
      VersusServerEvent.VersusMatchHitMultiplier,
      (hitData) => {
        console.log('Player hit force:', hitData.hitMultiplier);

        this.handlePlayerHit(hitData);
      },
    );

    this.socketService.on<VersusWssNewRound>(
      VersusServerEvent.VersusMatchNewRound,
      (roundData) => {
        console.log('New round started:', roundData);

        this.handleNewRound(roundData);
      },
    );

    this.socketService.once<VersusWssGameOver>(
      VersusServerEvent.VersusMatchFinish,
      (gameOverData) => {
        console.log('Game over:', gameOverData);

        this.handleGameOver(gameOverData);
      },
    );

    this.socketService.on<VersusWssError>(VersusServerEvent.Error, (error) => {
      this.handleServerError(error);
    });
  }

  handleResumeMatch(data: VersusWssMatchState) {
    console.log(`Received resume data: `, data);

    const {
      user,
      enemy,
      hp,
      enemyHp,
      maxHp,
      startDate,
      endDate,
      serverDate,
      round,
      image,
      hitMultiplier,
      victoryRewards,
      isTournament,
    } = data;

    this.isTournamentMode = isTournament;

    if (isTournament) {
      this.setTournamentConfig();
    }

    this.player = {
      name: user.name,
      hp: { current: hp, total: maxHp },
      skills: this.generateSkills(user.skills),
      rating: user.rating,
      character: user.character,
    };

    this.opponent = {
      name: enemy.name,
      hp: { current: enemyHp, total: maxHp },
      skills: this.generateSkills(enemy.skills),
      rating: enemy.rating,
      character: enemy.character,
    };

    this.victoryRewards = victoryRewards;
    this.roundStartDate = new Date(startDate);
    this.roundEndDate = new Date(endDate);
    this.serverDate = new Date(serverDate);

    this.currentRound = round;
    this.scaleDiagramImage = image;
    this.indicatorPeriod = user.reactionPeriod;

    this.calculateClientServerOffset();
    this.navigateToBattle();

    if (hitMultiplier != null) {
      this.playerHitForce = hitMultiplier;
      this.setRoundState('waiting');
      this.startTimer();
    } else {
      this.setRoundState('startRound');
    }
  }

  handleQueued(data: VersusWssQueuedData) {
    console.log('Server started match making!', data);

    const { name, skills, rating, character } = data.user;

    this.player = {
      name,
      hp: { current: 1, total: 1 },
      skills: this.generateSkills(skills),
      rating,
      character,
    };
  }

  handleMatchCreated(matchData: VersusWssMatch) {
    const {
      user,
      enemy,
      hp,
      enemyHp,
      round,
      startDate,
      endDate,
      serverDate,
      image,
      victoryRewards,
      isTournament,
    } = matchData;

    console.log('Match created:', matchData);
    this.stopRetryMatchmaking();

    this.isTournamentMode = isTournament;

    if (isTournament) {
      this.setTournamentConfig();
    }

    this.player = {
      name: user.name,
      hp: { current: hp, total: hp },
      skills: this.generateSkills(user.skills),
      rating: user.rating,
      character: user.character,
    };

    this.opponent = {
      name: enemy.name,
      hp: { current: enemyHp, total: enemyHp },
      skills: this.generateSkills(enemy.skills),
      rating: enemy.rating,
      character: enemy.character,
    };

    this.victoryRewards = victoryRewards;
    this.roundStartDate = new Date(startDate);
    this.roundEndDate = new Date(endDate);
    this.serverDate = new Date(serverDate);

    this.currentRound = round;
    this.scaleDiagramImage = image;
    this.indicatorPeriod = user.reactionPeriod;

    this.calculateClientServerOffset();

    setTimeout(() => {
      this.navigateToBattle();
      this.setRoundState('startRound');
    }, this.matchFoundRedirectDelay);
  }

  handlePlayerHit(hitData: VersusWssHitForce) {
    this.playerHitForce = hitData.hitMultiplier;
    this.setRoundState('showHitForce');
    this.resetHitForce();
  }

  handleNewRound(roundData: VersusWssNewRound) {
    this.isTournamentMode = roundData.isTournament;
    this.currentRound = roundData.round;
    this.roundStartDate = new Date(roundData.startDate);
    this.roundEndDate = new Date(roundData.endDate);
    this.serverDate = new Date(roundData.serverDate);
    this.opponentHitForce = roundData.enemyHitMultiplier;

    this.calculateClientServerOffset();

    this.setNewHp(roundData.hp, roundData.enemyHp);

    this.applyDamage(roundData.hitDamage, roundData.enemyHitDamage);

    setTimeout(() => {
      this.scaleDiagramImage = roundData.image;
    }, this.patternChangeDelay);

    this.enterFightState();
  }

  disconnect() {
    this.socketService.socket?.removeAllListeners();
    this.socketService.socket?.disconnect();
  }

  async handleGameOver(gameOverData: VersusWssGameOver) {
    this.disconnect();

    const {
      scoreChange,
      outcome,
      enemyHitMultiplier,
      hitDamage,
      enemyHitDamage,
      versusScoreRewards,
      versusScoreChange,
      newScore,
      isTournament,
      hp,
      enemyHp,
    } = gameOverData;

    this.isTournamentMode = isTournament;

    if (isTournament) {
      this.setTournamentConfig();
    }

    this.setNewHp(hp, enemyHp);

    this.matchOutcome = outcome;

    if (this.roundState !== 'idle') {
      this.opponentHitForce = enemyHitMultiplier;
      this.applyDamage(hitDamage, enemyHitDamage);

      this.hasActiveMatch = false;
      this.enterFightState();
    }

    const oldUserProfile = this.userService.userProfile;
    const oldVersusData = oldUserProfile?.versus;

    this.battleResultInfo = {
      leagueLevel: oldVersusData?.rating.rank || 0,
      leagueProgress: oldVersusData?.rating.progress || 0,
      leagueImage: oldVersusData?.rating.leagueIcon || '',
      score: oldVersusData?.score || 0,
      balance: oldUserProfile?.score || 0,
      balanceAfterBattle: newScore,
      scoreChange: scoreChange,
      versusScore: oldVersusData?.score || 0,
      versusScoreChange: versusScoreChange,
      rewards: versusScoreRewards,
      shouldNavigateToLeague: false,
    };

    await this.userService.fetchProfile();

    if (this.isTournamentMode) {
      await versusTournamentService.getTournamentByOutcome(outcome);
      this.versusTournamentService.sendBattleEndedEvent(outcome);
    } else {
      const shouldNavigateToLeague =
        oldVersusData?.rating.leagueName !==
          this.userService.userProfile?.versus?.rating.leagueName &&
        outcome === VersusMatchOutcome.Victory;

      this.battleResultInfo = {
        ...this.battleResultInfo,
        shouldNavigateToLeague,
      };

      await this.versusLeagueService.fetchLeagueRewards();
    }

    if (this.roundState === 'idle') {
      this.goToResults();
    }
  }

  updateBattleInfoLeague() {
    const rating = this.userService.userProfile?.versus?.rating;
    const leagueIcon =
      this.userService.userProfile?.versus?.rating.leagueIcon ?? '';

    if (!rating || !this.battleResultInfo) {
      return;
    }

    const oldRating = this.userService.getRatingFromRankProgress(
      this.battleResultInfo.leagueLevel,
      this.battleResultInfo.leagueProgress,
      rating.progressMax,
    );

    const targetRating = this.userService.getRatingFromRankProgress(
      rating.rank,
      rating.progress,
      rating.progressMax,
    );

    if (targetRating === oldRating) {
      return;
    }

    let { rank: oldLevel, progress: oldProgress } =
      this.userService.getRankProgressFromRating(oldRating);

    const { rank: newLevel, progress: newProgress } =
      this.userService.getRankProgressFromRating(targetRating);

    const shouldIncrement = (
      oldLevel: number,
      oldProgress: number,
      newLevel: number,
      newProgress: number,
    ) =>
      oldLevel < newLevel ||
      (oldLevel === newLevel && oldProgress < newProgress);

    const shouldDecrement = (
      oldLevel: number,
      oldProgress: number,
      newLevel: number,
      newProgress: number,
    ) =>
      oldLevel > newLevel ||
      (oldLevel === newLevel && oldProgress > newProgress);

    const incrementProgress = () => {
      if (oldProgress < rating.progressMax - 1) {
        oldProgress++;
      } else {
        oldProgress = 0;
        oldLevel++;
      }
    };

    const decrementProgress = () => {
      if (oldProgress === 0) {
        oldLevel--;
        oldProgress = rating.progressMax;
      }

      oldProgress--;
    };

    const interval = setInterval(() => {
      if (shouldIncrement(oldLevel, oldProgress, newLevel, newProgress)) {
        incrementProgress();
      } else if (
        shouldDecrement(oldLevel, oldProgress, newLevel, newProgress)
      ) {
        decrementProgress();
      }

      if (this.battleResultInfo) {
        this.battleResultInfo = {
          ...this.battleResultInfo,
          leagueLevel: oldLevel,
          leagueProgress: oldProgress,
          leagueImage: leagueIcon,
        };
      }

      if (oldLevel === newLevel && oldProgress === newProgress) {
        clearInterval(interval);
      }
    }, this.progressUpdateDelay);
  }

  updateBattleInfoBalance() {
    if (!this.battleResultInfo) {
      return;
    }

    this.battleResultInfo = {
      ...this.battleResultInfo,
      balance: this.battleResultInfo.balanceAfterBattle,
    };
  }

  handleServerError(error: VersusWssError) {
    console.log('Server error:', error.message);

    notifyError(`Error: ${error.message}`);
  }

  retreat() {
    this.socketService.emit(VersusClientEvent.VersusMatchRetreat, null);
    this.cancelActionTimeout();
    this.setRoundState('idle');
    this.hasActiveMatch = false;
  }

  ping() {
    this.socketService.emit(VersusClientEvent.VersusMatchPing, null);
  }

  async emitHit() {
    const time = Math.round(this.detectForceTime);
    const round = this.currentRound;

    this.isDetectForce = true;
    this.savedHitTime = time;

    this.socketService.emitWithRetry(
      VersusClientEvent.VersusMatchHit,
      {
        time,
        round,
      },
      1500,
      15,
    );
  }

  cancelMatchMaking() {
    this.socketService.emit(VersusClientEvent.VersusMatchMakingCancel, null);

    setTimeout(() => {
      if (this.opponent != null) {
        return;
      }

      this.socketService.socket?.removeAllListeners();
      this.socketService.disconnect();

      if (this.isTournamentMode) {
        globalNavigate('/tournament-phase');
      } else {
        globalNavigate('/play');
      }
    }, this.cancelMatchMakingTimeout);
  }

  async checkForActiveMatch() {
    try {
      const isActive = await this.apiService.get<boolean>(
        '/versus/is-match-active',
      );

      this.hasActiveMatch = isActive;

      if (!isActive) {
        return;
      }

      this.continueMatch();
    } catch (e) {
      notifyError(`Error: ${e}`);
    }
  }

  setNewHp(playerHp: number, opponentHp: number) {
    this.newPlayerHp = playerHp;
    this.newOpponentHp = opponentHp;
  }

  private generateSkills(
    userSkills: VersusWssSkillData[],
  ): VersusWssSkillData[] {
    const allSkills: VersusWssSkill[] = [
      VersusWssSkill.Damage,
      VersusWssSkill.Resilience,
      VersusWssSkill.Reaction,
    ];

    const leveledSkills = userSkills.filter((skill) => skill.level > 0);

    if (leveledSkills.length > 0) {
      return allSkills.map((skill) => {
        const userSkill = userSkills.find((s) => s.id === skill);

        return {
          id: skill,
          level: userSkill ? userSkill.level : 0,
        };
      });
    }

    return allSkills.map((skill) => ({
      id: skill,
      level: 0,
    }));
  }

  private setServiceTimeout(callback: () => void, delay: number) {
    this.cancelActionTimeout();

    this.actionTimeout = setTimeout(() => {
      this.actionTimeout = null;
      callback();
    }, delay);
  }

  cancelActionTimeout() {
    if (this.actionTimeout) {
      clearTimeout(this.actionTimeout);
    }
  }

  enableTournamentMode() {
    this.isTournamentMode = true;

    this.setTournamentConfig();
  }

  setTournamentConfig() {
    if (!this.isTournamentMode) {
      return;
    }

    const tournamentBg = this.versusTournamentService.getBattleBackground();
    const indicatorCover = this.versusTournamentService.getIndicatorCover();

    this.versusTournamentService.setIsFinalBattle();

    if (tournamentBg) {
      this.battleBackground = tournamentBg;
    }

    if (indicatorCover) {
      this.indicatorCover = indicatorCover;
    }
  }

  navigateToBattle() {
    const mode = this.isTournamentMode ? 'tournament' : 'default';

    if (this.isTournamentMode && !this.hasActiveMatch) {
      this.versusTournamentService.sendBattleStartedEvent();
    }

    globalNavigate(`/battle?mode=${mode}`, false);
  }
}
