export const customScrollTo = (
  element: HTMLElement,
  duration: number,
  scrollContainer?: HTMLElement,
) => {
  const start = scrollContainer ? scrollContainer.scrollTop : window.scrollY;

  const target = scrollContainer
    ? element.offsetTop - (scrollContainer as HTMLElement).offsetTop
    : element.getBoundingClientRect().top + window.scrollY;

  const distance = target - start;

  let startTime: number | null = null;

  const scroll = (currentTime: number) => {
    if (startTime === null) {
      startTime = currentTime;
    }

    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);

    const scrollPosition = start + distance * progress;

    if (scrollContainer) {
      scrollContainer.scrollTo(0, scrollPosition);
    } else {
      window.scrollTo(0, scrollPosition);
    }

    if (elapsedTime < duration) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
