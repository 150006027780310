import cn from 'classnames';

import { ImageLoader } from '@app/components/ImageLoader';
import { VersusAccessory } from '@app/types';
import { ReactComponent as DeselectIcon } from '@icons/deselect.svg';
import { numberWithCommas } from '@app/utils';
import { VERSUS_ITEM_BONUS } from '@app/constants/versus';
import { versusCharacterService } from '@app/services';

import s from './WormdrobeItem.module.scss';

interface WormdrobeItemProps {
  data: VersusAccessory | null;
  isActive: boolean;
  className?: string;
  isDeselect?: boolean;
  disabled: boolean;
  onClick: (id: string | null) => void;
}

export const WormdrobeItem: React.FC<WormdrobeItemProps> = ({
  data,
  isActive,
  isDeselect,
  disabled,
  className,
  onClick,
}) => {
  if (isDeselect) {
    return (
      <button
        className={cn(
          s.root,
          s.deselect,
          { [s.disabled]: disabled },
          className,
        )}
        onClick={() => onClick(null)}
      >
        <DeselectIcon className={s.deselectIcon} />
      </button>
    );
  }

  if (data) {
    const { code, name, abilities } = data;
    const image = versusCharacterService.generateAccessoryIcon(code);

    return (
      <button
        className={cn(
          s.root,
          s.item,
          {
            [s.active]: isActive,
            [s.disabled]: disabled,
          },
          className,
        )}
        onClick={() => onClick(data.code)}
      >
        <ImageLoader src={image} alt={name} className={s.itemImage} />
        {abilities.map((bonus) => (
          <div className={s.bonus}>
            <div className={s.amount}>
              +{numberWithCommas(bonus.bonusAmount)}
            </div>
            <img
              src={VERSUS_ITEM_BONUS[bonus.bonusType].image}
              className={s.bonusIcon}
              alt={bonus.bonusType}
            />
          </div>
        ))}
      </button>
    );
  }

  return null;
};
