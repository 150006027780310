import cn from 'classnames';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { BottomSheet, Button } from '@app/ui-kit';
import tonWalletImg from '@media/ton-wallet.png';
import {
  ConnectedWallet,
  TransactionDetailsCard,
  VersusPriceTag,
} from '@components';

import { CryptoWithdrawalStatus, VersusPriceType } from '@app/types';
import { versusTournamentService } from '@app/services';

import s from './TournamentWithdrawPrizeBottomSheet.module.scss';

interface TournamentWithdrawPrizeBottomSheetProps {
  prizeId: string;
  amount: number;
  isOpen: boolean;
  onClose: (result: boolean) => void;
}

export const TournamentWithdrawPrizeBottomSheet: React.FC<
  TournamentWithdrawPrizeBottomSheetProps
> = ({ prizeId, amount, isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentReward = versusTournamentService.getCryptoRewardById(prizeId);
  const isCompleted =
    currentReward?.status === CryptoWithdrawalStatus.Completed;

  const handleWithdraw = async () => {
    setIsLoading(true);

    await versusTournamentService.withdrawTournamentCryptoReward(prizeId);

    setIsLoading(false);
  };

  const handleCtaClick = () => {
    if (currentReward?.txHash) {
      onClose(false);
    } else {
      handleWithdraw();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <BottomSheet
      open={isOpen}
      withClose={false}
      contentClassName={s.bottomSheet}
      bgColor={'var(--surface-primary)'}
      className={s.root}
    >
      <img src={tonWalletImg} alt="wallet" className={s.image} />
      <h2 className={s.title}>
        {currentReward?.status && statusMessages[currentReward.status]}
      </h2>
      <AnimatePresence initial={false} mode="wait">
        {!isCompleted && (
          <motion.div
            key="connected-wallet"
            variants={animationVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <ConnectedWallet className={s.wallet} />
            <VersusPriceTag
              price={amount}
              priceType={VersusPriceType.Ton}
              size="big"
            />
          </motion.div>
        )}
        {isCompleted && currentReward && (
          <motion.div
            key="transaction-details"
            variants={animationVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <TransactionDetailsCard
              amount={currentReward.amount}
              toWallet={currentReward.walletAddress ?? ''}
              txHash={currentReward.txHash ?? ''}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <Button
        onClick={handleCtaClick}
        variant="primary"
        disabled={isLoading}
        className={s.button}
      >
        {isCompleted ? 'Cool Thanks!' : 'Withdraw'}
      </Button>
      {!isCompleted && (
        <Button
          onClick={() => onClose(false)}
          variant="secondary"
          disabled={isLoading}
          className={s.button}
        >
          Close
        </Button>
      )}
    </BottomSheet>
  );
};

const statusMessages = {
  [CryptoWithdrawalStatus.Pending]: 'Withdraw',
  [CryptoWithdrawalStatus.Queued]: 'Withdrawal Queued for Processing..',
  [CryptoWithdrawalStatus.Processing]: 'Withdrawal Processing..',
  [CryptoWithdrawalStatus.ProcessingError]: 'Withdraw Error!',
  [CryptoWithdrawalStatus.Completed]: 'Withdraw Completed',
};

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.5 } },
};
