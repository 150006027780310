export const formatDateTime = (isoString: string) => {
  const parsedDate = new Date(isoString);

  const date = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(parsedDate);

  const time = new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(parsedDate);

  return {
    date,
    time,
  };
};
