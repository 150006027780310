import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';

import {
  ShopInvoice,
  ShopInvoiceType,
  ShopItemType,
  VersusPriceType,
  type VersusAccessory,
  type VersusShopItem,
  type VersusWormData,
} from '@app/types';
import { UserService } from '../UserService';
import { notifySuccess } from '@app/ui-kit/ToastNotifications';
import {
  SendTransactionRequest,
  TonConnectUI,
  Wallet,
  WalletInfoWithOpenMethod,
} from '@tonconnect/ui-react';
import { beginCell, Cell, fromNano } from '@ton/core';
import { analyticsService } from '..';
import { TonClient } from '@ton/ton';
import { ConfigService } from '../ConfigService';
import { globalNavigate } from '@app/components';
import { ShopPageSections, ShopPageTabs } from '@app/pages/shop/ShopPage';

@Injectable()
@MakeObservable
export class VersusShopService {
  @observable
  public userCharacters: VersusWormData[] | null = null;

  @observable
  public shopCharacters: VersusWormData[] | null = null;

  @observable
  public userAccessories: VersusAccessory[] | null = null;

  @observable
  public shopAccessories: VersusAccessory[] | null = null;

  @observable
  public shopItems: VersusShopItem[] | null = null;

  @observable
  public purchaseInProgress = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly configService: ConfigService,
    private readonly userService: UserService,
  ) {}

  async getUserCharacters() {
    this.userCharacters = await this.apiService.get<VersusWormData[]>(
      '/versus/character/my',
    );
  }

  async getShopCharacters() {
    this.shopCharacters = await this.apiService.get<VersusWormData[]>(
      '/versus/character/available',
    );
  }

  async getCharacters() {
    await Promise.all([this.getUserCharacters(), this.getShopCharacters()]);
  }

  async purchaseCharacterWithGameCurrency(
    code: string,
    currency: VersusPriceType,
    price: number,
  ) {
    this.userCharacters = await this.apiService.post<VersusWormData[]>(
      '/versus/character/purchase',
      { code },
    );

    if (currency === VersusPriceType.Medals) {
      analyticsService.trackEvent({
        name: 'purchase',
        variables: {
          item: code,
          amount: price,
          currency: currency.toLowerCase(),
        },
      });
    }

    await this.getShopCharacters();
    await this.userService.fetchProfile();

    let navBackTab = ShopPageTabs.default;
    let navBackSection = ShopPageSections.wormsDefault;

    if (currency === VersusPriceType.Medals) {
      navBackTab = ShopPageTabs.medals;
      navBackSection = ShopPageSections.wormsMedals;
    }

    globalNavigate(`/shop?tab=${navBackTab}&section=${navBackSection}`);
  }

  async getUserAccessories() {
    this.userAccessories = await this.apiService.get<VersusAccessory[]>(
      '/versus/accessory/my',
    );
  }

  async getShopAccessories() {
    this.shopAccessories = await this.apiService.get<VersusAccessory[]>(
      '/versus/accessory/available',
    );
  }

  async getAccessories() {
    await Promise.all([this.getUserAccessories(), this.getShopAccessories()]);
  }

  async purchaseAccessoryWithTomato(code: string) {
    this.userCharacters = await this.apiService.post<VersusWormData[]>(
      '/versus/accessory/purchase',
      { code },
    );

    await this.getAccessories();

    await this.userService.fetchProfile();

    globalNavigate(`/shop/${ShopPageSections.accessoriesDefault}`);
  }

  async getShopItems() {
    this.shopItems =
      await this.apiService.get<VersusShopItem[]>('/versus/shop');
  }

  async purchaseWithTon({
    wallet,
    tonConnectUI,
    tgUserId,
    shopItemCode,
    shopItemPrice,
    client,
    itemType,
    onTxSent,
    onItemReceived,
  }: PurchaseItemWithTon) {
    if (!tgUserId || !this.configService.serverConfig) {
      return;
    }

    if (!wallet?.account.address) {
      return;
    }

    const invoice = await this.createInvoice(shopItemCode, ShopInvoiceType.Ton);

    const body = beginCell()
      .storeUint(0, 32)
      .storeStringTail(invoice.id)
      .endCell();

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: this.configService.serverConfig.versusTreasuryAddress,
          amount: shopItemPrice,
          payload: body.toBoc().toString('base64'),
        },
      ],
    };

    const result = await tonConnectUI.sendTransaction(tx);

    const hash = Cell.fromBase64(result.boc).hash().toString('base64');

    // const txResult = await waitForTransaction(
    //   {
    //     address: wallet.account?.address ?? '',
    //     boc: result.boc,
    //   },
    //   client,
    // );

    if (!result) {
      return;
    }

    onTxSent();

    notifySuccess(
      'Transaction complete! Please hold on while we process your purchase — this may take up to 30 seconds.',
      { autoClose: 5000 },
    );

    console.log('Transaction completed', result);
    console.log('Start polling API for bought item');

    analyticsService.trackEvent({
      name: 'purchase',
      variables: {
        item: shopItemCode,
        amount: fromNano(shopItemPrice),
        currency: 'ton',
        transaction_hash: hash,
        invoice_id: invoice.id,
      },
    });

    const isItemAdded = await this.checkBoughtItemIsAdded(invoice.id, 20, 5000);

    if (isItemAdded) {
      await this.userService.fetchProfile();

      if (itemType === 'worm') {
        await this.getCharacters();
      }

      if (itemType === 'accessory') {
        await this.getAccessories();
      }

      onItemReceived?.();
    }

    console.log('shop item purchase completed');
  }

  async checkBoughtItemIsAdded(
    invoiceId: string,
    maxRetries = 15,
    pollInterval = 3000,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let attempts = 0;

      const checkApi = async () => {
        try {
          this.purchaseInProgress = true;

          const invoice = await this.checkInvoiceStatus(invoiceId);

          if (invoice.isPaid === true) {
            clearInterval(timer);
            this.purchaseInProgress = false;
            resolve(true);
          } else {
            console.log('API returned false, polling continues...');
          }

          attempts++;

          if (attempts >= maxRetries) {
            this.purchaseInProgress = false;
            clearInterval(timer);
            console.log('Max retries limit reached cancelling polling');
          }
        } catch (error) {
          clearInterval(timer);
          reject(error);
          this.purchaseInProgress = false;
        }
      };

      this.purchaseInProgress = true;

      const timer = setInterval(checkApi, pollInterval);
    });
  }

  async purchaseItemWithStars({
    shopItemCode,
    shopItemPrice,
    itemType,
    onInvoicePaid,
    onItemReceived,
  }: PurchaseItemWithStars) {
    const invoice = await this.createInvoice(
      shopItemCode,
      ShopInvoiceType.Stars,
    );
    const { invoiceLink, id } = invoice;

    const invoiceStatus = await new Promise<
      'paid' | 'cancelled' | 'failed' | 'pending'
    >((resolve) => {
      Telegram.WebApp.openInvoice(invoiceLink, (status) => resolve(status));
    });

    if (invoiceStatus === 'paid') {
      onInvoicePaid();

      analyticsService.trackEvent({
        name: 'purchase',
        variables: {
          item: shopItemCode,
          amount: shopItemPrice,
          currency: 'stars',
          invoice_id: id,
        },
      });

      const isItemAdded = await this.checkBoughtItemIsAdded(
        invoice.id,
        10,
        3000,
      );

      if (isItemAdded) {
        await this.userService.fetchProfile();

        if (itemType === 'worm') {
          await this.getCharacters();
        }

        if (itemType === 'accessory') {
          await this.getAccessories();
        }

        onItemReceived?.();
      }
    }
  }

  async createInvoice(
    code: string,
    type: ShopInvoiceType,
  ): Promise<ShopInvoice> {
    const invoice = await this.apiService.post<ShopInvoice>(
      '/versus/shop/create-invoice',
      {
        code,
        type,
      },
    );

    return invoice;
  }

  async checkInvoiceStatus(id: string): Promise<ShopInvoice> {
    const invoice = await this.apiService.get<ShopInvoice>(`/invoice/${id}`);

    return invoice;
  }
}

interface PurchaseItemWithTon {
  wallet: Wallet | (Wallet & WalletInfoWithOpenMethod) | null;
  tonConnectUI: TonConnectUI;
  tgUserId: string | number | undefined;
  shopItemCode: string;
  shopItemPrice: string;
  client?: TonClient;
  itemType: ShopItemType;
  onTxSent: () => void;
  onItemReceived?: () => void;
}

interface PurchaseItemWithStars {
  shopItemCode: string;
  shopItemPrice: number;
  itemType: ShopItemType;
  onInvoicePaid: () => void;
  onItemReceived?: () => void;
}
