import { useEffect, useState, useRef, useCallback } from 'react';
import {
  SpinePlayer,
  SpinePlayerConfig,
  StringMap,
} from '@esotericsoftware/spine-player';
import { versusCharacterService } from '@app/services';
import transparentPng from '@media/transparent-1px.png';
import { VersusAccessoryLocation } from '@app/types';

export interface SpineAnimationProps {
  config: SpinePlayerConfig;
  charCode?: string;
  skin?: string;
  accessoryName?: string | null;
  onLoad?: () => void;
  onAnimationComplete?: () => void;
}

interface AnimationOptions {
  loop?: boolean;
  force?: boolean;
  speed?: number;
  onComplete?: () => void;
}

const RE_INIT_DELAY_MS = 500;

export const useSpineAnimation = ({
  config,
  charCode,
  accessoryName,
  onLoad,
  onAnimationComplete,
}: SpineAnimationProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const spinePlayerRef = useRef<SpinePlayer | null>(null);

  const [rawConfig, setRawConfig] = useState<SpinePlayerConfig | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [animations, setAnimations] = useState<string[]>([]);

  const loadAssets = useCallback(async () => {
    if (!charCode) return;

    const wormAssets = versusCharacterService.generateCharAssets(charCode);

    if (!wormAssets) {
      return;
    }

    const skeletonName = 'worm.json';
    const atlasName = 'worm.atlas';

    const accessoryData = accessoryName
      ? await versusCharacterService.getSkinOrAccessoryFromIndexedDb(
          accessoryName,
          true,
        )
      : null;

    const accessorySlotMap: Record<string, string | null> = {
      [VersusAccessoryLocation.Face]: 'slap_2_worm_2.png',
      [VersusAccessoryLocation.Head]: 'slap_2_worm_5.png',
      [VersusAccessoryLocation.Body]: 'slap_2_worm.png',
    };

    const accessorySlot =
      accessorySlotMap[accessoryData?.itemLocation || ''] || null;

    const [skeletonData, atlasData, commonHands1, commonHands2, wormSkin] =
      await Promise.all([
        versusCharacterService.getItemFromIndexedDB('skeleton'),
        versusCharacterService.getItemFromIndexedDB('atlas'),
        versusCharacterService.getItemFromIndexedDB('commonHands1'),
        versusCharacterService.getItemFromIndexedDB('commonHands2'),
        versusCharacterService.getSkinOrAccessoryFromIndexedDb(charCode, false),
      ]);

    if (
      !atlasData ||
      !skeletonData ||
      !wormSkin ||
      !commonHands1 ||
      !commonHands2
    ) {
      return;
    }

    const getAccessoryBlob = (slotName: string) => {
      return accessorySlot === slotName
        ? accessoryData?.blob || transparentPng
        : transparentPng;
    };

    const rawDataURIs = {
      [skeletonName]: skeletonData.blob,
      [atlasName]: atlasData.blob,
      // BODY
      'slap_2_worm.png': getAccessoryBlob('slap_2_worm.png'),
      // FACE
      'slap_2_worm_2.png': getAccessoryBlob('slap_2_worm_2.png'),
      'slap_2_worm_3.png': commonHands1.blob,
      'slap_2_worm_4.png': commonHands2.blob,
      // HEAD
      'slap_2_worm_5.png': getAccessoryBlob('slap_2_worm_5.png'),
      'slap_2_worm_6.png': wormSkin.blob,
    };

    setRawConfig({
      skeleton: skeletonName,
      atlas: atlasName,
      rawDataURIs: rawDataURIs as StringMap<string>,
      preserveDrawingBuffer: false,
    });
  }, [charCode, accessoryName]);

  const initPlayer = useCallback(() => {
    if (!rawConfig) return;

    const playerConfig = {
      ...config,
      ...rawConfig,
      showLoading: false,

      success: (player: SpinePlayer) => {
        spinePlayerRef.current = player;
        setIsLoaded(true);
        onLoad?.();

        setAnimations(
          player.skeleton?.data.animations.map((anim) => anim.name) || [],
        );

        player?.animationState?.addListener({
          complete: () => onAnimationComplete?.(),
        });

        player.play();
      },
      error: (player: SpinePlayer, msg: string) => {
        console.error('Player failed to load:', msg);
        player.dispose();
        spinePlayerRef.current = null;
      },
    };

    if (containerRef.current) {
      spinePlayerRef.current = new SpinePlayer(
        containerRef.current,
        playerConfig,
      );
    }
  }, [config, rawConfig, onLoad, onAnimationComplete]);

  const reInit = async () => {
    setIsLoaded(false);

    setTimeout(() => {
      dispose();

      initPlayer();
    }, RE_INIT_DELAY_MS);
  };

  const dispose = () => {
    spinePlayerRef.current?.animationState?.clearTracks();
    spinePlayerRef.current?.animationState?.clearListeners();
    spinePlayerRef.current?.assetManager?.dispose();
    spinePlayerRef.current?.animationState?.clearListenerNotifications();
    spinePlayerRef.current?.dispose();
    spinePlayerRef.current = null;
  };

  useEffect(() => {
    loadAssets();
  }, [loadAssets]);

  useEffect(() => {
    if (rawConfig) {
      reInit();
    }

    window.addEventListener('beforeunload', () => dispose());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawConfig]);

  const changeAnimation = useCallback(
    (animationName: string, options: AnimationOptions = {}) => {
      const { loop = false, force = false, onComplete } = options;
      const player = spinePlayerRef.current;
      const animationState = player?.animationState;
      const hasAnimationName =
        player?.skeleton?.data.findAnimation(animationName);
      const speed = options.speed ?? 1;

      if (!animationState || !hasAnimationName) {
        console.error(`Animation "${animationName}" not found.`);

        return;
      }

      if (force) {
        animationState.setAnimation(0, animationName, loop);
        animationState.timeScale = speed;
      } else {
        animationState.addAnimation(0, animationName, loop);
      }

      animationState.addListener({
        start: (entry) => {
          if (entry.animation?.name === animationName) {
            animationState.timeScale = speed;
          }
        },
        complete: (entry) => {
          if (entry.animation?.name === animationName) {
            onComplete?.();
          }
        },
      });
    },
    [],
  );

  return {
    containerRef,
    changeAnimation,
    animations,
    player: spinePlayerRef.current,
    reInitDelayMs: RE_INIT_DELAY_MS,
    isLoaded,
    initPlayer,
    dispose,
  };
};
