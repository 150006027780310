import { DIContainer } from '@app/common/di';
import { UserService } from './UserService';
import { ConfigService } from './ConfigService';
import { QuestService } from './QuestService';
import { AnalyticsService } from './AnalyticsService';
import { CacheService } from './CacheService';
import { SoundService } from './SoundService';
import { ApiService } from './ApiService';
import { AuthService } from './AuthService';
import { VersusService } from './VersusService';
import { OnboardingService } from './OnboardingService';
import { SocketService } from './SocketService';
import { VersusLeagueService } from './VersusLeagueService';
import { VersusAudioService } from './VersusAudioService';
import { VersusCharacterService } from './VersusCharacterService';
import { VersusTournamentService } from './VersusTournamentService';
import { VersusShopService } from './VersusShopService';
import { ReferralService } from './ReferralService';
import { Web3Service } from './Web3Service';

export const cacheService = DIContainer.resolve(CacheService);
export const authService = DIContainer.resolve(AuthService);
export const apiService = DIContainer.resolve(ApiService);
export const userService = DIContainer.resolve(UserService);
export const configService = DIContainer.resolve(ConfigService);
export const questService = DIContainer.resolve(QuestService);
export const analyticsService = DIContainer.resolve(AnalyticsService);
export const soundService = DIContainer.resolve(SoundService);
export const versusService = DIContainer.resolve(VersusService);
export const onboardingService = DIContainer.resolve(OnboardingService);
export const socketService = DIContainer.resolve(SocketService);
export const versusLeagueService = DIContainer.resolve(VersusLeagueService);
export const versusAudioService = DIContainer.resolve(VersusAudioService);
export const versusShopService = DIContainer.resolve(VersusShopService);
export const versusCharacterService = DIContainer.resolve(
  VersusCharacterService,
);
export const versusTournamentService = DIContainer.resolve(
  VersusTournamentService,
);
export const referralService = DIContainer.resolve(ReferralService);
export const web3Service = DIContainer.resolve(Web3Service);
