import React from 'react';
import cn from 'classnames';
import { VersusPlayer } from '@app/types';
import { ProgressBar, VersusAvatar, VersusSkillsGroup } from '@components';
import { maskName } from '@app/utils';
import { useServiceState } from '@app/common/state';
import { onboardingService, versusCharacterService } from '@app/services';

import s from './PlayerInfo.module.scss';

interface PlayerInfoProps {
  player: VersusPlayer;
  mirror: boolean;
  transitionDelayMs?: number;
  className?: string;
}

export const PlayerInfo: React.FC<PlayerInfoProps> = ({
  player,
  mirror,
  transitionDelayMs,
  className,
}) => {
  const { isOnboarding } = useServiceState(onboardingService, ['isOnboarding']);

  const getFillBarColor = () => {
    const hpPercentage = (player.hp.current / player.hp.total) * 100;

    if (hpPercentage > 60) {
      return 'var(--action-primary-default)';
    } else if (hpPercentage > 25) {
      return '#FFC266';
    } else {
      return 'var(--decorative-action-red)';
    }
  };

  const playerAssets = versusCharacterService.generateCharAssets(
    player.character.code,
  );

  return (
    <div className={cn(s.root, { [s.mirror]: mirror }, className)}>
      <div className={s.row}>
        <VersusAvatar
          wormImage={playerAssets?.avatarSm ?? ''}
          leagueImage={player.rating?.leagueIcon}
          className={s.avatar}
        />
        <div className={s.column}>
          {player.name && (
            <div className={s.name}>
              {isOnboarding ? maskName(player.name) : `@${player.name}`}
            </div>
          )}
          {player.skills && (
            <VersusSkillsGroup items={player.skills} className={s.skills} />
          )}
        </div>
      </div>
      {player.hp && (
        <ProgressBar
          fillBarColor={getFillBarColor()}
          barBgColor={'var(--surface-secondary)'}
          variant="custom"
          minNumber={0}
          fillRadius={16}
          currentNumber={player.hp.current}
          maxNumber={player.hp.total}
          transitionDelayMs={transitionDelayMs}
          className={s.hp}
        />
      )}
    </div>
  );
};
