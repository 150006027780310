import cn from 'classnames';

import { ShopItemType, VersusAccessory, VersusPriceType } from '@app/types';
import {
  ShopAccessoryBonus,
  ShopPurchaseButtons,
  VersusPriceTag,
} from '@components';

import s from './PurchaseAccessoryBlock.module.scss';

interface PurchaseAccessoryBlockProps {
  itemId: string;
  itemName: string;
  price: number;
  priceFull?: number;
  priceStars?: number;
  priceTon: string | undefined;
  priceType: VersusPriceType;
  abilities: VersusAccessory['abilities'];
  className?: string;
}

export const PurchaseAccessoryBlock: React.FC<PurchaseAccessoryBlockProps> = ({
  itemId,
  itemName,
  price,
  priceFull,
  priceTon,
  priceStars,
  priceType,
  abilities,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <h3 className={s.name}>{itemName}</h3>
      <VersusPriceTag
        price={price}
        priceTon={priceTon}
        priceFull={priceFull}
        priceType={priceType}
        size="big"
      />
      {abilities.map((bonus, i) => (
        <ShopAccessoryBonus
          bonus={bonus}
          className={s.bonus}
          key={bonus.bonusType + i}
        />
      ))}
      <ShopPurchaseButtons
        code={itemId}
        price={price}
        priceTon={priceTon}
        priceType={priceType}
        priceStars={priceStars}
        className={s.buttons}
        itemType={ShopItemType.accessory}
      />
    </div>
  );
};
