import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { Injectable } from '@app/common/di';
import { MakeObservable, observable } from '@app/common/state';
import { AuthService } from '../AuthService';
import { ConfigService } from '../ConfigService';
import { notifyError } from '@app/ui-kit/ToastNotifications';
import { consoleErrorNotify } from '@app/utils';

@Injectable()
@MakeObservable
export class SocketService {
  @observable
  public socket: Socket | null = null;

  constructor(
    private readonly configService: ConfigService,
    private readonly authService: AuthService,
  ) {}

  connect() {
    if (this.socket?.connected) {
      console.log('Already connected to server');

      return;
    }

    const token = this.authService.getCredentials();

    if (!token) {
      throw new Error(
        'Cant init websocket connection as auth token not available',
      );
    }

    const options: Partial<ManagerOptions & SocketOptions> = {
      reconnection: true,
      reconnectionDelay: 500,
      transports: ['websocket'],
      auth: {
        token,
      },
    };

    this.socket = io(this.configService.wssUrl, options);
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  emit(eventName: string, data: unknown) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(eventName, data);
    } else {
      consoleErrorNotify(
        `Cannot emit event: ${eventName}. Socket is not connected.`,
      );
    }
  }

  emitWithRetry(
    eventName: string,
    data: unknown,
    timeout = 1500,
    maxRetries = 3,
  ) {
    let attempts = 0;

    const sendEvent = () => {
      if (!this.socket) {
        consoleErrorNotify(
          `Cannot emit event: ${eventName}. Socket is not connected.`,
        );

        return;
      }

      if (attempts >= maxRetries) {
        consoleErrorNotify(
          `Max retries reached for event ${eventName}. Giving up.`,
        );

        return;
      }

      attempts++;
      console.warn(`Emitting event ${eventName}, attempt ${attempts}`);

      this.socket
        .timeout(timeout)
        .emit(eventName, data, (err: Error | null) => {
          if (err) {
            consoleErrorNotify(
              `Acknowledgment for event ${eventName} timed out (attempt ${attempts}): ${err.message}`,
            );
            sendEvent();
          } else {
            console.log(`Acknowledgment received for event ${eventName}.`);
          }
        });
    };

    sendEvent();
  }

  on<T>(eventName: string, callback: (data: T) => void) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }

  once<T>(eventName: string, callback: (data: T) => void) {
    if (this.socket) {
      this.socket.once(eventName, callback);
    }
  }

  off<T>(eventName: string, callback?: (data: T) => void) {
    if (this.socket) {
      this.socket.off(eventName, callback);
    }
  }

  onDisconnect(reason: string) {
    console.log(`Socket disconnected: ${reason}`);
  }

  onConnectError(error: Error) {
    consoleErrorNotify(`Connection error: ${error.message}`);
  }
}
