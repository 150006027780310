import cn from 'classnames';

import { VersusAvatar } from '../VersusAvatar';
import { versusCharacterService } from '@app/services';
import { VersusPriceTag } from '../VersusPriceTag';
import { TournamentPastWinner, VersusPriceType } from '@app/types';
import { fromNano } from '@ton/core';

import s from './TournamentLeaderBoardTable.module.scss';

interface TournamentLeaderBoardTableProps {
  winners: TournamentPastWinner[];
  className?: string;
}

export const TournamentLeaderBoardTable: React.FC<
  TournamentLeaderBoardTableProps
> = ({ winners, className }) => {
  return (
    <div className={cn(s.root, className)}>
      <div className={s.tableHead}>
        <div>Place</div>
        <div>Earned</div>
      </div>
      <div className={s.tableBody}>
        {winners.map((el, i) => {
          const assets = versusCharacterService.generateCharAssets(
            el.character.code,
          );

          if (!assets) {
            return null;
          }

          return (
            <div className={s.item} key={el.name}>
              <div className={s.place}>{i + 1}</div>
              <VersusAvatar
                wormImage={assets.avatarSm}
                leagueImage={el.leagueIcon}
                className={s.avatar}
              />
              <div className={s.name}>{el.name}</div>
              <VersusPriceTag
                price={+fromNano(el.tonAmount)}
                priceType={VersusPriceType.Ton}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
