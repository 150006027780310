import { Injectable } from '@app/common/di';
import { MakeObservable, observable } from '@app/common/state';
import { ApiService } from '../ApiService';

@Injectable()
@MakeObservable
export class Web3Service {
  constructor(private readonly apiService: ApiService) {}

  @observable
  public tonPrice = 0;

  async getTonPrice() {
    try {
      this.tonPrice = await this.apiService.get<number>('/web3/ton-price');
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }
}
