import {
  Page,
  BackButton,
  VersusHeader,
  HomeWidgetSlapBeta,
  HomeWidgetPartnerEngage,
  DayChecklist,
  HomeHeroCard,
} from '@components';

import { useHideBackButton } from '@app/hooks';
import { ClaimNicknameBottomSheet } from '@app/components/ClaimNicknameBottomSheet';

import s from './HomePage.module.scss';

export const HomePage = () => {
  useHideBackButton();

  return (
    <Page className={s.root}>
      <BackButton onClick={() => window.Telegram.WebApp.close()} />
      <VersusHeader />
      <div className={s.content}>
        <HomeHeroCard variant="becomeBoss" />
        <HomeHeroCard variant="earn" />
        <HomeWidgetSlapBeta />
        <HomeWidgetPartnerEngage />
        <DayChecklist variant="widget" className={s.streak} />
      </div>
      <ClaimNicknameBottomSheet />
    </Page>
  );
};
