import cn from 'classnames';

import { ReactComponent as PendingIcon } from '@icons/pending.svg';
import { ReactComponent as TonIcon } from '@icons/ton-icon.svg';
import { Spinner } from '@app/ui-kit';
import { numberWithCommas } from '@app/utils';

import s from './ManagerEarningItem.module.scss';

interface ManagerEarningItemProps {
  variant: 'earned' | 'withdrawn' | 'pending' | 'available';
  amount: number;
  tonPrice: number;
  isLoading?: boolean;
  className?: string;
}

export const ManagerEarningItem: React.FC<ManagerEarningItemProps> = ({
  variant,
  amount,
  tonPrice,
  isLoading,
  className,
}) => {
  const usdEquivalent = numberWithCommas(Math.round(amount * tonPrice));

  return (
    <div className={cn(s.root, className)}>
      <div className={s.col}>
        {config[variant].title}
        <span className={s.description}>{config[variant].description}</span>
      </div>
      <div className={s.col}>
        <div className={s.amount}>
          <TonIcon className={s.tonIcon} />
          {isLoading ? (
            <Spinner className={s.loader} />
          ) : (
            <span>{numberWithCommas(amount)}</span>
          )}
        </div>
        {
          <span className={cn(s.usdEquivalent, { [s.hidden]: isLoading })}>
            ≈ ${usdEquivalent}
          </span>
        }
      </div>
    </div>
  );
};

const config = {
  earned: {
    title: <div className={s.title}>Earned:</div>,
    description: 'All-time earnings',
  },
  withdrawn: {
    title: <div className={s.title}>Withdrawn:</div>,
    description: 'Transferred to your wallet',
  },
  pending: {
    title: (
      <div className={cn(s.title, s.pending)}>
        <PendingIcon width={16} height={16} /> Pending:
      </div>
    ),
    description: 'Available soon',
  },
  available: {
    title: <div className={s.title}>Available:</div>,
    description: 'Withdraw now',
  },
};
