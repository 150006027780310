import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import cn from 'classnames';

import {
  MatchMakingStatus,
  MatchMakingWorm,
  Page,
  VersusPlayerBars,
} from '@components';
import { BackButton } from '@components';
import foregroundImg from '@media/matchmaking-foreground.png';
import { useServiceState } from '@app/common/state';
import { versusService, versusTournamentService } from '@app/services';
import tournamentRewardImg from '@media/phase-diamond-reward.png';

import s from './MatchMakingPage.module.scss';

export const MatchMakingPage = () => {
  const navigate = useNavigate();

  const { player, opponent, isConnected, isTournamentMode } = useServiceState(
    versusService,
    ['player', 'opponent', 'isConnected', 'isTournamentMode'],
  );

  const { isFinalBattle } = useServiceState(versusTournamentService, [
    'isFinalBattle',
  ]);

  useEffect(() => {
    if (!isConnected) {
      navigate('/play');
    }
  }, [isConnected, navigate]);

  const handleCancel = async () => {
    versusService.cancelMatchMaking();
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={handleCancel} />
      {isTournamentMode && isFinalBattle && (
        <motion.img
          initial={{ y: -400, scale: 0, x: '-50%' }}
          animate={{ y: 50, scale: 1, x: '-50%' }}
          transition={{
            type: 'spring',
            bounce: 0.4,
            duration: 2,
          }}
          src={tournamentRewardImg}
          alt="coins"
          className={s.cryptoRewardImg}
          key={isTournamentMode.toString()}
        />
      )}
      <VersusPlayerBars
        player={player}
        opponent={opponent}
        className={s.playersBar}
      />
      <div className={s.wrapper}>
        <MatchMakingWorm
          charCode={player?.character.code}
          accessoryName={player?.character.accessories?.[0]?.code}
          animationName={opponent ? 'matchmaking-emotion1' : 'matchmaking-idle'}
          className={s.player}
        />
      </div>
      {opponent && (
        <div className={s.wrapper}>
          <MatchMakingWorm
            charCode={opponent?.character.code}
            accessoryName={opponent?.character.accessories?.[0]?.code}
            animationName={
              opponent ? 'matchmaking-emotion2' : 'matchmaking-idle'
            }
            className={s.opponent}
          />
        </div>
      )}
      <motion.img
        initial={{ scale: 1.5 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'tween',
          duration: 0.5,
        }}
        src={foregroundImg}
        width={'100%'}
        height={'100%'}
        className={s.image}
        alt="ground"
      />
      <MatchMakingStatus
        mode={!opponent ? 'looking' : 'found'}
        onCancel={handleCancel}
        className={s.status}
      />
    </Page>
  );
};
