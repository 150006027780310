import cn from 'classnames';

import { ReactComponent as Success } from '@icons/success.svg';
import { ReactComponent as Warning } from '@icons/warning.svg';
import { ReactComponent as Info } from '@icons/info.svg';
import { ReactNode } from 'react';

import s from './InfoBlock.module.scss';
import React from 'react';

interface InfoBlockProps {
  message: string | ReactNode;
  variant?: 'success' | 'warning' | 'info';
  backgroundColor?: string;
  className?: string;
}

export const InfoBlock: React.FC<InfoBlockProps> = ({
  message,
  variant = 'success',
  backgroundColor,
  className,
}) => {
  const Icon = config[variant];

  const parseMessage = (text: string) => {
    const parts = text.split(/(<b>.*?<\/b>)/g);

    return parts.map((part, index) => {
      if (part.startsWith('<b>') && part.endsWith('</b>')) {
        return (
          <b key={index} className={s.bold}>
            {part.slice(3, -4)}
          </b>
        );
      }

      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className={cn(s.root, className)} style={{ backgroundColor }}>
      <Icon className={s.icon} />
      <div>
        {React.isValidElement(message) && message}
        {typeof message === 'string' && parseMessage(message)}
      </div>
    </div>
  );
};

const config = {
  success: Success,
  warning: Warning,
  info: Info,
};
