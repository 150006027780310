import cn from 'classnames';

import { ShopItemType, VersusPriceType, VersusUserAssetType } from '@app/types';
import { Button } from '@app/ui-kit';
import { useTonClient, useTonConnect } from '@app/hooks';
import { ShopPurchaseStatus } from '../ShopPurchaseStatus';
import { ButtonPurchaseStars } from '../ButtonPurchaseStars';
import { useState } from 'react';
import { useServiceState } from '@app/common/state';
import { apiService, userService, versusShopService } from '@app/services';

import s from './ShopPurchaseButtons.module.scss';
import { ShopPageSections, ShopPageTabs } from '@app/pages/shop/ShopPage';
import { notifyError } from '@app/ui-kit/ToastNotifications';

interface ShopPurchaseButtonsProps {
  code: string;
  price: number;
  priceTon: string | undefined;
  priceStars: number | undefined;
  priceType: VersusPriceType;
  itemType: ShopItemType;
  className?: string;
}

export const ShopPurchaseButtons: React.FC<ShopPurchaseButtonsProps> = ({
  code,
  price,
  priceTon,
  priceType,
  priceStars,
  itemType,
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);

  const [isLoading, setIsLoading] = useState(false);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const [purchaseType, setPurchaseType] = useState<VersusPriceType>();
  const { tonConnectUI, wallet } = useTonConnect();
  const { client } = useTonClient();
  const userId = userProfile?.id;
  const tomatoBalance = userProfile?.score ?? 0;
  const medalsBalance =
    userProfile?.versus?.assets.find(
      (el) => el.type === VersusUserAssetType.Medals,
    )?.amount ?? 0;
  const balance =
    priceType === VersusPriceType.Tomato ? tomatoBalance : medalsBalance;
  const purchaseDisabled =
    isLoading ||
    ((priceType === VersusPriceType.Tomato ||
      priceType === VersusPriceType.Medals) &&
      balance < price);

  const handlePurchaseClick = async (priceType: VersusPriceType) => {
    if (priceType === VersusPriceType.Ton) {
      if (!wallet?.account.address) {
        await tonConnectUI.openModal();

        return;
      }
    }

    handlePurchase(priceType);
  };

  const handlePurchase = async (purchaseType: VersusPriceType) => {
    setIsLoading(true);

    try {
      setPurchaseType(purchaseType);

      if (
        purchaseType === VersusPriceType.Tomato ||
        purchaseType === VersusPriceType.Medals
      ) {
        if (itemType === ShopItemType.worm) {
          await versusShopService.purchaseCharacterWithGameCurrency(
            code,
            purchaseType,
            price,
          );
        }

        if (itemType === ShopItemType.accessory) {
          await versusShopService.purchaseAccessoryWithTomato(code);
        }
      }

      if (purchaseType === VersusPriceType.Ton) {
        await versusShopService.purchaseWithTon({
          wallet,
          client,
          tonConnectUI,
          tgUserId: userId,
          shopItemCode: code,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shopItemPrice: priceTon!,
          itemType: itemType,
          onTxSent: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }

      if (purchaseType === VersusPriceType.Stars) {
        await versusShopService.purchaseItemWithStars({
          shopItemCode: code,
          shopItemPrice: price,
          itemType: itemType,
          onInvoicePaid: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }
    } catch (e) {
      apiService.notifyError(e);
    }

    setIsLoading(false);
  };

  return (
    <div className={cn(s.root, className)}>
      {(priceType === VersusPriceType.Tomato ||
        priceType === VersusPriceType.Medals) && (
        <Button
          onClick={() => handlePurchaseClick(priceType)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {isLoading ? 'Processing...' : 'Purchase'}
        </Button>
      )}
      {(purchaseType === VersusPriceType.Ton && isLoading) ||
      !priceTon ? null : (
        <Button
          onClick={() => handlePurchaseClick(VersusPriceType.Ton)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {!wallet?.account.address ? 'Connect Wallet' : 'Purchase using TON'}
        </Button>
      )}
      {priceStars && priceStars > 0 && !purchaseCompleted && (
        <ButtonPurchaseStars
          onClick={() => handlePurchaseClick(VersusPriceType.Stars)}
          disabled={purchaseDisabled}
          stars={priceStars || 0}
        />
      )}
      <ShopPurchaseStatus
        isCompleted={purchaseCompleted}
        type={purchaseType}
        className={s.status}
      />
    </div>
  );
};
