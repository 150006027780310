import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Button } from '@app/ui-kit';
import {
  apiService,
  configService,
  versusTournamentService,
} from '@app/services';
import {
  TournamentStage,
  TournamentRewardType,
  VersusUserAssetType,
} from '@app/types';
import rewardDiamondPhase from '@media/phase-diamond-reward.png';
import {
  VERSUS_PHASE_ENTRY,
  VERSUS_PHASE_REWARDS,
} from '@app/constants/versus';

import { ShopPageSections, ShopPageTabs } from '@app/pages/shop/ShopPage';

import { TournamentEntryFee } from '../TournamentEntryFee';
import { TonPhaseReward } from '../TonPhaseReward';

import s from './PhaseSelectCard.module.scss';

interface PhaseSelectCardProps {
  scrollTargetId: string;
  data: TournamentStage;
  canChangeChar: boolean | undefined;
  claimableTonAmount: number;
  className?: string;
}

export const PhaseSelectCard: React.FC<PhaseSelectCardProps> = ({
  scrollTargetId,
  data,
  canChangeChar,
  claimableTonAmount,
  className,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const {
    title,
    rewards,
    description,
    entranceFee,
    isEntered,
    isCurrent,
    isCompleted,
    stage,
  } = data;

  const canEnter = versusTournamentService.hasRequiredAssets();
  const cryptoReward = rewards.find(
    (el) => el.type === TournamentRewardType.Ton,
  );

  const userTicketsAmount =
    versusTournamentService.getUserEntryTicketInfo()?.amount;

  const filteredRewards = cryptoReward
    ? rewards.filter((el) => el.type !== TournamentRewardType.Ton)
    : rewards;

  const canClaimTon = cryptoReward?.amount === claimableTonAmount;

  const showButton = (isCurrent && !isCompleted) || canClaimTon;

  const handleButton = async () => {
    if (canClaimTon) {
      setIsLoading(true);

      try {
        await versusTournamentService.claimGoldenStageReward();
      } catch (e) {
        apiService.notifyError(e);
      }

      setIsLoading(false);

      return;
    }

    if (isEntered) {
      handleNavigateNext();

      return;
    }

    if (!canEnter && stage === 1) {
      navigate(
        `/shop?tab=${ShopPageTabs.default}&section=${ShopPageSections.ticketsDefault}`,
      );

      return;
    }

    if (!canEnter) {
      return;
    }

    try {
      setIsLoading(true);

      if (stage === 1) {
        await versusTournamentService.joinTournament();
      } else {
        await versusTournamentService.enterNextStage();
      }

      handleNavigateNext();
    } catch (e) {
      apiService.notifyError(e);
    }

    setIsLoading(false);
  };

  const handleNavigateNext = () => {
    if (canChangeChar || canChangeChar === undefined) {
      navigate('/tournament-char-manage');
    } else {
      navigate('/tournament-phase');
    }
  };

  const getButtonText = () => {
    if (!canEnter && stage === 1 && !isEntered) {
      return 'Get a Ticket';
    }

    if (!canEnter && stage > 1 && !isEntered) {
      return `Error: ${entranceFee[0].type} not found`;
    }

    if (!isEntered && canEnter) {
      return 'Enter';
    }

    if (canClaimTon) {
      return `Claim ${cryptoReward.amount} TON and Exit Tournament`;
    }

    if (isEntered) {
      return 'Continue';
    }

    return 'Unknown state';
  };

  return (
    <div
      className={cn(s.root, className)}
      id={isCurrent ? scrollTargetId : undefined}
    >
      <h3 className={s.title}>{title}</h3>
      {cryptoReward && (
        <TonPhaseReward amount={cryptoReward.amount} size={'normal'} />
      )}
      <div className={s.rewards}>
        {filteredRewards.map((reward, i) => {
          const rewardInfo = VERSUS_PHASE_REWARDS[reward.type];
          const title =
            reward.amount > 1
              ? `${reward.amount} ${rewardInfo.title}`
              : rewardInfo.title;

          return (
            <div key={reward.type} className={s.reward}>
              <img
                src={rewardInfo.image}
                className={s.rewardImg}
                alt="reward"
              />
              <div>{title}</div>
            </div>
          );
        })}
      </div>
      {!isCurrent && !isCompleted && description && (
        <div className={s.entryNote}>{description}</div>
      )}
      {isCurrent && !isCompleted && (
        <div className={s.entry}>
          <h4 className={s.entryTitle}>Entry</h4>
          <div className={s.entryCard}>
            {entranceFee.map((el) => (
              <TournamentEntryFee key={el.type} type={el.type} />
            ))}
          </div>
        </div>
      )}
      {isCurrent && !isEntered && !isCompleted && canEnter && (
        <div className={s.youHave}>
          You have:
          <img
            src={VERSUS_PHASE_ENTRY[entranceFee[0].type].image}
            alt="ticket"
          />
          <span>{userTicketsAmount}</span>
        </div>
      )}
      {isCompleted && <div className={s.completed}>Completed!</div>}

      {showButton && (
        <Button
          onClick={handleButton}
          disabled={isLoading}
          className={s.button}
        >
          {getButtonText()}
        </Button>
      )}

      {configService.env !== 'production' && (
        <Button
          className={s.button}
          variant="unfilled"
          onClick={() =>
            versusTournamentService.DEV_addAssets({
              type: entranceFee[0].type as unknown as VersusUserAssetType,
              amount: 1,
            })
          }
        >
          DEV: Get {entranceFee[0].type}
        </Button>
      )}
    </div>
  );
};
