import { Injectable } from '@app/common/di';
import { MakeObservable, observable } from '@app/common/state';
import { ApiService } from '../ApiService';
import {
  PhaseEntryFee,
  PhaseEntryTicket,
  CryptoWithdrawal,
  CryptoWithdrawalStatus,
  TournamentPastWinner,
  TournamentRewardType,
  TournamentStage,
  TournamentStatus,
  VersusMatchOutcome,
  type VersusTournament,
  VersusTournamentStatusRes,
  VersusUserAsset,
  VersusUserAssetType,
  VersusWssSkill,
  VersusWssVictoryReward,
  VersusWssVictoryRewardType,
  CryptoWithdrawalType,
} from '@app/types';

import battleBackgroundStage1 from '@media/tournament-background-ring.png';
import battleBackgroundStage2 from '@media/tournament-background-ring.png';
import battleBackgroundStage3 from '@media/tournament-background-ring.png';

import indicatorCoverStage1 from '@media/versus/wheel-cover-stage-1.png';
import indicatorCoverStage2 from '@media/versus/wheel-cover-stage-1.png';
import indicatorCoverStage3 from '@media/versus/wheel-cover-stage-1.png';
import { analyticsService, userService } from '..';
import { notifyError, notifySuccess } from '@app/ui-kit/ToastNotifications';
import { UserService } from '../UserService';
import { toNano } from '@ton/core';
import { VERSUS_PHASE_ENTRY } from '@app/constants/versus';
import { VersusShopService } from '../VersusShopService';

@Injectable()
@MakeObservable
export class VersusTournamentService {
  @observable
  public currentStage: number | null = null;

  @observable
  public tournamentData: VersusTournament | null = null;

  @observable
  public isFinalBattle = false;

  @observable
  public cryptoRewardAmount = 0;

  @observable
  public currentMatchNumber = 0;

  @observable
  public availableRewards: CryptoWithdrawal[] = [];

  @observable
  public pastWinners: TournamentPastWinner[] = [];

  private pollingInterval: number | undefined;
  private timeoutId: number | undefined;

  constructor(
    private readonly apiService: ApiService,
    private readonly userService: UserService,
    private readonly versusShopService: VersusShopService,
  ) {
    this.currentMatchNumber =
      Number(localStorage.getItem(this.lastMatchStorageKey)) ?? 0;
  }

  private lastMatchStorageKey = 'lastMatchNumber';

  private readonly battleBackgrounds: Record<number, string> = {
    1: battleBackgroundStage1,
    2: battleBackgroundStage2,
    3: battleBackgroundStage3,
  };

  private readonly indicatorCovers: Record<number, string> = {
    1: indicatorCoverStage1,
    2: indicatorCoverStage2,
    3: indicatorCoverStage3,
  };

  public readonly matchesPerStage = 3;

  public getStageDataById(phaseId: number | null): TournamentStage | null {
    if (!this.tournamentData) {
      console.error('Tournament data is not available.');

      return null;
    }

    const stage = this.tournamentData.stages?.find(
      (el) => el.stage === phaseId,
    );

    if (!stage) {
      console.error(`No stage found for phase: ${phaseId}`);

      return null;
    }

    return stage;
  }

  public getBattleBackground(): string | null {
    if (this.currentStage) {
      return this.battleBackgrounds[this.currentStage];
    }

    return null;
  }

  public getIndicatorCover() {
    if (this.currentStage) {
      return this.indicatorCovers[this.currentStage];
    }

    return null;
  }

  async getInitialTournamentData() {
    try {
      await this.getTournament();

      const canContinueOldTournament = this.canContinueOldTournament();

      if (canContinueOldTournament) {
        await this.getLastTournament();
      }
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async getTournament() {
    try {
      const res =
        await this.apiService.get<VersusTournament>('/versus/tournament');

      if (!this.availableRewards.length) {
        await this.getAvailableCryptoRewards();
      }

      this.updateTournamentData(res);
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async getLastTournament() {
    const res = await this.apiService.get<VersusTournament>(
      '/versus/tournament/last',
    );

    this.updateTournamentData(res);
  }

  async getTournamentStatus() {
    const status = await this.apiService.get<VersusTournamentStatusRes>(
      '/versus/tournament/status',
    );

    return status;
  }

  // call ONLY to enter stage: 1
  async joinTournament() {
    this.isFinalBattle = false;

    const res = await this.apiService.post<VersusTournament>(
      '/versus/tournament/join',
    );

    this.updateTournamentData(res);
  }

  async selectCharacter(code: string) {
    const res = await this.apiService.post<VersusTournament>(
      '/versus/tournament/set-character',
      { code },
    );

    this.updateTournamentData(res);
  }

  async setAccessory(code: string | null) {
    const res = await this.apiService.post<VersusTournament>(
      '/versus/tournament/set-accessory',
      {
        code,
      },
    );

    this.updateTournamentData(res);
  }

  // call to enter stage for all stages except stage: 1
  async enterNextStage() {
    this.isFinalBattle = false;

    const res = await this.apiService.post<VersusTournament>(
      '/versus/tournament/enter-next-stage',
    );

    this.tournamentData = res;

    if (this.currentStage) {
      this.currentStage += 1;
    }
  }

  async resumeStage() {
    this.isFinalBattle = false;

    try {
      const res = await this.apiService.post<VersusTournament>(
        '/versus/tournament/resume',
      );

      await this.userService.fetchProfile();

      this.updateTournamentData(res);
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  private setCurrentStage(data: VersusTournament) {
    const currentStage = data?.stages?.find(
      (stage) => stage?.isCurrent && stage?.isEntered,
    );

    if (!currentStage) {
      const lastActiveStage = data?.stages
        ?.reverse()
        .find((stage) => stage?.isEntered && stage?.isCompleted);

      this.currentStage = lastActiveStage ? lastActiveStage.stage : null;
    } else {
      this.currentStage = currentStage.stage;
    }
  }

  private updateTournamentData(data: VersusTournament) {
    this.tournamentData = data;

    const lastStageRewards = data?.stages?.[data.stages.length - 1].rewards;

    this.cryptoRewardAmount =
      lastStageRewards?.find(
        (reward) => reward.type === TournamentRewardType.Ton,
      )?.amount ?? 0;

    this.setCurrentStage(data);
  }

  async getTournamentByOutcome(outcome: VersusMatchOutcome) {
    if (
      outcome === VersusMatchOutcome.Defeat ||
      (outcome === VersusMatchOutcome.Victory && this.isFinalBattle)
    ) {
      this.getLastTournament();
    } else {
      this.getTournament();
    }

    if (this.isFinalBattle && outcome === VersusMatchOutcome.Victory) {
      this.getAvailableCryptoRewards();
    }
  }

  checkIsLastMatch(victoryRewards: VersusWssVictoryReward[]) {
    const finalRewards = [
      VersusWssVictoryRewardType.DiamondTicket,
      VersusWssVictoryRewardType.GoldenTicket,
      VersusWssVictoryRewardType.Ton,
    ];

    return victoryRewards.some((el) => finalRewards.includes(el.type));
  }

  setIsFinalBattle() {
    const isFinalStage = this.currentStage === this.matchesPerStage;
    const currentStage = this.getStageDataById(this.matchesPerStage);
    const hasEnoughVictories =
      currentStage?.matches.filter(
        (el) => el.outcome === VersusMatchOutcome.Victory,
      ).length ===
      this.matchesPerStage - 1;

    if (isFinalStage && hasEnoughVictories) {
      this.isFinalBattle = true;
    } else {
      this.isFinalBattle = false;
    }
  }

  setCurrentMatchNumber(number: number) {
    this.currentMatchNumber = number;

    localStorage.setItem(this.lastMatchStorageKey, number.toString());
  }

  getBattleEventVariables() {
    if (
      !this.currentStage ||
      !this.tournamentData ||
      !this.tournamentData.character
    ) {
      return null;
    }

    const { character } = this.tournamentData;
    const findSkillLevel = (skillId: VersusWssSkill) =>
      character.skills.find((skill) => skill.id === skillId)?.level || 0;

    return {
      phase: this.currentStage,
      battle_number: this.currentMatchNumber,
      worm_skin: character.code,
      power: findSkillLevel(VersusWssSkill.Damage),
      resilience: findSkillLevel(VersusWssSkill.Resilience),
      reaction: findSkillLevel(VersusWssSkill.Reaction),
    };
  }

  sendBattleStartedEvent() {
    const variables = this.getBattleEventVariables();

    if (!variables) {
      return;
    }

    analyticsService.trackEvent({
      name: 'tournament_battle_started',
      variables,
    });
  }

  sendBattleEndedEvent(outcome: VersusMatchOutcome) {
    const generatedVariables = this.getBattleEventVariables();

    if (!generatedVariables) {
      return;
    }

    const variables = {
      outcome,
      ...generatedVariables,
    };

    analyticsService.trackEvent({
      name: 'tournament_battle_ended',
      variables,
    });
  }

  getCurrentStageInfo = () => {
    return this.tournamentData?.stages.find((stage) => stage.isCurrent);
  };

  getUserEntryTicketInfo = () => {
    const currentStageData = this.getCurrentStageInfo();

    return this.userService.userProfile?.versus?.assets.find(
      (asset) =>
        asset.type ===
        (currentStageData?.entranceFee[0]
          .type as unknown as VersusUserAssetType),
    );
  };

  getStageEntryTicketInfo = () => {
    const currentStageData = this.getCurrentStageInfo();

    return currentStageData?.entranceFee.find((el) =>
      el.type.toString().toLowerCase().includes('ticket'),
    );
  };

  getStageEntryTicketPrice = () => {
    const ticket = this.getStageEntryTicketInfo();
    const shopItems = this.versusShopService.shopItems;

    if (!ticket || !shopItems) {
      return null;
    }

    const shopTicket = shopItems.find((el) => el.code === ticket.code);

    return shopTicket;
  };

  getTicketPriceByType = (ticketType: PhaseEntryTicket) => {
    const shopItems = this.versusShopService.shopItems;

    return shopItems?.find((el) =>
      el.code.includes(VERSUS_PHASE_ENTRY[ticketType].shopCode),
    );
  };

  hasRequiredAssets = (): boolean => {
    const currentStageData = this.getCurrentStageInfo();

    if (!currentStageData) {
      return false;
    }

    return currentStageData?.entranceFee.every((fee) => {
      const userAsset = this.getUserEntryTicketInfo();

      return userAsset && userAsset.amount >= fee.amount;
    });
  };

  canContinueOldTournament() {
    if (this.tournamentData?.status === TournamentStatus.Active) {
      return false;
    }

    const userAssets = this.userService.userProfile?.versus?.assets || [];

    const sortedPhases = this.tournamentData?.stages.sort(
      (a, b) => b.stage - a.stage,
    );

    return sortedPhases?.find((phase) => {
      if (phase.stage === 1) {
        return false;
      }

      return phase.entranceFee.some((fee) =>
        userAssets.some(
          (asset) =>
            asset.type === (fee.type as unknown as VersusUserAssetType) &&
            asset.amount >= fee.amount,
        ),
      );
    });
  }

  async getAvailableCryptoRewards() {
    try {
      this.availableRewards = await this.apiService.get<CryptoWithdrawal[]>(
        '/versus/withdrawal',
        {
          type: CryptoWithdrawalType.TournamentWin,
          status: [CryptoWithdrawalStatus.Pending],
        },
      );
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async withdrawTournamentCryptoReward(id: string) {
    try {
      await this.apiService.post<CryptoWithdrawal[]>(
        '/versus/withdrawal/withdraw',
        { id },
      );

      await this.startRewardsPolling(id);
    } catch (e) {
      this.apiService.notifyError(e);
      await this.getAvailableCryptoRewards();
    }
  }

  getCryptoRewardById(id: string) {
    return this.availableRewards.find((reward) => reward.id === id);
  }

  startRewardsPolling(id: string): Promise<void> {
    const POLLING_INTERVAL_MS = 10000;
    const TIMEOUT_MS = 120000;
    let elapsedTime = 0;

    this.stopRewardsPolling();

    return new Promise<void>((resolve, reject) => {
      const poll = async () => {
        try {
          await this.getAvailableCryptoRewards();

          const reward = this.availableRewards.find((r) => r.id === id);

          if (
            reward &&
            (reward.status === CryptoWithdrawalStatus.ProcessingError ||
              reward.status === CryptoWithdrawalStatus.Completed)
          ) {
            this.stopRewardsPolling();
            resolve();

            return;
          }

          elapsedTime += POLLING_INTERVAL_MS;

          if (elapsedTime >= TIMEOUT_MS) {
            this.stopRewardsPolling();
            reject(new Error('Polling timeout reached.'));
          }
        } catch (e) {
          this.apiService.notifyError(e);
          this.stopRewardsPolling();
          reject(e);
        }
      };

      this.pollingInterval = window.setInterval(poll, POLLING_INTERVAL_MS);

      poll();

      this.timeoutId = window.setTimeout(() => {
        this.stopRewardsPolling();
        reject(new Error('Polling timeout reached.'));
      }, TIMEOUT_MS);
    });
  }

  stopRewardsPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = undefined;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  async claimGoldenStageReward() {
    const res = await this.apiService.post<VersusTournament>(
      '/versus/tournament/claim-golden-stage-reward',
    );

    await this.getAvailableCryptoRewards();

    analyticsService.trackEvent({
      name: '4_ton_claim',
    });

    this.updateTournamentData(res);
  }

  async getTournamentPastWinnersStat() {
    try {
      this.pastWinners = await this.apiService.get(
        '/versus/tournament/past-winners',
      );
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async DEV_addAssets(asset: VersusUserAsset) {
    try {
      await this.apiService.post('/dev/versus-add-assets', { ...asset });
      await userService.fetchProfile();
      notifySuccess(`Added x ${asset.amount} - ${asset.type}!`);
    } catch (e) {
      notifyError(`${e}`);
    }
  }

  async DEV_createCryptoPrize() {
    await this.apiService.post('/dev/create-withdrawal', {
      amount: toNano(0.001).toString(),
      type: CryptoWithdrawalType.TournamentWin,
    });
    await this.getAvailableCryptoRewards();
  }

  async DEV_winTournamentMatch() {
    if (!this.currentStage) {
      await this.DEV_addAssets({
        type: VersusUserAssetType.SilverTicket,
        amount: 1,
      });
      await this.joinTournament();
    }

    await this.apiService.post('/dev/win-tournament-game');

    await this.getTournament();
  }
}
