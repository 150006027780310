import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ShopAccessoryCard, ShopItemCard, WormCard } from '@components';
import { useServiceState } from '@app/common/state';
import { versusShopService } from '@app/services';
import { VersusAccessory, VersusPriceType, VersusWormData } from '@app/types';
import { ShopPageSections } from '@app/pages/shop/ShopPage';

import s from './DefaultShop.module.scss';

export const DefaultShop: React.FC = () => {
  const { shopCharacters, shopAccessories, shopItems } = useServiceState(
    versusShopService,
    ['shopCharacters', 'shopAccessories', 'shopItems'],
  );

  const navigate = useNavigate();

  const openWormPreview = (worm: VersusWormData) => {
    navigate(`/shop-worm-preview/${worm.code}`);
  };

  const openAccessoryPreview = (accessory: VersusAccessory) => {
    navigate(`/shop-accessory-preview/${accessory.code}`);
  };

  const openItemPreview = (id: string, backNavSectionId: string) => {
    navigate(`/shop-item-preview/${id}/${backNavSectionId}`);
  };

  const cucumbers = shopItems?.filter((el) => el.code.includes('cucumbers'));
  const silverTickets = shopItems?.filter((el) =>
    el.code.includes('silvertickets'),
  );

  const worms = shopCharacters?.filter(
    (char) => char.priceType !== VersusPriceType.Medals,
  );

  return (
    <div className={s.root}>
      <div className={s.section} id={ShopPageSections.ticketsDefault}>
        <h3 className={s.sectionTitle}>Tournament Entry Tickets</h3>

        <div className={s.itemCards}>
          {silverTickets?.map((item, i) => (
            <ShopItemCard
              key={item.code + i}
              data={item}
              onClick={() => openItemPreview(item.code, 'tickets')}
            />
          ))}
        </div>
      </div>
      <div className={s.section} id={ShopPageSections.cucumbersDefault}>
        <h3 className={s.sectionTitle}>Cucumbers</h3>
        <div className={s.itemCards}>
          {cucumbers?.map((item, i) => (
            <ShopItemCard
              key={item.code + i}
              data={item}
              onClick={() => openItemPreview(item.code, 'cucumbers')}
            />
          ))}
        </div>
      </div>

      <div className={cn(s.section)} id={ShopPageSections.wormsDefault}>
        <h3 className={s.sectionTitle}>Assemble your team</h3>
        <div className={cn(s.cards)}>
          {worms?.map((worm, i) => (
            <WormCard
              id={worm.code}
              wormInfo={worm}
              key={worm.code + i}
              onClick={() => openWormPreview(worm)}
            />
          ))}
        </div>
      </div>

      <div className={s.section} id={ShopPageSections.accessoriesDefault}>
        <h3 className={s.sectionTitle}>Refresh your wormdrobe</h3>
        <div className={s.cards}>
          {shopAccessories?.map((accessory) => (
            <ShopAccessoryCard
              accessory={accessory}
              key={accessory.code}
              onClick={openAccessoryPreview}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
