import { BottomSheet, Button } from '@app/ui-kit';

import s from './ConfirmNicknameBottomSheet.module.scss';

interface ConfirmNicknameBottomSheetProps {
  nickname: string | undefined;
  isOpen: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onClose: (res: boolean) => void;
}

export const ConfirmNicknameBottomSheet: React.FC<
  ConfirmNicknameBottomSheetProps
> = ({ nickname, isOpen, isLoading, onConfirm, onClose }) => {
  return (
    <BottomSheet
      contentClassName={s.root}
      bgColor={'var(--surface-primary)'}
      withClose={false}
      open={isOpen}
    >
      <h2 className={s.title}>Just checking</h2>
      <p className={s.description}>
        Are you sure you want to change your nickname to <b>{nickname}</b>?
      </p>
      <Button onClick={onConfirm} disabled={isLoading} className={s.confirm}>
        Yes, change
      </Button>
      <Button
        variant="secondary"
        disabled={isLoading}
        onClick={() => onClose(false)}
      >
        Nope, go back
      </Button>
    </BottomSheet>
  );
};
