import { useEffect, useState } from 'react';
import { isErrored } from 'stream';

export const useCountDown = (targetDate: Date) => {
  const isValidDate =
    targetDate instanceof Date && !isNaN(targetDate.getTime());

  const countDownDate = isValidDate ? targetDate.getTime() : 0;
  const [countDown, setCountDown] = useState(
    isValidDate ? countDownDate - Date.now() : 0,
  );

  useEffect(() => {
    if (!isValidDate) return;

    const interval = setInterval(() => {
      setCountDown(countDownDate - Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate, isValidDate]);

  return getReturnValues(countDown, isValidDate);
};

const formatWithLeadingZero = (value: number) => {
  return value < 10 ? `0${value}` : value.toString();
};

const getReturnValues = (countDown: number, isValidDate: boolean) => {
  if (!isValidDate) {
    return {
      days: '--',
      hours: '--',
      minutes: '--',
      seconds: '--',
      isCompleted: false,
      currentValue: null,
    };
  }

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const formattedDays = formatWithLeadingZero(days);
  const formattedHours = formatWithLeadingZero(hours);
  const formattedMinutes = formatWithLeadingZero(minutes);
  const formattedSeconds = formatWithLeadingZero(seconds);

  const isCompleted = countDown <= 0;

  let currentUnit = '';
  let currentValue = '';

  if (days > 0) {
    currentUnit = days === 1 ? 'day' : 'days';
    currentValue = `${days} ${currentUnit}`;
  } else if (hours > 0) {
    currentUnit = hours === 1 ? 'hour' : 'hours';
    currentValue = `${hours} ${currentUnit}`;
  } else if (minutes > 0) {
    currentUnit = minutes === 1 ? 'minute' : 'minutes';
    currentValue = `${minutes} ${currentUnit}`;
  } else if (seconds > 0) {
    currentUnit = seconds === 1 ? 'second' : 'seconds';
    currentValue = `${seconds} ${currentUnit}`;
  } else {
    currentValue = 'Time is up';
  }

  return {
    days: formattedDays,
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
    isCompleted,
    currentValue,
  };
};
