import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { WormCard } from '@components';
import { useServiceState } from '@app/common/state';
import { versusShopService } from '@app/services';
import { VersusPriceType, VersusWormData } from '@app/types';
import { ShopPageSections } from '@app/pages/shop/ShopPage';
import medalShopImage from '@media/medal-shop.png';

import s from './MedalShop.module.scss';

export const MedalShop: React.FC = () => {
  const { shopCharacters } = useServiceState(versusShopService, [
    'shopCharacters',
  ]);

  const navigate = useNavigate();

  const openWormPreview = (worm: VersusWormData) => {
    navigate(`/shop-worm-preview/${worm.code}`);
  };

  const worms = shopCharacters?.filter(
    (char) => char.priceType === VersusPriceType.Medals,
  );

  return (
    <div className={s.root}>
      <h1 className={s.title}>Medal Shop</h1>
      <img src={medalShopImage} className={s.medalShopImage} alt="medals" />
      <p className={s.description}>
        Earn medals in tournaments and grab exclusives
      </p>
      <div className={cn(s.section)} id={ShopPageSections.wormsMedals}>
        <h3 className={s.sectionTitle}>Assemble your team</h3>
        <div className={cn(s.cards)}>
          {worms?.map((worm, i) => (
            <WormCard
              id={worm.code}
              wormInfo={worm}
              key={worm.code + i}
              onClick={() => openWormPreview(worm)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
