import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AnimatePresence, m } from 'framer-motion';

import {
  BackButton,
  ManagerHistorySection,
  ManagerOfficeSection,
} from '@app/components';
import { Button, Tabs } from '@app/ui-kit';
import { ReactComponent as HelpIcon } from '@icons/help.svg';
import managerImg from '@media/manager-hero.png';

import s from './ManagerPage.module.scss';

export enum ManagerPageTabs {
  office = 'office',
  history = 'history',
}

export const ManagerPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') as ManagerPageTabs;
  const [currentTab, setCurrentTab] = useState<ManagerPageTabs | null>(
    tab ?? ManagerPageTabs.office,
  );
  const [direction, setDirection] = useState<number>(0);

  const handleTabChange = (tab: ManagerPageTabs) => {
    setDirection(tab === ManagerPageTabs.office ? -1 : 1);
    setCurrentTab(tab);
    setSearchParams((prev) => {
      prev.set('tab', tab);

      return prev;
    });
  };

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab);
    }
  }, [tab, currentTab]);

  return (
    <div className={s.root}>
      <BackButton onClick={() => navigate('/')} />
      <h1 className={s.title}>Become a Manager</h1>
      <p className={s.description}>
        Bring tough slappers to the game, get 20% every time they win the
        Tournament
      </p>
      <img src={managerImg} alt="manager" className={s.heroImage} />
      <div className={s.tabs}>
        <Tabs
          initialActiveTab={tab || ManagerPageTabs.office}
          currentActiveTab={currentTab}
          variant="secondary"
          tabs={[
            { key: ManagerPageTabs.office, label: 'Office' },
            { key: ManagerPageTabs.history, label: 'History' },
          ]}
          onTabChange={handleTabChange}
        />
      </div>
      <AnimatePresence custom={direction} mode="popLayout" initial={false}>
        {currentTab === ManagerPageTabs.office && (
          <m.div
            key="office"
            className={s.content}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={transition}
          >
            <ManagerOfficeSection />
          </m.div>
        )}
        {currentTab === ManagerPageTabs.history && (
          <m.div
            key="history"
            className={s.content}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={transition}
          >
            <ManagerHistorySection />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? '100%' : '-100%',
  }),
  center: {
    x: 0,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? '100%' : '-100%',
  }),
};

const transition = {
  type: 'tween',
  duration: 0.6,
};
