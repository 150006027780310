import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import iconReaction from '@app/assets/media/skills/reaction.png';
import { NavItem } from '@app/components/NavBars/NavItem';
import { Button } from '@app/ui-kit';
import { RankWithProgress } from '@components';
import iconEquip from '@media/equip-icon.png';
import iconChange from '@media/change-icon.png';

import {
  onboardingService,
  userService,
  versusAudioService,
  versusService,
} from '@app/services';
import { useServiceState } from '@app/common/state';

import s from './SlapVersusMenu.module.scss';

interface SlapVersusMenuProps {
  isTournament?: boolean;
  className?: string;
  changeButtonClassName?: string;
  equipButtonClassName?: string;
}

export const SlapVersusMenu: React.FC<SlapVersusMenuProps> = ({
  isTournament = false,
  changeButtonClassName,
  equipButtonClassName,
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { rank, progress, progressMax, leagueIcon } =
    userProfile?.versus?.rating || {};
  const isOnboardingCompleted =
    userProfile?.versus?.isOnboardingCompleted ?? false;
  const { playPressed } = useServiceState(versusService, ['playPressed']);

  const navigate = useNavigate();

  const handlePlay = () => {
    if (isTournament) {
      return;
    }

    if (!isOnboardingCompleted) {
      onboardingService.startOnboarding();
    } else {
      versusService.connectToServer(() => {
        versusService.initGame();
      });
    }
  };

  const handleNext = () => {
    // TODO notify server that user char is ready and navigate to phase
    navigate('/tournament-phase');
  };

  const handleCtaClick = () => {
    if (isTournament) {
      handleNext();
    } else {
      handlePlay();
    }
  };

  const handleNavigateSkills = () => {
    versusAudioService.playSoundEffect('buttonClick');

    if (isTournament) {
      navigate('/skills/tournament');
    } else {
      navigate('/skills/default');
    }
  };

  const handleNavigateEquip = () => {
    versusAudioService.playSoundEffect('buttonClick');

    if (isTournament) {
      navigate('/equip/tournament');
    } else {
      navigate('/equip/default');
    }
  };

  const handleNavigateChange = () => {
    versusAudioService.playSoundEffect('buttonClick');

    if (isTournament) {
      navigate('/select-worm/tournament');
    } else {
      navigate('/select-worm/default');
    }
  };

  const handleNavigateLeagueProgression = () => {
    versusAudioService.playSoundEffect('buttonClick');

    if (isTournament) {
      navigate('/league-progression?navBack=/select-worm/tournament');
    } else {
      navigate('/league-progression?navBack=/play');
    }
  };

  return (
    <div className={cn(s.root, className)}>
      <NavItem
        className={cn(s.changeButton, changeButtonClassName)}
        label={'Change'}
        labelClassName={s.changeLabel}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.changeImage}
              src={iconChange}
              width={64}
              height={64}
              alt="change"
            />
          </div>
        }
        onClick={handleNavigateChange}
      />
      <NavItem
        className={cn(s.equipButton, equipButtonClassName)}
        label={'Equip'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.equipImg}
              src={iconEquip}
              width={40}
              height={40}
              alt="equip"
            />
          </div>
        }
        onClick={handleNavigateEquip}
      />
      <div className={s.background} />
      <NavItem
        label={'Skills'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.img}
              src={iconReaction}
              width={40}
              height={40}
              alt="skills"
            />
          </div>
        }
        className={s.skills}
        onClick={handleNavigateSkills}
      />
      <Button
        onClick={handleCtaClick}
        disabled={playPressed}
        className={s.button}
        size="large"
      >
        {isTournament ? 'Next' : 'Play'}
      </Button>
      <RankWithProgress
        variant="menu"
        level={rank}
        currentProgress={progress}
        max={progressMax}
        rankImage={leagueIcon}
        showProgressValues
        onClick={handleNavigateLeagueProgression}
        className={s.rank}
      />
    </div>
  );
};
