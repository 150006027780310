import cn from 'classnames';
import { useState } from 'react';

import { Button } from '@app/ui-kit';
import { versusShopService, versusTournamentService } from '@app/services';
import { TournamentEntryFee } from '../TournamentEntryFee';
import { BuyTicketBottomSheet } from '../BuyTicketBottomSheet';
import { VERSUS_PHASE_ENTRY } from '@app/constants/versus';

import s from './TournamentStayInPhase.module.scss';
import { useServiceState } from '@app/common/state';

interface TournamentStayInPhaseProps {
  className?: string;
}

export const TournamentStayInPhase: React.FC<TournamentStayInPhaseProps> = ({
  className,
}) => {
  const [isBuyOpen, setIsBuyOpen] = useState(false);
  const { purchaseInProgress } = useServiceState(versusShopService, [
    'purchaseInProgress',
  ]);
  const entryTicketInfo = versusTournamentService.getStageEntryTicketInfo();
  const userTicketInfo = versusTournamentService.getUserEntryTicketInfo();
  const currentStage = versusTournamentService.getCurrentStageInfo();
  const entryTicketPrice = versusTournamentService.getStageEntryTicketPrice();
  const priceTon = entryTicketPrice?.priceTon;
  const priceStars = entryTicketPrice?.priceStars;

  if (!entryTicketInfo) {
    return null;
  }

  const hasRequiredTicket =
    (userTicketInfo?.amount || 0) >= entryTicketInfo?.amount;

  const buttonTitle = () => {
    if (purchaseInProgress) {
      return 'Processing purchase..';
    }

    if (hasRequiredTicket) {
      return `Enter ${currentStage?.title} again`;
    }

    return 'Purchase and stay in phase';
  };

  const handleButtonClick = async () => {
    if (!hasRequiredTicket) {
      setIsBuyOpen(true);
    } else {
      await versusTournamentService.resumeStage();
    }
  };

  return (
    <div className={cn(s.root, className)}>
      <TournamentEntryFee
        key={entryTicketInfo.type}
        type={entryTicketInfo.type}
        variant={hasRequiredTicket ? 'default' : 'showPrice'}
        priceTon={priceTon}
        priceStars={priceStars}
      />
      <Button
        onClick={handleButtonClick}
        className={s.button}
        disabled={purchaseInProgress}
      >
        {buttonTitle()}
      </Button>
      <BuyTicketBottomSheet
        isOpen={isBuyOpen}
        onClose={setIsBuyOpen}
        itemCode={entryTicketInfo.code}
        itemName={VERSUS_PHASE_ENTRY[entryTicketInfo.type].title}
        priceTon={priceTon}
        priceStars={priceStars}
      />
    </div>
  );
};
