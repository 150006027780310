import cn from 'classnames';

import {
  ManagerEarningsCard,
  ManagerSlappersCard,
  ManagerYourTopFighters,
  ReferralLinkWidget,
} from '@components';
import { useServiceState } from '@app/common/state';
import { userService } from '@app/services';

import s from './ManagerOfficeSection.module.scss';

interface ManagerOfficeSectionProps {
  className?: string;
}

export const ManagerOfficeSection: React.FC<ManagerOfficeSectionProps> = ({
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);

  return (
    <div className={cn(s.root, className)}>
      <ReferralLinkWidget userId={userProfile?.id} />
      <ManagerEarningsCard />
      <ManagerSlappersCard />
      <ManagerYourTopFighters />
    </div>
  );
};
