import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, m } from 'framer-motion';

import { DefaultShop, MedalShop, Page, VersusHeader } from '@components';
import { BackButton } from '@components';
import { Tabs } from '@app/ui-kit';
import { useHideBackButton } from '@app/hooks';

import s from './ShopPage.module.scss';

export enum ShopPageSections {
  ticketsDefault = 'tickets-default',
  cucumbersDefault = 'cucumbers-default',
  wormsDefault = 'worms-default',
  accessoriesDefault = 'accessories-default',
  wormsMedals = 'worms-medals',
}

export enum ShopPageTabs {
  default = 'default',
  medals = 'medals',
}

export const ShopPage = () => {
  useHideBackButton();

  const [searchParams, setSearchParams] = useSearchParams();
  const scrolling = useRef(false);
  const tab = searchParams.get('tab') as ShopPageTabs;
  const [currentTab, setCurrentTab] = useState<ShopPageTabs | null>(
    tab ?? ShopPageTabs.default,
  );
  const section = searchParams.get('section');

  const [direction, setDirection] = useState<number>(0);

  const handleTabChange = (tab: ShopPageTabs) => {
    document.body.scrollIntoView({ behavior: 'smooth' });
    setDirection(tab === ShopPageTabs.default ? -1 : 1);
    setCurrentTab(tab);
    setSearchParams((prev) => {
      prev.set('tab', tab);
      prev.delete('section');

      return prev;
    });
  };

  const navigate = useNavigate();

  const scrollTo = useCallback((sectionId: string, behavior = 'smooth') => {
    const section = document.getElementById(sectionId);

    console.log(section);

    if (section) {
      scrolling.current = true;
      section.scrollIntoView({
        behavior: behavior as ScrollBehavior,
        block: 'start',
        inline: 'nearest',
      });

      setTimeout(() => {
        scrolling.current = false;
      }, 800);
    }
  }, []);

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab);
    }

    if (section) {
      scrollTo(section);
    }
  }, [tab, section, currentTab, scrollTo]);

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <VersusHeader
        variant={tab === ShopPageTabs.medals ? 'shop-medals' : 'default'}
        className={s.header}
      />
      <div className={s.tabs}>
        <Tabs
          initialActiveTab={tab || ShopPageTabs.default}
          currentActiveTab={currentTab}
          variant="secondary"
          tabs={[
            { key: ShopPageTabs.default, label: 'Shop' },
            { key: ShopPageTabs.medals, label: 'Medal Shop' },
          ]}
          onTabChange={handleTabChange}
        />
      </div>
      <AnimatePresence custom={direction} mode="popLayout" initial={false}>
        {currentTab === ShopPageTabs.default && (
          <m.div
            key="shop"
            className={s.content}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={transition}
          >
            <DefaultShop />
          </m.div>
        )}
        {currentTab === ShopPageTabs.medals && (
          <m.div
            key="medalShop"
            className={s.content}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={transition}
          >
            <MedalShop />
          </m.div>
        )}
      </AnimatePresence>
    </Page>
  );
};

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? '100%' : '-100%',
  }),
  center: {
    x: 0,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? '100%' : '-100%',
  }),
};

const transition = {
  type: 'tween',
  duration: 0.6,
};
