import cn from 'classnames';

import { ImageLoader, VersusPriceTag } from '@components';
import { VersusPriceType, VersusShopItem } from '@app/types';
import { numberWithCommas } from '@app/utils';
import { versusCharacterService } from '@app/services';

import s from './ShopItemCard.module.scss';

interface ShopItemCardCardProps {
  data: VersusShopItem;
  className?: string;
  onClick: () => void;
}

export const ShopItemCard: React.FC<ShopItemCardCardProps> = ({
  data,
  className,
  onClick,
}) => {
  const { amount, price, priceFull, priceTon, priceType, priceStars, code } =
    data;

  const image = versusCharacterService.generateItemImage(code);

  const renderAmount = () => {
    if (code.includes('tickets')) {
      return `${amount} Ticket${amount === 1 ? '' : 's'}`;
    }

    return numberWithCommas(amount);
  };

  return (
    <button className={cn(s.root, className)} onClick={onClick}>
      <ImageLoader src={image} alt={data.code} className={s.image} />
      <div className={s.amount}>{renderAmount()}</div>
      <VersusPriceTag
        price={price || 0}
        priceTon={priceTon}
        priceType={priceType}
        priceFull={priceFull}
      />

      {priceStars && (
        <VersusPriceTag
          price={priceStars}
          priceType={VersusPriceType.Stars}
          className={s.starsPrice}
        />
      )}
    </button>
  );
};
