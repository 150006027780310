import { useEffect, useState } from 'react';
import { useServiceState } from '@app/common/state';
import {
  analyticsService,
  configService,
  userService,
  versusService,
  versusShopService,
  versusTournamentService,
  web3Service,
} from '@app/services';

export const useAuth = (refUserId?: number) => {
  const { isAuth } = useServiceState(userService);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);

      try {
        await userService.fetchProfile();
        await configService.getServerConfig();

        await Promise.all([
          userService.authStreak(),
          versusShopService.getShopItems(),
          userService.updateServerTgUserData(),
          versusTournamentService.getInitialTournamentData(),
          web3Service.getTonPrice(),
        ]);

        await versusService.checkForActiveMatch();

        analyticsService.trackEvent({
          name: 'slap_start_game',
          variables: {
            game_name: 'slap',
          },
        });

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (!isAuth) {
      userService.login(refUserId).then(init);

      return;
    }

    init();
  }, [isAuth, refUserId]);

  return { isLoading, isAuth };
};
