import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Page,
  BackButton,
  WhyParticipateItem,
  JamIsSafe,
  TomatoHowWorkItem,
} from '@components';
import { openLink } from '@app/utils';
import { configService } from '@app/services';

import heroImg from '@media/completed-day-hero.png';

import wormImg from '@media/worm-with-helmet.png';
import tHreeTomatosImg from '@media/three-tomatos.png';

import s from './TomatoHowItWorkPage.module.scss';

export const TomatoHowItWorkPage: React.FC = () => {
  const navigate = useNavigate();
  const handleOpenWormfare = () => {
    openLink(configService.wormfareAppLink);
  };

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.hero}>
        <img src={heroImg} className={s.heroImg} alt="hero img" />

        <div className={s.contentWrap}>
          <h4 className={s.title}>We’re Launching a New Chapter of SLAP</h4>
          <p className={s.desc}>
            You’ve been slapping the hell out of that worm’s ass. Now it’s time
            for the new chapter! Still be slapping though, cuz we know you like
            it!
          </p>
        </div>
      </div>
      <div className={s.container}>
        <JamIsSafe onClick={handleOpenWormfare} />
        <TomatoHowWorkItem
          title="WTF Are These Tomatoes?"
          desc="Well, we know you love slapping, don’t you? How about slapping the worm’s face? Smack it so it’s red as a tomato!"
          img={wormImg}
          imgPosition="bottom"
          imgHeight="200"
        />
        <TomatoHowWorkItem
          title="What Do I Do Now?"
          desc="For now, come back every day and collect tomatoes."
          img={tHreeTomatosImg}
          imgHeight="129"
        />
        <WhyParticipateItem
          title="In This Chapter, We’ll Make It Different"
          desc1="You wanted to cash out, so we’ll make tomatoes convert into cash."
          desc2="We prepared an updated economy and a bunch of new fun for SLAP 2. Stay tuned and don’t forget to come back every day for your portion of tomatoes!"
        />
      </div>
    </Page>
  );
};
