import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';
import { CacheService } from '../CacheService';
import { UserService } from '../UserService';
import { AnalyticsService } from '../AnalyticsService';
import { openLink, updateArrayItem } from '@app/utils';
import type { Quest, QuestRaw, ApiResponse, QuestTaks } from '@app/types';

@Injectable()
@MakeObservable
export class QuestService {
  @observable
  public quests: Quest[] = [];

  @observable
  public isQuestsLoading = false;

  @observable
  public isCheckCompletionLoading = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly cacheService: CacheService,
    private readonly userService: UserService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  private isQuestInProgress(questId: string, taskId?: number): boolean {
    const quest = this.quests.find(({ id }) => id === questId);

    if (
      quest?.completeTimes &&
      quest?.completeTimes === quest?.maxCompleteTimes
    ) {
      return false;
    }

    if (taskId !== undefined && taskId !== null) {
      return !!this.cacheService.get(`inprogress_${questId}_${taskId}`);
    }

    return !!this.cacheService.get(`inprogress_${questId}`);
  }

  private updateQuestCompletion(questId: string, taskId?: number) {
    const quest = this.quests.find(({ id }) => id === questId);

    if (!quest) {
      return;
    }

    if (quest.type === 'simple') {
      this.cacheService.remove(`inprogress_${questId}`);

      this.quests = updateArrayItem(this.quests, questId, {
        isCompleted: true,
        inProgress: false,
      });

      this.analyticsService.trackEvent({
        name: 'quest_completed',
        variables: {
          quest_id: questId,
        },
      });

      return;
    } else {
      const tasks = quest.tasks || [];
      const lastTask = tasks[tasks.length - 1];

      this.cacheService.remove(`inprogress_${questId}_${taskId}`);

      this.quests = updateArrayItem(this.quests, questId, {
        tasks: updateArrayItem(tasks, taskId, {
          isCompleted: true,
          inProgress: false,
        }),
        isCompleted: lastTask.id === taskId,
        inProgress: lastTask.id !== taskId,
      });

      if (lastTask.id === taskId) {
        this.analyticsService.trackEvent({
          name: 'quest_completed',
          variables: {
            quest_id: questId,
          },
        });
      }

      this.cacheService.remove(`inprogress_${questId}_${taskId}`);
    }
  }

  private setInProgress(quest: Quest, taskId?: number): void {
    if (taskId !== undefined && taskId !== null) {
      this.cacheService.set(`inprogress_${quest.id}_${taskId}`, true);
    } else {
      this.cacheService.set(`inprogress_${quest.id}`, true);
    }

    this.quests = updateArrayItem(this.quests, quest.id, {
      inProgress: true,
      tasks: quest.tasks
        ? updateArrayItem(quest.tasks, taskId, {
            inProgress: true,
          })
        : undefined,
    });
  }

  private _isQuestOrTaskNeedToCheck(questId: string): boolean {
    const quest = this.quests.find(({ id }) => id === questId);

    if (!quest) {
      return false;
    }

    if (quest.isCompleted) {
      return false;
    }

    if (quest.type === 'complex') {
      const inProgressTask = quest.tasks?.find(({ inProgress }) => inProgress);

      if (!inProgressTask) {
        return false;
      }

      return !inProgressTask.isCompleted;
    }

    return !quest.isCompleted && quest.inProgress;
  }

  public isQuestOrTaskNeedToCheck(questId: string): boolean {
    return this._isQuestOrTaskNeedToCheck(questId);
  }

  public getInProgressTask(questId: string): QuestTaks | null {
    const quest = this.quests.find(({ id }) => id === questId);

    if (!quest) {
      return null;
    }

    if (quest.type === 'complex') {
      const inProgressTask = quest.tasks?.find(({ inProgress }) => inProgress);

      return inProgressTask || null;
    }

    return null;
  }

  public startQuestOrTask(questId: string, taskId?: number): boolean {
    const quest = this.quests.find(({ id }) => id === questId);

    if (!quest) {
      throw new Error(`Quest with id: ${questId} is not defined!`);
    }

    if (quest.isCompleted) {
      return false;
    }

    this.setInProgress(quest, taskId);

    this.analyticsService.trackEvent({
      name: 'quest_started',
      variables: {
        quest_id: questId,
      },
    });

    return true;
  }

  protected checkQuestDependencies(quests: QuestRaw[]): void {
    const questsByName = quests.reduce(
      (result, quest) => {
        result[quest.id] = quest;

        return result;
      },
      {} as Record<string, QuestRaw>,
    );

    for (const quest of quests) {
      if (quest.dependsOn?.length) {
        for (const depQuestId of quest.dependsOn) {
          const depQuest = questsByName[depQuestId];

          if (!depQuest.isCompleted) {
            quest.isLocked = true;
          }
        }
      }
    }
  }

  protected filterQuestsInGroups(quests: QuestRaw[]): QuestRaw[] {
    const usedGroups: string[] = [];

    return quests.filter((row) => {
      if (!row.group) {
        return true;
      } else {
        if (
          (!row.isCompleted && !usedGroups.includes(row.group)) ||
          (row.isCompleted && !row.isRewardClaimed)
        ) {
          usedGroups.push(row.group);

          return true;
        } else {
          return false;
        }
      }
    });
  }

  public async fetchQuests(): Promise<void> {
    this.isQuestsLoading = true;

    let questsRaw = await this.apiService.get<QuestRaw[]>('/quest');

    this.checkQuestDependencies(questsRaw);
    questsRaw = this.filterQuestsInGroups(questsRaw);

    this.quests = questsRaw.map((quest) => ({
      ...quest,
      isCompleted:
        quest.isCompleted === undefined
          ? (quest.isCompleted = false)
          : quest.isCompleted,
      inProgress: this.isQuestInProgress(quest.id),
      tasks: quest.tasks?.map((task) => ({
        ...task,
        inProgress: this.isQuestInProgress(quest.id, task.id),
      })),
    }));

    this.isQuestsLoading = false;
  }

  public async checkCompletion(
    questId: string,
    taskId?: number,
    payload?: Record<string, unknown>,
  ): Promise<void> {
    if (!this._isQuestOrTaskNeedToCheck(questId)) {
      return;
    }

    const params: {
      questId: string;
      taskId?: number;
      payload?: Record<string, unknown>;
    } = {
      questId,
      taskId,
      payload,
    };

    this.isCheckCompletionLoading = true;

    const response = await this.apiService.post<ApiResponse>(
      '/quest/check-completion',
      params,
    );

    if (response.success) {
      this.updateQuestCompletion(questId, taskId);
    }

    this.isCheckCompletionLoading = false;
  }

  public async claimReward(questId: string): Promise<ApiResponse> {
    const params = {
      questId,
    };

    const response = await this.apiService.post<ApiResponse>(
      '/quest/claim-reward',
      params,
    );

    if (response.success) {
      this.quests = updateArrayItem(this.quests, questId, {
        isRewardClaimed: true,
      });
      this.cacheService.remove(`inprogress_${questId}`);
      await this.userService.fetchProfile();
    }

    return response;
  }
}
