import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';

import {
  VersusPlayer,
  VersusWssVictoryReward,
  VersusWssVictoryRewardType,
} from '@app/types';
import { PlayerInfo } from './PlayerInfo';
import tomatoIcon from '@media/tomato_coin.png';
import medalIcon from '@media/medal.png';
import cryptoIcon from '@media/ton-icon.png';
import { kNumberFormatter } from '@app/utils';

import s from './VersusPlayerBars.module.scss';

interface VersusPlayerBarsProps {
  player: VersusPlayer | null;
  opponent: VersusPlayer | null;
  victoryRewards?: VersusWssVictoryReward[] | undefined | null;
  zIndex?: number;
  className?: string;
  transitionDelayMs?: number;
}

export const VersusPlayerBars: React.FC<VersusPlayerBarsProps> = ({
  player,
  opponent,
  victoryRewards,
  zIndex,
  transitionDelayMs,
  className,
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isOpponentAvailable = opponent != null && opponent !== undefined;

  const rewardToShow = victoryRewards?.find((reward) => reward.isPrimary);

  useEffect(() => {
    setShouldAnimate(!opponent?.skills);
  }, [opponent?.skills]);

  return (
    <div className={cn(s.root, className)} style={{ zIndex }}>
      {player && (
        <PlayerInfo
          player={player}
          transitionDelayMs={transitionDelayMs}
          mirror={false}
          className={s.playerInfo}
        />
      )}
      <AnimatePresence initial>
        {rewardToShow && (
          <motion.div
            initial={{ y: -200 }}
            animate={{ y: 0 }}
            className={s.col}
            transition={{ type: 'spring', duration: 0.5, delay: 1 }}
          >
            <img
              className={s.balanceIcon}
              src={
                scoreTypeImages[
                  rewardToShow.type as keyof typeof scoreTypeImages
                ]
              }
              alt={rewardToShow.type}
            />
            <div className={s.balanceValue}>
              {kNumberFormatter(rewardToShow.amount)}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {isOpponentAvailable && shouldAnimate ? (
        <motion.div
          className={s.playerInfo}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
        >
          <PlayerInfo
            player={opponent}
            transitionDelayMs={transitionDelayMs}
            mirror
          />
        </motion.div>
      ) : (
        isOpponentAvailable && (
          <PlayerInfo
            player={opponent}
            transitionDelayMs={transitionDelayMs}
            mirror
            className={s.playerInfo}
          />
        )
      )}
    </div>
  );
};

const scoreTypeImages: Record<
  | VersusWssVictoryRewardType.Score
  | VersusWssVictoryRewardType.Medals
  | VersusWssVictoryRewardType.Ton,
  string
> = {
  [VersusWssVictoryRewardType.Score]: tomatoIcon,
  [VersusWssVictoryRewardType.Medals]: medalIcon,
  [VersusWssVictoryRewardType.Ton]: cryptoIcon,
};
