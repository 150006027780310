import cn from 'classnames';

import { ManagerTransactionsCard } from '@components';

import s from './ManagerHistorySection.module.scss';

interface ManagerHistorySectionProps {
  className?: string;
}

export const ManagerHistorySection: React.FC<ManagerHistorySectionProps> = ({
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <ManagerTransactionsCard />
    </div>
  );
};
