import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import {
  Page,
  VersusPlayerBars,
  WormBattle,
  VersusBattleWheel,
  OnboardingAlert,
  FightHpAnimation,
  VersusConnectionLost,
  RetreatConfirmBottomSheet,
} from '@components';

import {
  configService,
  onboardingService,
  versusAudioService,
  versusService,
  versusTournamentService,
} from '@app/services';
import { useServiceState } from '@app/common/state';
import underwearImg from '@media/versus/underwear.png';
import devResetImg from '@media/last-day-mark.png';
import { NavItem } from '@app/components/NavBars';
import { useHideBackButton } from '@app/hooks';

import s from './VersusBattlePage.module.scss';

export const VersusBattlePage: React.FC = () => {
  useHideBackButton();

  const navigate = useNavigate();
  const {
    roundState,
    player,
    opponent,
    victoryRewards,
    shakeBackground,
    isTournamentMode,
    battleBackground,
  } = useServiceState(versusService, [
    'roundState',
    'player',
    'opponent',
    'victoryRewards',
    'shakeBackground',
    'isTournamentMode',
    'battleBackground',
  ]);

  const { onboardingMessage, onboardingState } = useServiceState(
    onboardingService,
    ['onboardingMessage', 'onboardingState'],
  );

  const [showRetreatConfirm, setShowRetreatConfirm] = useState(false);

  console.log(
    '\x1b[32m%s\x1b[0m',
    `Round state: ${roundState}, Onboarding state: ${onboardingState}`,
  );

  useEffect(() => {
    if (player == null || opponent == null) {
      navigate('/play');
    }
  }, [navigate, opponent, player]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        versusService.setRoundState('idle');
      } else {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const devReset = async () => {
    await onboardingService.devResetVersusData();
    versusService.disconnect();
    navigate('/play');
  };

  const handleRetreat = () => {
    versusAudioService.playSoundEffect('buttonClick');
    setShowRetreatConfirm(true);
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <div
        className={cn(s.background, {
          [s.shake]: shakeBackground,
        })}
        style={{
          backgroundImage: `url(${battleBackground})`,
        }}
        onAnimationEnd={() => (versusService.shakeBackground = false)}
      />
      <VersusPlayerBars
        player={player}
        opponent={opponent}
        className={s.playersBar}
        victoryRewards={victoryRewards}
        zIndex={onboardingMessage?.zIndexPlayerBars}
        transitionDelayMs={versusService.hpAnimationDelay / 1.3}
      />

      {!isTournamentMode && (
        <NavItem
          className={s.retreatButton}
          label="Retreat"
          icon={<img src={underwearImg} width={54} alt="icon" />}
          onClick={handleRetreat}
          iconBg={'#FF5C4F'}
          iconBgSize={'48px'}
        />
      )}
      {configService.env !== 'production' && (
        <NavItem
          className={s.resetButton}
          label="DEV:RESET"
          icon={<img src={devResetImg} width={54} alt="icon" />}
          onClick={devReset}
          iconBg={'#000'}
          iconBgSize={'48px'}
        />
      )}
      <WormBattle className={s.worm} zIndex={onboardingMessage?.zIndexWorm} />
      <VersusBattleWheel
        className={s.wheel}
        zIndex={onboardingMessage?.zIndexWheel}
      />
      <FightHpAnimation className={s.hpAnimation} />
      <RetreatConfirmBottomSheet
        isOpen={showRetreatConfirm}
        onClose={setShowRetreatConfirm}
      />
      <OnboardingAlert
        className={s.onboarding}
        onboardingMessage={onboardingMessage}
        onClick={() => onboardingService.nextOnboardingStep()}
      />
      <VersusConnectionLost />
    </Page>
  );
};
