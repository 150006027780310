import React, { useEffect, useRef, useState, RefObject } from 'react';
import { clsx } from 'clsx';
import { vibrate } from '@app/utils';
import {
  onboardingService,
  versusAudioService,
  versusService,
} from '@app/services';

import { useServiceState } from '@app/common/state';
import { EyeBlinkAnimation } from '../EyeBlinkAnimation';
import { HitForce } from '@app/types';
import { useSpineAnimation } from '@app/hooks';

import s from './WormBattle.module.scss';

interface WormBattleProps {
  className?: string;
  zIndex?: number;
}

export const WormBattle: React.FC<WormBattleProps> = ({
  zIndex,
  className,
}) => {
  const {
    opponent,
    roundState,
    isDetectForce,
    fightState,
    playerHitForce,
    opponentHitForce,
  } = useServiceState(versusService, [
    'opponent',
    'roundState',
    'isDetectForce',
    'fightState',
    'playerHitForce',
    'opponentHitForce',
  ]);

  const accessoryName = opponent?.character.accessories?.[0]?.code;

  const { containerRef, player, changeAnimation, dispose } = useSpineAnimation({
    charCode: opponent?.character.code,
    accessoryName,
    config: animationConfig,
    onAnimationComplete: () => {
      if (roundStateRef.current !== 'fight') {
        if (idleSequenceRef.current < idleMaxCountRef.current) {
          changeAnimation('idle', { loop: true });
          idleSequenceRef.current += 1;
        } else {
          changeAnimation('idle2', { loop: true });
          idleSequenceRef.current = 0;
          idleMaxCountRef.current = randomMaxCount();
        }
      }
    },
  });

  const [showEye, setShowEye] = useState(false);
  const idleSequenceRef = useRef(0);
  const idleMaxCountRef = useRef(randomMaxCount());
  const roundStateRef = useRef('');

  const { isOnboarding } = useServiceState(onboardingService, ['isOnboarding']);

  useEffect(() => {
    roundStateRef.current = roundState;
  }, [roundState]);

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const slapAnimationOptions = {
      force: true,
      speed: 1.5,
    };

    if (roundState !== 'fight' && idleSequenceRef.current === 0) {
      changeAnimation('idle', { loop: true });
    } else if (fightState === 'playerTurn') {
      if (playerHitForce === HitForce.splendid) {
        changeAnimation('slap-max', { force: true, speed: 1.6 });
        versusAudioService.playSoundEffect('maxSlapOutgoing');

        if (accessoryName) {
          changeAnimation(
            Math.random() < 0.5
              ? 'accessoriesComeback'
              : 'accessoriesComeback2',
            { speed: 2 },
          );
        }

        changeAnimation('idle', { loop: true });
      } else if (playerHitForce === HitForce.okay) {
        versusAudioService.playSoundEffect('midSlapOutgoing');

        changeAnimation('slap-mid', slapAnimationOptions);
        changeAnimation('idle', { loop: true });
      } else if (playerHitForce === HitForce.miss) {
        versusAudioService.playSoundEffect('missSlapOutgoing');

        changeAnimation('slap-miss', slapAnimationOptions);
        changeAnimation('idle', { loop: true });
      }
    } else if (fightState === 'opponentTurn') {
      player?.animationState?.clearTracks();

      if (opponentHitForce === HitForce.miss) {
        changeAnimation('incoming-slap-miss', slapAnimationOptions);
        versusAudioService.playSoundEffect('missSlapIncoming');

        changeAnimation('idle', { loop: true });
      } else {
        changeAnimation('incoming-slap', slapAnimationOptions);
        changeAnimation('idle', { loop: true });

        if (opponentHitForce === HitForce.okay) {
          versusAudioService.playSoundEffect('midSlapIncoming');
        }

        if (opponentHitForce === HitForce.splendid) {
          versusAudioService.playSoundEffect('maxSlapIncoming');

          setTimeout(() => setShowEye(true), 520);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundState, fightState, playerHitForce, opponentHitForce, accessoryName]);

  const handleTap = (event: React.TouchEvent<HTMLDivElement>) => {
    vibrate();

    if (roundState !== 'detectForce' || isDetectForce) {
      return;
    }

    if (isOnboarding) {
      onboardingService.registerTap();
    } else {
      versusService.emitHit();
    }
  };

  return (
    <>
      <div className={clsx(s.root, className)} style={{ zIndex }}>
        <div className={s.tapArea} onTouchStart={handleTap}></div>
        <div
          className={s.worm}
          ref={containerRef as RefObject<HTMLDivElement>}
        />
      </div>
      {showEye && (
        <EyeBlinkAnimation onAnimationEnd={() => setShowEye(false)} />
      )}
    </>
  );
};

const randomMaxCount = () => 2 + Math.floor(Math.random() * 3);

const animationConfig = {
  animation: 'idle',
  animations: [
    'idle',
    'idle2',
    'slap-max',
    'slap-mid',
    'slap-miss',
    'accessoriesComeback',
    'accessoriesComeback2',
    'incoming-slap',
    'incoming-slap-miss',
  ],
  alpha: true,
  preserveDrawingBuffer: false,
  viewport: { x: -150, y: 200, width: 450, height: 1050 },
};
