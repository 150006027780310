import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import {
  BackButton,
  BottomButton,
  CoinsIndicator,
  Page,
  VersusBattleResultHero,
} from '@app/components';

import { useServiceState } from '@app/common/state';
import { versusService } from '@app/services';
import { VersusMatchOutcome, VersusWssVictoryRewardType } from '@app/types';

import s from './TournamentBattleResultPage.module.scss';

export const TournamentBattleResultPage: React.FC = () => {
  const { matchOutcome, victoryRewards } = useServiceState(versusService, [
    'matchOutcome',
    'victoryRewards',
  ]);

  const medals =
    victoryRewards?.find((el) => el.type === VersusWssVictoryRewardType.Medals)
      ?.amount ?? 0;

  const navigate = useNavigate();

  useEffect(() => {
    if (matchOutcome == null) {
      navigate('/tournament?tab=phase');
    }
  }, [matchOutcome, navigate]);

  const handleNext = () => {
    navigate('/tournament-phase');
  };

  const getMedalsAmount = () => {
    if (matchOutcome === VersusMatchOutcome.Draw) {
      return Math.round(medals / 2);
    }

    if (matchOutcome === VersusMatchOutcome.Victory) {
      return medals;
    }

    return 0;
  };

  const medalsAmount = getMedalsAmount();

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={() => navigate('/tournament-phase')} />
      {matchOutcome != null && (
        <VersusBattleResultHero battleResult={matchOutcome} />
      )}
      {medalsAmount > 0 && (
        <div className={s.reward}>
          <CoinsIndicator
            variant="medals"
            value={medalsAmount}
            animateOnChange
            animateDuration={1}
            fontSize={48}
            className={s.medals}
          />
        </div>
      )}
      <BottomButton onClick={handleNext} />
    </Page>
  );
};
