import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import {
  HitForce,
  type OnboardingLeagueProgress,
  VersusLeagueRewards,
  VersusMatchOutcome,
  VersusOnboardingRewards,
  VersusWssSkill,
  VersusWssSkillData,
  type OnboardingData,
  type OnboardingState,
  VersusWssVictoryRewardType,
} from '@app/types';
import { onboardingData } from '@app/constants/onboarding';
import { ApiService } from '../ApiService';
import { VersusService } from '../VersusService';
import scale1 from '@media/versus/onboarding-scale-1.png';
import scale2 from '@media/versus/onboarding-scale-2.png';
import { globalNavigate } from '@app/components';
import { UserService } from '../UserService';
import { notifyError, notifySuccess } from '@app/ui-kit/ToastNotifications';
import { versusLeagueService } from '..';

@Injectable()
@MakeObservable
export class OnboardingService {
  @observable
  public onboardingState: OnboardingState = 'idle';

  @observable
  public onboardingMessage: OnboardingData | undefined;

  @observable
  public isOnboarding = false;

  public onboardingLeagueData: VersusLeagueRewards = {
    rank: 0,
    league: 'RainDrop',
    leagueName: 'Rain Drop',
    leagueIcon:
      'https://dev-api-clicker-main-bucket.s3.eu-central-1.amazonaws.com/versus/league-icons/RainDrop.png',
    isClaimed: false,
    canClaim: false,
    rewards: [],
  };

  @observable
  public onboardingLeagueProgress: OnboardingLeagueProgress = {
    rank: 0,
    leagueName: this.onboardingLeagueData.leagueName,
    leagueIcon: this.onboardingLeagueData.leagueIcon,
  };

  public readonly rankIncreaseTime = 4000;

  private readonly missWaitTime = 2000;

  private onboardingSteps: OnboardingState[] = [
    'hereHp',
    'opponentHp',
    'reduceHp',
    'startRound',
    'slapPower',
    'powerLevels',
    'tapToGreen',
    'showHitForce',
    'waitOpponent',
    'fight',
    'roundWin',
    'changePattern',
    'round2hit',
    'round2waiting',
    'round2fight',
    'getRewards',
    'victory',
    'winToLvlUp',
    'earnTomatoes',
    'cucumbersLevelUp',
    'increaseSlaps',
    'league',
    'collectLeagueReward',
  ];

  private onboardingHitRanges = [
    { success: [23, 55], okay: [-25, 22] },
    { success: [-40, -23], okay: [-14, 18] },
  ];

  private onboardingHitScales = [scale1, scale2];

  private actionTimeout: NodeJS.Timeout | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly versusService: VersusService,
    private readonly userService: UserService,
  ) {}

  startOnboarding() {
    this.isOnboarding = true;

    const skills: VersusWssSkillData[] = [
      { id: VersusWssSkill.Damage, level: 1 },
      { id: VersusWssSkill.Resilience, level: 1 },
      { id: VersusWssSkill.Reaction, level: 1 },
    ];

    const rating = {
      rank: 0,
      progress: 0,
      progressMax: 7,
      league: 'Microbe',
      leagueName: 'Microbe',
      leagueIcon:
        'https://dev-api-clicker-main-bucket.s3.eu-central-1.amazonaws.com/versus/league-icons/Microbe.png',
      leagueRank: 0,
    };

    const character = {
      code: 'jimmy',
      accessories: [],
    };

    this.versusService.player = {
      hp: { current: 1000, total: 1000 },
      name: this.userService.userProfile?.username || 'null',
      skills,
      rating,
      character,
    };

    this.versusService.opponent = {
      hp: { current: 1000, total: 1000 },
      name: 'dummy',
      skills,
      rating,
      character,
    };

    this.versusService.victoryRewards = [
      { type: VersusWssVictoryRewardType.Score, amount: 1000, isPrimary: true },
    ];

    this.updateOnboardingState(this.onboardingSteps[0]);
    this.versusService.scaleDiagramImage = this.onboardingHitScales[0];

    globalNavigate('/battle');
  }

  async registerTap() {
    this.versusService.isDetectForce = true;
    this.versusService.savedHitTime = this.versusService.detectForceTime;
    await this.setServiceTimeout(
      () => this.nextOnboardingStep(),
      this.missWaitTime,
    );
    this.generateHitForce();
    this.versusService.resetHitForce();
    this.versusService.roundState = 'showHitForce';
  }

  nextOnboardingStep() {
    const currentIndex = this.onboardingSteps.indexOf(this.onboardingState);

    if (currentIndex < this.onboardingSteps.length - 1) {
      const nextStep = this.onboardingSteps[currentIndex + 1];

      if (
        (nextStep === 'showHitForce' || nextStep === 'round2waiting') &&
        !this.isHitInSuccessRange()
      ) {
        this.handleMissedHit();

        return;
      }

      this.updateOnboardingState(nextStep);
      this.handleOnboardingStepActions(nextStep);
    }
  }

  private async handleOnboardingStepActions(step: OnboardingState) {
    switch (step) {
      case 'startRound':
        this.versusService.roundState = 'startRound';
        this.versusService.isDetectForce = false;
        this.versusService.currentRound = 1;
        this.versusService.isTimerActive = true;
        this.versusService.indicatorPeriod = 1500;
        this.versusService.savedHitTime = 0;

        break;
      case 'slapPower':
        this.versusService.roundState = 'preDetectForce';
        break;
      case 'tapToGreen': {
        this.versusService.roundState = 'detectForce';
        this.versusService.startTimer();

        const interval = setInterval(() => {
          if (this.versusService.timerProgress <= 5) {
            this.versusService.pauseTimer();
            clearInterval(interval);
          }
        }, 1000);

        break;
      }
      case 'showHitForce':
        this.versusService.roundState = 'showHitForce';
        break;
      case 'waitOpponent':
        this.versusService.roundState = 'waiting';
        this.versusService.setOpponentHitForce(HitForce.splendid);
        this.versusService.applyDamage(500, 200);
        this.versusService.setNewHp(800, 500);
        break;
      case 'fight':
        this.versusService.roundState = 'fight';
        this.versusService.hideTimer();
        this.versusService.resetTimer();
        this.versusService.startFightPhase(true);

        this.setServiceTimeout(() => {
          this.versusService.resetFightState();
          this.versusService.roundState = 'startRound';
          this.versusService.scaleDiagramImage = this.onboardingHitScales[1];
          this.versusService.isDetectForce = false;
          this.versusService.currentRound += 1;
          this.versusService.isTimerActive = true;
          this.nextOnboardingStep();
        }, this.versusService.turnChangeTime * 2);
        break;
      case 'roundWin':
        break;
      case 'changePattern':
        this.versusService.savedHitTime = 0;
        this.versusService.roundState = 'preDetectForce';
        break;
      case 'round2hit': {
        this.versusService.roundState = 'detectForce';
        this.versusService.startTimer();

        const interval = setInterval(() => {
          if (this.versusService.timerProgress <= 5) {
            this.versusService.pauseTimer();
            clearInterval(interval);
          }
        }, 1000);

        break;
      }
      case 'round2waiting':
        this.versusService.roundState = 'waiting';
        this.versusService.setOpponentHitForce(HitForce.miss);
        this.versusService.applyDamage(500, 0);
        this.versusService.setNewHp(800, 0);

        this.setServiceTimeout(() => {
          this.nextOnboardingStep();
        }, 2000);
        break;
      case 'round2fight':
        this.versusService.roundState = 'fight';
        this.versusService.hideTimer();
        this.versusService.resetTimer();
        this.versusService.startFightPhase();

        this.setServiceTimeout(() => {
          this.versusService.resetFightState();
          this.versusService.cancelActionTimeout();

          this.versusService.roundState = 'idle';
          this.versusService.isDetectForce = false;

          this.nextOnboardingStep();
        }, this.versusService.turnChangeTime * 2);
        break;
      case 'getRewards':
        try {
          const { scoreChange, versusScoreChange, ratingChange, newRating } =
            await this.apiService.post<VersusOnboardingRewards>(
              '/versus/complete-onboarding',
            );

          this.versusService.battleResultInfo = {
            leagueLevel: 0,
            leagueProgress: 0,
            leagueImage: '',
            score: 0,
            balance: 0,
            balanceAfterBattle: 0,
            scoreChange: scoreChange,
            versusScore: 0,
            shouldNavigateToLeague: true,
            versusScoreChange: versusScoreChange,

            rewards: [
              { name: 'Complete onboarding', points: versusScoreChange },
            ],
          };

          this.nextOnboardingStep();
        } catch (e) {
          notifyError(`Error: ${e}`);
        }

        break;
      case 'victory':
        {
          this.versusService.matchOutcome = VersusMatchOutcome.Victory;

          const oldUserProfile = this.userService.userProfile;
          const oldVersusData = oldUserProfile?.versus;

          await this.userService.fetchProfile();
          await versusLeagueService.fetchLeagueRewards();

          if (this.versusService.battleResultInfo) {
            this.versusService.battleResultInfo = {
              ...this.versusService.battleResultInfo,
              leagueLevel: oldVersusData?.rating.leagueRank || 0,
              leagueProgress: oldVersusData?.rating.progress || 0,
              leagueImage: oldVersusData?.rating.leagueIcon || '',
              shouldNavigateToLeague: true,
              score: oldVersusData?.score || 0,
              balance: oldUserProfile?.score || 0,
              balanceAfterBattle: this.userService.userProfile?.score || 0,
            };
          }

          globalNavigate('/battle-result', false);
        }
        break;
      case 'winToLvlUp':
        this.versusService.updateBattleInfoLeague();
        break;

      case 'earnTomatoes':
        this.versusService.updateBattleInfoBalance();
        break;

      case 'cucumbersLevelUp':
        globalNavigate('/skills/battle-result', false);
        break;

      case 'increaseSlaps':
        break;
      case 'league':
        this.updateLeagueProgress();

        break;
      case 'collectLeagueReward':
        break;
    }
  }

  private handleMissedHit() {
    const currentState = this.onboardingState;

    this.versusService.savedHitTime = 0;

    if (currentState === 'round2hit') {
      this.updateOnboardingState('missed-2');
    } else {
      this.updateOnboardingState('missed');
    }

    this.versusService.roundState = 'preDetectForce';
    this.versusService.isDetectForce = false;
    this.versusService.resetTimer();

    this.setServiceTimeout(() => {
      this.versusService.roundState = 'detectForce';

      if (currentState === 'tapToGreen') {
        this.updateOnboardingState('tapToGreen');
      } else if (currentState === 'round2hit') {
        this.updateOnboardingState('round2hit');
      }

      this.versusService.startTimer();
    }, this.missWaitTime);
  }

  private isHitInSuccessRange(): boolean {
    const successRange = this.getCurrentHitRange().success;

    return (
      this.versusService.hitAngle >= successRange[0] &&
      this.versusService.hitAngle <= successRange[1]
    );
  }

  private generateHitForce() {
    const currentRoundRange = this.getCurrentHitRange();

    if (this.isHitInRange(currentRoundRange.okay)) {
      this.versusService.setPlayerHitForce(HitForce.okay);
    } else if (this.isHitInRange(currentRoundRange.success)) {
      this.versusService.setPlayerHitForce(HitForce.splendid);
    } else {
      this.versusService.setPlayerHitForce(HitForce.miss);
    }
  }

  private isHitInRange(range: number[]): boolean {
    return (
      this.versusService.hitAngle >= range[0] &&
      this.versusService.hitAngle <= range[1]
    );
  }

  private getCurrentHitRange() {
    return this.onboardingHitRanges[
      this.versusService.currentRound % 2 === 0 ? 1 : 0
    ];
  }

  private updateOnboardingState(state: OnboardingState) {
    this.onboardingState = state;
    this.updateOnboardingMessage();
  }

  completeOnboarding() {
    this.onboardingLeagueProgress = {
      ...this.onboardingLeagueProgress,
      rank: 0,
    };
    this.onboardingState = 'idle';
    this.onboardingMessage = undefined;
    this.versusService.resetGameState();
    this.isOnboarding = false;
  }

  private updateOnboardingMessage() {
    this.onboardingMessage = onboardingData.find(
      (msg) => msg.id === this.onboardingState,
    );
  }

  updateLeagueProgress() {
    setTimeout(() => {
      this.onboardingLeagueProgress = {
        rank: 1,
        leagueName: 'Microbe',
        leagueIcon:
          'https://dev-api-clicker-main-bucket.s3.eu-central-1.amazonaws.com/versus/league-icons/Microbe.png',
      };
    }, this.rankIncreaseTime / 2);
  }

  resetOnboarding() {
    this.onboardingState = 'idle';
    this.onboardingMessage = undefined;
    this.isOnboarding = false;
  }

  retreat() {
    if (this.actionTimeout) {
      clearTimeout(this.actionTimeout);
    }

    this.resetOnboarding();
    this.versusService.cancelActionTimeout();
    this.versusService.resetGameState();
    globalNavigate('/play', false);
  }

  private setServiceTimeout(callback: () => void, delay: number) {
    if (this.actionTimeout) {
      clearTimeout(this.actionTimeout);
    }

    this.actionTimeout = setTimeout(() => {
      this.actionTimeout = null;
      callback();
    }, delay);
  }

  async devCompleteOnboarding() {
    try {
      await this.apiService.post<VersusOnboardingRewards>(
        '/versus/complete-onboarding',
      );
      await this.userService.fetchProfile();
      notifySuccess('Onboarding completed!', { autoClose: 100 });
    } catch (e) {
      notifyError(`Error: ${e}`);
    }
  }

  async devResetVersusData() {
    try {
      await this.apiService.post('/dev/clear-versus-data');
      await this.userService.fetchProfile();
      await versusLeagueService.fetchLeagueRewards();
      notifySuccess('Versus data has been reset', { autoClose: 100 });
    } catch (e) {
      notifyError(`Error: ${e}`);
    }
  }

  async devAddRating(amount: number) {
    try {
      await this.apiService.post('/dev/versus-add-rating', { amount });
      await this.userService.fetchProfile();
      notifySuccess('Versus league rating increased!', { autoClose: 100 });
    } catch (e) {
      notifyError(`Error: ${e}`);
    }
  }
}
