import React from 'react';
import clsx from 'clsx';

import s from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  size?: 'small' | 'medium' | 'big';
}

export const Loader: React.FC<LoaderProps> = ({
  className,
  size = 'medium',
}) => {
  return (
    <div className={clsx(s.root, className, s[size])}>
      <span className={s.loader}></span>
    </div>
  );
};
