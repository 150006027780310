import cn from 'classnames';

import { VersusPriceTag } from '@components';
import { VersusPriceType } from '@app/types';
import rewardDiamondPhase from '@media/phase-diamond-reward.png';

import s from './TonPhaseReward.module.scss';

interface TonPhaseRewardProps {
  amount: number;
  size: 'small' | 'normal';
  className?: string;
}

export const TonPhaseReward: React.FC<TonPhaseRewardProps> = ({
  amount,
  size,
  className,
}) => {
  return (
    <div className={cn(s.root, s[size], className)}>
      <img src={rewardDiamondPhase} alt="ton reward" className={s.image} />
      <VersusPriceTag
        price={amount}
        priceType={VersusPriceType.Ton}
        size="big"
        iconSize={25}
        className={s.price}
        tagClassName={s.priceTag}
      />
    </div>
  );
};
