import cn from 'classnames';
import { useState } from 'react';
import {
  CryptoWithdrawal,
  CryptoWithdrawalStatus,
  VersusPriceType,
} from '@app/types';
import { VersusPriceTag } from '@app/components/VersusPriceTag';
import { fromNano } from '@ton/ton';
import { Button } from '@app/ui-kit';
import { useCountDown } from '@app/hooks';
import { ReactComponent as PendingIcon } from '@icons/pending.svg';
import { ReactComponent as Warning } from '@icons/warning.svg';
import { TournamentWithdrawPrizeBottomSheet } from '@app/components/TournamentWithdrawPrizeBottomSheet';
import { openLink } from '@app/utils';

import s from './CryptoPrizeItem.module.scss';

interface CryptoPrizeItemProps {
  data: CryptoWithdrawal;
  isSuspicious?: boolean;
  tonPrice: number;
  className?: string;
}

export const CryptoPrizeItem: React.FC<CryptoPrizeItemProps> = ({
  data,
  isSuspicious,
  tonPrice,
  className,
}) => {
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const { id, amount, unlocksAt, status, walletAddress, txHash } = data;
  const { currentValue: time, isCompleted } = useCountDown(new Date(unlocksAt));

  const canWithdraw = isCompleted;

  // const handleWithdraw = async () => {
  //   if (!canWithdraw) {
  //     return;
  //   }

  //   setWithdrawOpen(true);
  //   // versusTournamentService.DEV_createCryptoPrize();
  // };

  const contactSupport = () => {
    openLink('https://t.me/wormfarechat');
  };

  const timeLabel = time != null ? `In ${time}` : 'Invalid date';

  const cryptoAmount = +fromNano(amount);

  const getStatusMessage = () => {
    if (isCompleted && status === CryptoWithdrawalStatus.Pending) {
      return 'Contact Support';
    }

    if (!isCompleted) {
      return timeLabel;
    }

    if (status === CryptoWithdrawalStatus.ProcessingError) {
      return 'Processing Error';
    }

    if (
      canWithdraw &&
      status !== CryptoWithdrawalStatus.Completed &&
      status !== CryptoWithdrawalStatus.Pending
    ) {
      return status;
    }

    return '---';
  };

  const getButtonColor = () => {
    if (canWithdraw && status === CryptoWithdrawalStatus.Pending) {
      return 'var(--surface-accent-orange)';
    }

    if (canWithdraw && status === CryptoWithdrawalStatus.ProcessingError) {
      return 'var(--surface-accent-orange)';
    }

    return 'var(--action-secondary-base-default)';
  };

  const getButtonDisabled = () => {
    if (
      !canWithdraw ||
      (canWithdraw && status !== CryptoWithdrawalStatus.Pending)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={cn(s.root, className)}>
      <VersusPriceTag price={cryptoAmount} priceType={VersusPriceType.Ton} />
      <div className={s.usdEquiv}>≈ ${Math.round(cryptoAmount * tonPrice)}</div>
      {isSuspicious && (
        <Button
          bgColor={'var(--action-secondary-base-default)'}
          size="medium"
          variant="tertiary"
          disabled
          gap="2px"
          leftIcon={
            <Warning
              width={16}
              height={16}
              color="var(--text-on-actions-dark)"
            />
          }
          className={s.button}
        >
          FLAGGED
        </Button>
      )}
      {!isSuspicious && (
        <Button
          bgColor={getButtonColor()}
          size="medium"
          variant="tertiary"
          disabled={getButtonDisabled()}
          gap="2px"
          leftIcon={
            !canWithdraw ? (
              <PendingIcon
                width={16}
                height={16}
                color="var(--text-on-actions-dark)"
              />
            ) : undefined
          }
          // onClick={handleWithdraw}
          onClick={contactSupport}
          className={s.button}
        >
          {getStatusMessage()}
        </Button>
      )}
      {/* <TournamentWithdrawPrizeBottomSheet
        key={id}
        prizeId={id}
        amount={cryptoAmount}
        isOpen={withdrawOpen}
        onClose={setWithdrawOpen}
      /> */}
    </div>
  );
};
