import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';

import {
  TournamentCharacter,
  VersusUserCharacter,
  type UserProfile,
  type VersusAccessory,
  type VersusShopItem,
  type VersusWormData,
  type VersusWssSkill,
} from '@app/types';
import { UserService } from '../UserService';
import { notifyError } from '@app/ui-kit/ToastNotifications';
import { preloadImage } from '@app/utils';
import { ConfigService } from '../ConfigService';
import { VersusShopService } from '../VersusShopService';

// increment when want to ignore cached files
const assetsVer = '?v=1';

@Injectable()
@MakeObservable
export class VersusCharacterService {
  constructor(
    private readonly apiService: ApiService,
    private readonly configService: ConfigService,
    private readonly userService: UserService,
    private readonly versusShopService: VersusShopService,
  ) {}

  @observable
  public allWormCodes: string[] = [];

  async upgradeSkill(
    skillId: VersusWssSkill,
    code: string,
    onSuccess?: () => void,
  ) {
    try {
      const profile = await this.apiService.post<UserProfile>(
        '/versus/character/upgrade-skill',
        { skillId, code },
      );

      this.userService.updateProfile(profile);

      onSuccess?.();
    } catch (e) {
      console.error(e);
      notifyError(`Error: ${e}`);
    }
  }

  async selectCharacter(code: string) {
    const profile = await this.apiService.post<UserProfile>(
      '/versus/character/activate',
      { code },
    );

    this.userService.updateProfile(profile);
  }

  async applyAccessory(code: string | null) {
    await this.apiService.post<VersusWormData[]>(
      '/versus/character/apply-accessory',
      { code },
    );

    await this.userService.fetchProfile();
  }

  private getAssetsUrl() {
    const assetsUrl = `${this.configService.serverConfig?.assetsUrl}versus/assets/`;

    return assetsUrl;
  }

  generateCharAssets(code: string | undefined | null) {
    if (!code) {
      return null;
    }

    const assetsUrl = this.getAssetsUrl();

    const charPath = `${assetsUrl}chars/${code}/`;

    const assets = {
      avatarLg: `${charPath}avatar-lg.png${assetsVer}`,
      avatarSm: `${charPath}avatar-sm.png${assetsVer}`,
      logoLg: `${charPath}logo-lg.png${assetsVer}`,
      spineSkin: `${charPath}sprite.png${assetsVer}`,
    };

    return assets;
  }

  private getCharCodes = async () => {
    const res = await this.apiService.get<string[]>('/versus/character/codes');

    this.allWormCodes = res;

    return res;
  };

  async preloadUserCharacter() {
    if (
      !this.versusShopService.userCharacters ||
      !this.versusShopService.shopCharacters
    ) {
      await this.versusShopService.getCharacters();
    }

    const currentChar = this.userService.userProfile?.versus?.character.code;

    await this.preloadCommonAssets();

    if (currentChar) {
      await this.preloadCharacterAssets(currentChar);
    }

    console.log('Common assets preloaded and stored in IndexedDB');
  }

  async preloadAccessory(code: string, accessory?: VersusAccessory) {
    const assetsUrl = this.getAssetsUrl();
    const accessoriesPath = `${assetsUrl}accessories/`;

    const spriteUrl = `${accessoriesPath}${code}/sprite.png${assetsVer}`;
    const thumbnailUrl = this.generateAccessoryIcon(code);

    if (!accessory) {
      const allAccessories = [
        ...(this.versusShopService.userAccessories || []),
        ...(this.versusShopService.shopAccessories || []),
      ];

      accessory = allAccessories.find((item) => item.code === code);

      if (!accessory) {
        console.error(`Accessory with code ${code} not found`);

        return;
      }
    }

    const spriteBlob = await this.fetchAndConvertToBase64(spriteUrl);

    await this.storeInIndexedDB(code, spriteBlob, accessory.location);
    await preloadImage(thumbnailUrl);
  }

  async preloadAllAccessories() {
    if (
      !this.versusShopService.userAccessories ||
      !this.versusShopService.shopAccessories
    ) {
      await this.versusShopService.getAccessories();
    }

    const allAccessories = [
      ...(this.versusShopService.userAccessories || []),
      ...(this.versusShopService.shopAccessories || []),
    ];

    const accessories = Array.from(
      new Map(
        allAccessories.map((accessory) => [accessory.code, accessory]),
      ).values(),
    );

    await Promise.all(
      accessories.map(async (accessory) => {
        await this.preloadAccessory(accessory.code, accessory);
      }),
    );

    console.log('Accessories preloaded and stored in IndexedDB');
  }

  async preloadCharactersAndAccessories() {
    if (
      !this.versusShopService.userCharacters ||
      !this.versusShopService.shopCharacters
    ) {
      await this.versusShopService.getCharacters();
    }

    if (
      !this.versusShopService.userAccessories ||
      !this.versusShopService.shopAccessories
    ) {
      await this.versusShopService.getAccessories();
    }

    await this.getCharCodes();

    await Promise.all([
      ...this.allWormCodes.map((charCode) =>
        this.preloadCharacterAssets(charCode),
      ),
      this.preloadAllAccessories(),
    ]);

    console.log(
      'All unique character assets preloaded and stored in IndexedDB',
    );
  }

  async preloadCommonAssets() {
    const assetsUrl = this.getAssetsUrl();

    const assetsToPreload = {
      atlas: `${assetsUrl}spine.atlas.txt${assetsVer}`,
      skeleton: `${assetsUrl}spine.json${assetsVer}`,
      commonHands1: `${assetsUrl}common/slap_2_worm_3.png${assetsVer}`,
      commonHands2: `${assetsUrl}common/slap_2_worm_4.png${assetsVer}`,
    };

    await Promise.all(
      Object.entries(assetsToPreload).map(async ([key, url]) => {
        const blob = await this.fetchAndConvertToBase64(url);

        await this.storeInIndexedDB(key, blob);
      }),
    );

    console.log('Common assets preloaded and stored in IndexedDB');
  }

  generateAccessoryIcon(code: string) {
    const assetsUrl = this.getAssetsUrl();
    const accessoriesPath = `${assetsUrl}accessories/`;
    const thumbnailUrl = `${accessoriesPath}${code}/thumbnail-lg.png${assetsVer}`;

    return thumbnailUrl;
  }

  generateItemImage(code: string) {
    const assetsUrl = this.getAssetsUrl();
    const url = `${assetsUrl}items/${code}.png${assetsVer}`;

    return url;
  }

  async preloadCharacterAssets(charCode: string) {
    const assets = this.generateCharAssets(charCode);

    if (!assets) return;

    const { spineSkin, ...otherAssets } = assets;
    const skinKey = `${charCode}-skin`;

    const blob = await this.fetchAndConvertToBase64(spineSkin);

    await this.storeInIndexedDB(skinKey, blob);

    const preloadPromises = Object.values(otherAssets)
      .filter((url) => url.endsWith('.png'))
      .map((url) => preloadImage(url));

    await Promise.all(preloadPromises);
    console.log(`Preloaded images for character ${charCode}`);
  }

  async fetchAndConvertToBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  async openDatabase() {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open('VersusCharacterAssets', 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        db.createObjectStore('assets', { keyPath: 'key' });
      };
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  async storeInIndexedDB(key: string, blob: string, itemLocation?: string) {
    const db = await this.openDatabase();

    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction('assets', 'readwrite');
      const store = transaction.objectStore('assets');

      store.put({ key, blob, ...(itemLocation && { itemLocation }) });

      transaction.oncomplete = () => resolve();
      transaction.onerror = () => reject(transaction.error);
    });
  }

  async getItemFromIndexedDB(
    key: string,
  ): Promise<{ blob: string; itemLocation: string; key: string } | undefined> {
    const db = await this.openDatabase();
    const transaction = db.transaction('assets', 'readonly');
    const store = transaction.objectStore('assets');
    const request = store.get(key);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        const result = request.result as
          | { blob: string; itemLocation: string; key: string }
          | undefined;

        if (result) {
          resolve(result);
        } else {
          console.warn(`Asset with key "${key}" not found in IndexedDB.`);
          resolve(undefined);
        }
      };
      request.onerror = () => resolve(undefined);
    });
  }

  async getSkinOrAccessoryFromIndexedDb(code: string, isAccessory = false) {
    try {
      const existingAsset = await this.getItemFromIndexedDB(
        isAccessory ? code : `${code}-skin`,
      );

      if (!existingAsset) {
        console.log(
          `Asset with key "${code}" not found. Preloading ${isAccessory ? 'accessory' : 'character'} assets for ${code}...`,
        );

        if (isAccessory) {
          await this.preloadAccessory(code);

          return await this.getItemFromIndexedDB(code);
        } else {
          await this.preloadCharacterAssets(code);

          return await this.getItemFromIndexedDB(`${code}-skin`);
        }
      }

      console.log(`Asset with key "${code}" found in IndexedDB.`);

      return existingAsset;
    } catch (error) {
      console.error(
        `Failed to retrieve or preload asset with key "${code}":`,
        error,
      );

      return undefined;
    }
  }

  async loadCharacterAsset(charCode: string) {
    const assetBlob = await this.getItemFromIndexedDB(charCode);

    if (assetBlob) {
      console.log('Loaded asset:', assetBlob);
    } else {
      console.log('Asset not found in IndexedDB');
    }
  }

  getCharDataByCode = (
    mode: string | undefined,
    defaultChar: VersusUserCharacter | undefined | null,
    tournamentChar: TournamentCharacter | undefined | null,
  ) => {
    if (mode === 'tournament') {
      return {
        charCode: tournamentChar?.code,
        accessoryCode: tournamentChar?.accessories?.[0],
        skills: tournamentChar?.skills || [],
      };
    }

    return {
      charCode: defaultChar?.code,
      accessoryCode: defaultChar?.accessories?.[0]?.code,
      skills: defaultChar?.skills || [],
    };
  };
}
