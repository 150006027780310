import { useNavigate } from 'react-router-dom';

import { SettingsListItem } from '@components';
import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg';
import { Button } from '@app/ui-kit';
import wormIcon from '@media/worm-head.png';

import s from './ProfileSettingsNicknameItem.module.scss';

interface ProfileSettingsNicknameItemProps {
  nickname?: string;
  className?: string;
}

export const ProfileSettingsNicknameItem: React.FC<
  ProfileSettingsNicknameItemProps
> = ({ nickname, className }) => {
  const navigate = useNavigate();

  const handleNicknameButton = () => {
    navigate('/change-nickname');
  };

  const walletButton = !nickname ? (
    <Button
      variant="tertiary"
      bgColor="var(--action-primary-default)"
      rightIcon={<ChevronRight width={16} height={16} />}
    >
      Claim
    </Button>
  ) : (
    <Button
      variant="unfilled"
      rightIcon={<ChevronRight width={24} height={24} />}
      gap="12px"
      className={s.buttonNickname}
    >
      {nickname}
    </Button>
  );

  return (
    <SettingsListItem
      image={wormIcon}
      title={'Nickname'}
      value={walletButton}
      onClick={handleNicknameButton}
      className={className}
    />
  );
};
