import { imagesToPreload } from '@app/constants/imagesToPreload';
import {
  authService,
  versusAudioService,
  versusCharacterService,
  versusLeagueService,
} from '@app/services';
import { notifyError } from '@app/ui-kit/ToastNotifications';
import { preloadImage } from '@app/utils';
import { useEffect, useState } from 'react';

export const usePreloadResources = (isAuthLoading: boolean) => {
  const [resourceStatus, setResourceStatus] = useState({
    images: false,
    sounds: false,
    leagues: false,
    characters: false,
  });

  const [allResourcesLoaded, setAllResourcesLoaded] = useState(false);

  const totalResources = Object.keys(resourceStatus).length;
  const loadedResources = Object.values(resourceStatus).filter(Boolean).length;

  const progress = Math.floor((loadedResources / totalResources) * 100);

  const getLoadingMessage = () => {
    if (!resourceStatus.images) return 'Loading images...';

    if (!resourceStatus.sounds) return 'Loading sounds...';

    if (!resourceStatus.leagues) return 'Loading leagues...';

    if (!resourceStatus.characters) return 'Loading character...';

    return 'All done!';
  };

  const loadingMessage = getLoadingMessage();

  useEffect(() => {
    if (!authService.isAuth() || isAuthLoading) {
      return;
    }

    const updateResourceStatus = (key: keyof typeof resourceStatus) => {
      setResourceStatus((prev) => ({ ...prev, [key]: true }));
    };

    const preloadResourcesSequentially = async () => {
      try {
        await Promise.all(imagesToPreload.map((url) => preloadImage(url)));
        updateResourceStatus('images');

        await versusAudioService.preloadAudio();
        updateResourceStatus('sounds');

        await versusLeagueService.preloadLeagues();
        updateResourceStatus('leagues');

        await versusCharacterService.preloadUserCharacter();
        updateResourceStatus('characters');

        setTimeout(() => {
          setAllResourcesLoaded(true);
        }, 300);
      } catch (e) {
        notifyError(`${e}`);
        console.error('Error preloading resources ----', e);
      }
    };

    preloadResourcesSequentially();
  }, [isAuthLoading]);

  useEffect(() => {
    if (allResourcesLoaded) {
      versusCharacterService.preloadCharactersAndAccessories();
    }
  }, [allResourcesLoaded]);

  return { allResourcesLoaded, progress, resourceStatus, loadingMessage };
};
