/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton, Page, PurchaseAccessoryBlock } from '@app/components';
import { useServiceState } from '@app/common/state';
import { userService, versusShopService } from '@app/services';
import { useEffect } from 'react';
import { useSpineAnimation } from '@app/hooks';
import { ShopPageSections, ShopPageTabs } from '../shop/ShopPage';

import s from './ShopAccessoryPreviewPage.module.scss';

export const ShopAccessoryPreviewPage: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { userAccessories, shopAccessories } = useServiceState(
    versusShopService,
    ['shopAccessories', 'userAccessories'],
  );
  const userChar = userProfile?.versus?.character;

  const accessory = shopAccessories?.find((item) => item.code === id);

  useEffect(() => {
    const isOwned = userAccessories?.find((el) => el.code === id);

    if (!accessory || isOwned) {
      setTimeout(() =>
        navigate(
          `/shop?tab=${ShopPageTabs.default}&section=${ShopPageSections.accessoriesDefault}`,
        ),
      );
    }
  }, [accessory, id, navigate, userAccessories]);

  const { containerRef, isLoaded, dispose } = useSpineAnimation({
    charCode: userChar?.code,
    accessoryName: id,
    config: {
      preserveDrawingBuffer: false,
      animation: 'idle',
      alpha: true,
      viewport: {
        x: -160,
        y: 60,
        width: 500,
        height: 1100,
      },
    },
  });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accessory) {
    return null;
  }

  const { name, abilities, price, priceTon, priceFull, priceType } = accessory;

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton
        onClick={() =>
          navigate(
            `/shop?tab=${ShopPageTabs.default}&section=${ShopPageSections.accessoriesDefault}`,
          )
        }
      />

      <div
        className={cn(s.worm, { [s.loaded]: isLoaded })}
        ref={containerRef}
      />
      <PurchaseAccessoryBlock
        itemId={id!}
        itemName={name}
        price={price}
        priceFull={priceFull}
        priceTon={priceTon}
        priceType={priceType}
        abilities={abilities}
        className={cn(s.block, { [s.loaded]: isLoaded })}
      />
    </Page>
  );
};
