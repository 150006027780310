import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useServiceState } from '@app/common/state';
import { versusCharacterService } from '@app/services';
import wormLockedImg from '@media/worm-collector-locked.png';
import { VersusWormData } from '@app/types';

import s from './WormsCollection.module.scss';

interface WormsCollectionProps {
  userCharacters: VersusWormData[] | null;
  className?: string;
}

export const WormsCollection: React.FC<WormsCollectionProps> = ({
  userCharacters,
  className,
}) => {
  const navigate = useNavigate();
  const { allWormCodes } = useServiceState(versusCharacterService, [
    'allWormCodes',
  ]);

  const handleNavigate = (code: string) => {
    navigate(`/shop-worm-preview/${code}?variant=preview`);
  };

  return (
    <div className={cn(s.root, className)}>
      <div className={s.items}>
        {allWormCodes.map((el) => {
          const userCharacter = userCharacters?.find(
            (char) => char.code === el,
          );

          return renderWorm(
            !!userCharacter,
            el,
            userCharacter?.color || 'gray',
            handleNavigate,
          );
        })}
      </div>
    </div>
  );
};

const renderWorm = (
  isCollected: boolean,
  code: string,
  backgroundColor: string,
  onNavigate: (code: string) => void,
) => {
  if (isCollected) {
    const assets = versusCharacterService.generateCharAssets(code);

    return (
      <button
        className={cn(s.charWrapper, s.colSpan)}
        onClick={() => onNavigate(code)}
      >
        <div className={s.background} style={{ backgroundColor }} />
        <div className={s.avatarWrapper}>
          <img src={assets?.avatarLg} className={s.avatar} alt="avatar" />
        </div>
        <img src={assets?.logoLg} className={s.logo} alt="logo" />
      </button>
    );
  }

  return (
    <img
      src={wormLockedImg}
      alt="mystery worm"
      className={cn(s.mysteryImage, s.colSpan)}
    />
  );
};
