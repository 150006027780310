import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useRef } from 'react';

import {
  BackButton,
  Page,
  PhaseReward,
  TournamentIssuesBottomSheet,
  TournamentPhaseBattleCard,
} from '@app/components';
import { ReactComponent as ArrowIcon } from '@icons/arrow-right.svg';
import { useServiceState } from '@app/common/state';
import { versusTournamentService } from '@app/services';
import { VersusMatchOutcome } from '@app/types';
import { customScrollTo } from '@app/utils';

import s from './TournamentPhasePage.module.scss';

interface TournamentPhasePageProps {
  className?: string;
}

const SCROLL_TIME_MS = 800;
const TARGET_ID = 'target-battle';

export const TournamentPhasePage: React.FC<TournamentPhasePageProps> = ({
  className,
}) => {
  const navigate = useNavigate();
  const { currentStage, tournamentData, isFinalBattle } = useServiceState(
    versusTournamentService,
    ['currentStage', 'tournamentData', 'isFinalBattle'],
  );

  useEffect(() => {
    if (currentStage === null || !tournamentData) {
      navigate('/tournament?tab=phase');
    }
  });

  useEffect(() => {
    setTimeout(() => {
      const targetElement = document.getElementById(TARGET_ID);

      if (targetElement) {
        customScrollTo(targetElement, SCROLL_TIME_MS);
      }
    });
  }, []);

  const phaseData = versusTournamentService.getStageDataById(currentStage);

  if (!currentStage || !phaseData) {
    return null;
  }

  const { matches } = phaseData;

  const matchesList = Array(versusTournamentService.matchesPerStage)
    .fill(null)
    .map((_, index) => matches[index] || null);

  const getActiveBattleIndex = () => {
    for (let i = 0; i < matchesList.length; i++) {
      const match = matchesList[i];

      if (
        !match ||
        match.outcome === null ||
        match.outcome === VersusMatchOutcome.Draw
      ) {
        return i;
      }

      if (
        match.outcome === VersusMatchOutcome.Defeat ||
        match.outcome === VersusMatchOutcome.Retreat
      ) {
        return -1;
      }
    }

    return -1;
  };

  const activeBattleIndex = getActiveBattleIndex();

  return (
    <Page isHaveBottomNav={false} className={cn(s.root, className)}>
      <BackButton onClick={() => navigate('/tournament?tab=phase')} />
      <PhaseReward
        phaseData={phaseData}
        isFinalBattle={isFinalBattle}
        className={s.reward}
      />
      <ArrowIcon className={s.arrow} />
      <div className={s.battleList}>
        {matchesList.map((match, i) => (
          <Fragment key={i}>
            <TournamentPhaseBattleCard
              matchData={match}
              tournamentData={tournamentData}
              currentStage={currentStage}
              index={i + 1}
              totalMatches={matchesList.length}
              isActive={activeBattleIndex === i}
              scrollTargetId={TARGET_ID}
            />
            {i !== matchesList.length - 1 && <ArrowIcon className={s.arrow} />}
          </Fragment>
        ))}
      </div>
      <TournamentIssuesBottomSheet />
    </Page>
  );
};
