import cn from 'classnames';

import { VersusSkillUpgradable, VersusWssSkill } from '@app/types';
import { DoubleProgressBar } from '@components';

import s from './WormPreviewSkills.module.scss';
import { VERSUS_SKILLS } from '@app/constants/versus';

interface WormPreviewSkillsProps {
  skills: VersusSkillUpgradable[] | undefined;
  className?: string;
}

export const WormPreviewSkills: React.FC<WormPreviewSkillsProps> = ({
  skills = defaultSkills,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      {skills.map((skill) => (
        <div key={skill.id} className={s.skill}>
          <div className={s.level}>
            {skill.level}/{skill.maxLevel}
          </div>
          <DoubleProgressBar
            value1={skill.level}
            max1={skill.generalMaxLevel}
            value2={skill.maxLevel}
            max2={skill.generalMaxLevel}
          />
          <div className={s.name}>{VERSUS_SKILLS[skill.id].name}</div>
        </div>
      ))}
    </div>
  );
};

const defaultSkills = [
  {
    id: VersusWssSkill.Damage,
    level: 0,
    maxLevel: 3,
    generalMaxLevel: 20,
    upgradePrice: 0,
  },
  {
    id: VersusWssSkill.Resilience,
    level: 0,
    maxLevel: 3,
    generalMaxLevel: 20,
    upgradePrice: 0,
  },
  {
    id: VersusWssSkill.Reaction,
    level: 0,
    maxLevel: 2,
    generalMaxLevel: 20,
    upgradePrice: 0,
  },
];
