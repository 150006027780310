import cn from 'classnames';
import { useEffect, useState } from 'react';
import { fromNano } from '@ton/core';

import { ManagerEarningItem } from '@components';
import { Button } from '@app/ui-kit';
import {
  apiService,
  configService,
  referralService,
  web3Service,
} from '@app/services';
import { useServiceState } from '@app/common/state';
import { CryptoWithdrawalStatus } from '@app/types';

import s from './ManagerEarningsCard.module.scss';

interface ManagerEarningsCardProps {
  className?: string;
}

export const ManagerEarningsCard: React.FC<ManagerEarningsCardProps> = ({
  className,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { stats, withdrawals } = useServiceState(referralService, [
    'stats',
    'withdrawals',
  ]);
  const { tonPrice } = useServiceState(web3Service, ['tonPrice']);

  useEffect(() => {
    if (!stats) {
      referralService.getStats();
    }

    if (!withdrawals) {
      referralService.getWithdrawals();
    }
  }, [stats, withdrawals]);

  const { tonEarned = 0, tonWithdrawn = 0, tonAvailable = 0 } = stats || {};

  const canWithdraw = tonAvailable && tonAvailable > 0;

  const pending =
    withdrawals?.reduce((total, el) => {
      return el.status === CryptoWithdrawalStatus.Pending
        ? total + Number(fromNano(el.amount))
        : total;
    }, 0) || 0;

  const showLoader = stats == null || isLoading;

  const handleWithdraw = async () => {
    setIsLoading(true);

    try {
      await referralService.withdrawReward();
    } catch (e) {
      apiService.notifyError(e);
    }

    setIsLoading(false);
  };

  return (
    <div className={cn(s.root, className)}>
      <h5 className={s.sectionTitle}>Your earnings</h5>
      <div className={s.earnings}>
        <ManagerEarningItem
          variant="earned"
          amount={tonEarned}
          isLoading={showLoader}
          tonPrice={tonPrice}
        />
        <ManagerEarningItem
          variant="withdrawn"
          amount={tonWithdrawn}
          isLoading={showLoader}
          tonPrice={tonPrice}
        />
        {pending > 0 && (
          <ManagerEarningItem
            variant="pending"
            amount={pending}
            isLoading={showLoader}
            tonPrice={tonPrice}
          />
        )}
      </div>
      <div className={s.available}>
        <ManagerEarningItem
          variant="available"
          amount={tonAvailable}
          isLoading={showLoader}
          tonPrice={tonPrice}
        />
        <Button
          variant="tertiary"
          bgColor="var(--action-primary-default)"
          onClick={handleWithdraw}
          disabled={!canWithdraw || isLoading}
        >
          Withdraw now!
        </Button>
        {configService.env !== 'production' && (
          <>
            <Button
              variant="unfilled"
              onClick={() => referralService.DEV_createWithdrawal()}
              className={s.devButton}
            >
              DEV: add withdrawal
            </Button>
            <Button
              variant="unfilled"
              onClick={() => referralService.DEV_createReferral()}
              className={s.devButton}
            >
              DEV: add referral
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
