import {
  BackButton,
  BottomButton,
  Page,
  ShopForCard,
  WormCard,
} from '@app/components';

import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useServiceState } from '@app/common/state';
import {
  apiService,
  userService,
  versusCharacterService,
  versusShopService,
  versusTournamentService,
} from '@app/services';

import s from './SelectWormPage.module.scss';

export const SelectWormPage: React.FC = () => {
  const { mode } = useParams<{
    mode: 'default' | 'tournament';
  }>();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { userCharacters } = useServiceState(versusShopService, [
    'userCharacters',
  ]);
  const { tournamentData } = useServiceState(versusTournamentService, [
    'tournamentData',
  ]);

  const { charCode } = versusCharacterService.getCharDataByCode(
    mode,
    userProfile?.versus?.character,
    tournamentData?.character,
  );

  const [selectedCardCode, setSelectedCardCode] = useState<string | undefined>(
    charCode,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasCharacters = Boolean(userCharacters?.length);

  const submitDisabled = isSubmitting || charCode === selectedCardCode;

  const navigate = useNavigate();

  const handleSelect = async (code: string) => {
    setSelectedCardCode(code);
  };

  const handleSubmit = async () => {
    if (selectedCardCode) {
      setIsSubmitting(true);

      try {
        if (mode === 'default') {
          await versusCharacterService.selectCharacter(selectedCardCode);
          navigate('/play');
        }

        if (mode === 'tournament') {
          await versusTournamentService.selectCharacter(selectedCardCode);
          navigate('/tournament-char-manage');
        }
      } catch (e) {
        apiService.notifyError(e);
      }

      setIsSubmitting(false);
    }
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={() => navigate(-1)} />
      <h1 className={s.title}>My worms</h1>
      <p className={s.description}>Slap your opponents in style</p>
      {hasCharacters && (
        <>
          <div className={s.cards}>
            {userCharacters?.map((worm) => (
              <WormCard
                wormInfo={worm}
                key={worm.code}
                isSelected={selectedCardCode === worm.code}
                onClick={handleSelect}
              />
            ))}
          </div>
          <ShopForCard variant="selector" />
          <BottomButton
            label="Select"
            disabled={submitDisabled}
            onClick={handleSubmit}
          />
        </>
      )}
      {!hasCharacters && (
        <div className={s.error}>
          No characters?? <br />
          Looks like something is wrong..
        </div>
      )}
    </Page>
  );
};
