import cn from 'classnames';

import tonWalletImg from '@media/ton-wallet.png';
import { BottomSheet, Button } from '@app/ui-kit';
import { useTonConnect } from '@app/hooks';

import s from './WalletConnectBottomSheet.module.scss';

interface WalletConnectBottomSheetProps {
  title?: string;
  description?: string;
  backButton?: string;
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
  onBack?: () => void;
}

export const WalletConnectBottomSheet: React.FC<
  WalletConnectBottomSheetProps
> = ({
  title,
  description,
  isOpen,
  backButton,
  className,
  onClose,
  onBack,
}) => {
  const { tonConnectUI, userFriendlyAddress } = useTonConnect();

  const handleConnection = () => {
    if (userFriendlyAddress) {
      tonConnectUI.disconnect();
    } else {
      tonConnectUI.openModal();
    }
  };

  return (
    <BottomSheet
      open={isOpen}
      onClose={onClose}
      contentClassName={s.bottomSheet}
      bgColor={'var(--surface-primary)'}
      className={cn(s.root, className)}
    >
      <img src={tonWalletImg} alt="wallet" className={s.walletImage} />
      <h2 className={s.title}>
        {title ?? (userFriendlyAddress ? 'Connected' : 'Not connected')}
      </h2>
      {description && <div className={s.description}>{description}</div>}
      {!description && userFriendlyAddress && (
        <div className={s.address}>{userFriendlyAddress}</div>
      )}
      <Button
        onClick={handleConnection}
        variant={userFriendlyAddress ? 'secondary' : 'primary'}
        className={s.connectionButton}
      >
        {userFriendlyAddress ? 'Disconnect' : 'Connect'}
      </Button>
      {backButton && (
        <Button
          onClick={onBack}
          variant={'secondary'}
          className={s.connectionButton}
        >
          {backButton}
        </Button>
      )}
    </BottomSheet>
  );
};
