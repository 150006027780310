import { Injectable } from '@app/common/di';
import { MakeObservable, observable } from '@app/common/state';
import { ApiService } from '../ApiService';
import {
  ApiSort,
  CryptoWithdrawal,
  CryptoWithdrawalStatus,
  CryptoWithdrawalType,
  type ReferralResponse,
  type ReferralStats,
} from '@app/types';
import { toNano } from '@ton/core';

@Injectable()
@MakeObservable
export class ReferralService {
  constructor(private readonly apiService: ApiService) {}

  @observable
  public referrals: ReferralResponse | null = null;

  @observable
  public stats: ReferralStats | null = null;

  @observable
  public withdrawals: CryptoWithdrawal[] | null = null;

  async getInitialReferrals(limit = 10, offset = 0, sort: ApiSort = 'asc') {
    try {
      this.referrals = await this.apiService.get<ReferralResponse>(
        '/user/my-referrals',
        {
          limit,
          offset,
          sort,
        },
      );
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async loadMoreReferrals(limit = 10, sort: ApiSort = 'asc') {
    if (!this.referrals) {
      this.referrals = {
        items: [],
        limit: 0,
        offset: 0,
        total: 0,
      };
    }

    if (this.referrals.items.length >= this.referrals.total) {
      return;
    }

    try {
      const response = await this.apiService.get<ReferralResponse>(
        '/user/my-referrals',
        {
          limit,
          offset: this.referrals.items.length,
          sort,
        },
      );

      this.referrals = {
        ...this.referrals,
        items: [...this.referrals.items, ...response.items],
        total: response.total,
      };
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async getStats() {
    try {
      this.stats = await this.apiService.get<ReferralStats>('/referral');
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async withdrawReward() {
    this.stats =
      await this.apiService.post<ReferralStats>('/referral/withdraw');

    await this.getWithdrawals();
  }

  async getWithdrawals(status?: CryptoWithdrawalStatus[]) {
    try {
      this.withdrawals = await this.apiService.get<CryptoWithdrawal[]>(
        '/versus/withdrawal',
        { type: CryptoWithdrawalType.Referral, ...(status && { status }) },
      );
    } catch (e) {
      this.apiService.notifyError(e);
    }
  }

  async DEV_createWithdrawal() {
    await this.apiService.post('/dev/create-withdrawal', {
      amount: toNano(2).toString(),
      type: CryptoWithdrawalType.Referral,
    });
    await this.getWithdrawals();
  }

  async DEV_createReferral() {
    await this.apiService.post('/dev/create-referral-user');

    await this.getInitialReferrals();
  }
}
