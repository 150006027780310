import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { ListItems } from '@ui-kit';
import { QuestCard, TaskItem } from './components';
import { Quest } from '@app/types';
import defaultImgUrl from '@media/wormfare.webp';
import { questService } from '@app/services';
import { wait } from '@app/utils/wait';
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from '@ui-kit/ToastNotifications';
import { isTelegramLink } from '@app/utils/isTelegramLink';
import { openLink } from '@app/utils';
import { useNavigate } from 'react-router-dom';

import s from './QuestPage.module.scss';

interface QuestPageProps {
  variant?: 'defult' | 'tomato';
  className?: string;
  quest: Quest;
  img?: string;
  onClaimed?: () => void;
}

export const QuestPage: React.FC<QuestPageProps> = ({
  variant = 'defult',
  className,
  quest,
  img,
  onClaimed,
}) => {
  const {
    id: questId,
    type,
    title,
    subtitle,
    description,
    isCompleted,
    rewardAmount,
    icon,
    buttonTitle,
    tasks,
  } = quest;

  const navigate = useNavigate();

  const [isClaming, setIsClaming] = useState(false);
  const [checkingQuestOrTasks, setCheckingQuestOrTasks] = useState(false);
  const [ctaLink, setCtaLink] = useState<string | undefined>(undefined);

  const firstIncompleteTask = tasks?.find((task) => !task.isCompleted);

  const handleClaimReward = useCallback(
    async (questId: string) => {
      try {
        notifyInfo('Claim reward, please wait...');
        setIsClaming(true);
        await questService.claimReward(questId);
        onClaimed?.();
        setIsClaming(false);

        notifySuccess('Reward successfully received!');
      } catch (error) {
        notifyError('Error claim reward. Please try again.');

        console.error('Claim reward quest:', error);
      }
    },
    [onClaimed],
  );

  const handleStartQuestOrTask = async (questId: string, taskId?: number) => {
    const isQuestStarted = questService.startQuestOrTask(questId, taskId);

    if (!isQuestStarted) {
      return;
    }

    const task = tasks?.find(({ id }) => id === taskId);

    const ctaLink = task?.url || quest.url;

    setCtaLink(ctaLink);

    if (!ctaLink) {
      return;
    }

    if (/^\//.test(ctaLink)) {
      navigate(ctaLink);
    } else {
      openLink(ctaLink);

      if (isTelegramLink(ctaLink)) {
        setTimeout(() => {
          checkQuestOrTasks();
        }, 3000);
      }
    }
  };

  const checkQuestOrTasks = async () => {
    if (!questService.isQuestOrTaskNeedToCheck(questId)) {
      return;
    }

    try {
      setCheckingQuestOrTasks(true);
      await wait(1000);

      const inProgressTask = questService.getInProgressTask(questId);

      await questService.checkCompletion(quest.id, inProgressTask?.id);
    } finally {
      setCheckingQuestOrTasks(false);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        checkQuestOrTasks();
      }
    });

    checkQuestOrTasks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quest.id]);

  return (
    <div className={clsx(s.root, className)}>
      <QuestCard
        className={s.rewardCard}
        title={title}
        subtitle={subtitle}
        desc={description}
        isCompleted={isCompleted}
        reward={rewardAmount}
        icon={icon || defaultImgUrl}
        ctaLabel={buttonTitle}
        isClaming={isClaming}
        completeTimes={quest.completeTimes}
        maxCompleteTimes={quest.maxCompleteTimes}
        onAction={() => handleStartQuestOrTask(questId)}
        onClaim={() => handleClaimReward(questId)}
        isChecking={
          checkingQuestOrTasks &&
          quest.inProgress &&
          !isCompleted &&
          type === 'simple'
        }
      />
      {tasks && (
        <ListItems
          listClassName={s.list}
          items={tasks}
          keyExtractor={({ id }) => id.toString()}
          renderItem={(
            { id, title, isCompleted, description, payloadParams, inProgress },
            index,
          ) => (
            <TaskItem
              number={index + 1}
              title={title}
              desc={description}
              isCompleted={isCompleted}
              onClick={() => handleStartQuestOrTask(questId, id)}
              disable={
                firstIncompleteTask?.id === id || inProgress === true
                  ? false
                  : true
              }
              isChecking={checkingQuestOrTasks && inProgress && !isCompleted}
              payloadParams={payloadParams}
              onCheck={(email) => {
                questService.checkCompletion(questId, id, { email });
              }}
              inProgress={inProgress}
            />
          )}
        />
      )}
      {img && (
        <div className={s.imgWrap}>
          <img src={img} className={s.img} alt="Quest img" />
        </div>
      )}
    </div>
  );
};
