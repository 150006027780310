import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useState } from 'react';
import { AnimatePresence, m } from 'framer-motion';

import {
  Page,
  VersusHeader,
  TournamentResultContent,
  TournamentOverviewContent,
  TournamentIssuesBottomSheet,
} from '@components';
import { Button, Tabs } from '@app/ui-kit';
import { useHideBackButton } from '@app/hooks';
import { configService, versusTournamentService } from '@app/services';
import { VersusUserAssetType } from '@app/types';

import s from './TournamentPage.module.scss';

interface TournamentPageProps {
  className?: string;
}

export enum TournamentPageParams {
  phase = 'phase',
  results = 'results',
}

export const TournamentPage: React.FC<TournamentPageProps> = ({
  className,
}) => {
  useHideBackButton();

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') as TournamentPageParams;

  const [currentSection, setCurrentSection] = useState<TournamentPageParams>(
    tab ?? TournamentPageParams.phase,
  );

  const [direction, setDirection] = useState<number>(0);

  const handleTabChange = (tab: TournamentPageParams) => {
    document.body.scrollIntoView({ behavior: 'smooth' });
    setDirection(tab === TournamentPageParams.phase ? -1 : 1);
    setCurrentSection(tab);
    setSearchParams((prev) => {
      prev.set('tab', tab);
      prev.delete('section');

      return prev;
    });
  };

  return (
    <Page isHaveBottomNav={true} className={cn(s.root, className)}>
      <VersusHeader variant="tournament" className={s.header} />

      <div className={s.tabs}>
        <Tabs
          initialActiveTab={tab}
          currentActiveTab={currentSection}
          variant="secondary"
          tabs={[
            { key: TournamentPageParams.phase, label: 'Tournament' },
            { key: TournamentPageParams.results, label: 'Results' },
          ]}
          onTabChange={handleTabChange}
        />
      </div>
      {configService.env !== 'production' && (
        <div className={s.dev}>
          <Button
            variant="unfilled"
            onClick={() => versusTournamentService.DEV_createCryptoPrize()}
          >
            DEV: add crypto prize
          </Button>
          <Button
            variant="unfilled"
            onClick={() => versusTournamentService.DEV_winTournamentMatch()}
          >
            DEV: win match
          </Button>
          <Button
            variant="unfilled"
            onClick={() =>
              versusTournamentService.DEV_addAssets({
                type: VersusUserAssetType.Medals,
                amount: 100,
              })
            }
          >
            DEV: add 100 medals
          </Button>
        </div>
      )}
      <AnimatePresence custom={direction} mode="popLayout" initial={false}>
        {currentSection === TournamentPageParams.phase && (
          <m.div
            key="tournament"
            className={s.content}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={transition}
          >
            <TournamentOverviewContent />
          </m.div>
        )}
        {currentSection === TournamentPageParams.results && (
          <m.div
            key="results"
            className={s.content}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={transition}
          >
            <TournamentResultContent />
          </m.div>
        )}
      </AnimatePresence>
      <TournamentIssuesBottomSheet />
    </Page>
  );
};

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? '100%' : '-100%',
  }),
  center: {
    x: 0,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? '100%' : '-100%',
  }),
};

const transition = {
  type: 'tween',
  duration: 0.6,
};
