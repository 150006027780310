import React from 'react';
import clsx from 'clsx';

import { userService } from '@app/services';
import wormHeadUrl from '@media/worm-head.png';
import { useServiceState } from '@app/common/state';
import { ReactComponent as PendingIcon } from '@icons/pending.svg';
import { InfoBlock } from '@app/ui-kit';

import s from './ManagerSlappersCard.module.scss';

interface ManagerSlappersCardProps {
  className?: string;
}

export const ManagerSlappersCard: React.FC<ManagerSlappersCardProps> = ({
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const invited = userProfile?.invitedActiveUsersCount || 0;
  const invitedTotal = userProfile?.invitedUsersCount || 0;
  const pending = invitedTotal - invited;

  return (
    <div className={clsx(s.root, className)}>
      <img className={s.img} src={wormHeadUrl} alt="worm" />

      <div className={s.content}>
        <h5 className={s.title}>Your Slappers</h5>
        <div className={s.slappers}>
          <span className={s.amount}>{invited}</span>
          <div>slappers</div>
        </div>
        {pending > 0 && (
          <div className={s.pending}>
            <PendingIcon width={16} height={16} />
            {pending} Pending Referrals
          </div>
        )}
        <InfoBlock
          variant="warning"
          backgroundColor="var(--surface-accent-orange)"
          message="Your slappers will only count after they enter at least one tournament"
        />
      </div>
    </div>
  );
};
