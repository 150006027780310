import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from '@icons/close.svg';

import styles from './BottomSheet.module.scss';
import { IconButton } from '../IconButton';

interface BottomSheetProps {
  className?: string;
  contentClassName?: string;
  children: React.ReactNode;
  open: boolean;
  bgColor?: string;
  withClose?: boolean;
  insideCoverContent?: ReactNode;
  onClose?: () => void;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  className,
  contentClassName,
  children,
  bgColor = 'linear-gradient(180deg, #110007 0%, #27181b 100%)',
  open,
  withClose = true,
  insideCoverContent,
  onClose,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [open]);

  const style = {
    background: bgColor,
  } as React.CSSProperties;

  const classNames = clsx(styles.root, className, styles[bgColor], {
    [styles.open]: open,
  });

  return createPortal(
    <div className={classNames}>
      <div className={styles.cover}>{insideCoverContent}</div>
      <div className={clsx(styles.content, contentClassName)} style={style}>
        {withClose && (
          <IconButton
            className={styles.closeBtn}
            icon={
              <CloseIcon
                style={{ width: 24, height: 24, color: 'var(--sand)' }}
              />
            }
            onClick={onClose}
            variant="close"
            size="medium"
          />
        )}
        {/* <button className={styles.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button> */}
        {children}
      </div>
    </div>,
    document.body,
  );
};
