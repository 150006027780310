import { ProgressValues } from './common';

export interface VersusSkillUpgradable extends VersusWssSkillData {
  upgradePrice: number;
  maxLevel: number;
  generalMaxLevel: number;
}

export interface VersusRewardType {
  multiplier?: number;
  name: string;
  points: number;
}

export type RoundState =
  | 'idle'
  | 'startRound'
  | 'preDetectForce'
  | 'detectForce'
  | 'showHitForce'
  | 'waiting'
  | 'timeout'
  | 'fight';

export type FightState = 'playerTurn' | 'opponentTurn';

export enum HitForce {
  miss = 0,
  okay = 0.5,
  splendid = 1,
}

export interface WheelIndicatorState {
  sectorColor?: string;
  text?: string;
  textColor?: string;
}

export interface LeaderboardEntry {
  id: number;
  place: number;
  avatar: string;
  name: string;
  rating: number;
}

export interface LeagueLeaderboardData {
  rank: number;
  name: string;
  leaderboard: LeaderboardEntry[];
}

export interface VersusPlayer {
  hp: ProgressValues;
  name: string;
  skills: VersusWssSkillData[];
  rating: VersusUserRating;
  character: VersusWssCharacter;
}

export enum VersusServerEvent {
  Error = 'error',
  VersusMatchmakingQueued = 'versus:matchmaking:queued',
  VersusMatchCreated = 'versus:match:created',
  VersusMatchHitMultiplier = 'versus:match:hitMultiplier',
  VersusMatchNewRound = 'versus:match:newRound',
  VersusMatchFinish = 'versus:match:finish',
  VersusMatchState = 'versus:match:state',
}

export enum VersusClientEvent {
  VersusMatchmakingQueue = 'versus:matchmaking:queue',
  VersusMatchMakingCancel = 'versus:matchmaking:cancel',
  VersusMatchHit = 'versus:match:hit',
  VersusMatchRetreat = 'versus:match:retreat',
  VersusMatchGetState = 'versus:match:getState',
  VersusMatchPing = 'versus:match:ping',
}

export interface VersusWssCharacter {
  code: string;
  accessories: VersusAccessory[] | [];
}

export enum VersusWssSkill {
  Damage = 'Damage',
  Resilience = 'Resilience',
  Reaction = 'Reaction',
}

export interface VersusWssSkillData {
  id: VersusWssSkill;
  level: number;
}

export interface VersusWssUser {
  name: string;
  character: VersusWssCharacter;
  skills: VersusWssSkillData[];
  reactionPeriod: number;
  rating: VersusUserRating;
}

export interface VersusWssEnemy {
  name: string;
  character: VersusWssCharacter;
  skills: VersusWssSkillData[];
  rating: VersusUserRating;
}

export interface VersusWssRound {
  round: number;
  startDate: string;
  endDate: string;
  image: string;
}

export interface VersusWssMatchUserStats {
  userId: number;
  hp: number; // 0-100
}

export interface VersusWssMatch {
  user: VersusWssUser;
  enemy: VersusWssEnemy;
  hp: number;
  enemyHp: number;
  round: number;
  startDate: string;
  endDate: string;
  serverDate: string;
  image: string;
  victoryRewards: VersusWssVictoryReward[];
  isTournament: boolean;
}

export interface VersusWssNewRound {
  hitDamage: number;
  enemyHitDamage: number;
  hp: number;
  enemyHp: number;
  round: number;
  startDate: string;
  endDate: string;
  serverDate: string;
  image: string;
  enemyHitMultiplier: HitForce;
  isTournament: boolean;
  maxHp: number;
}

export enum VersusMatchOutcome {
  Victory = 'Victory',
  Defeat = 'Defeat',
  Draw = 'Draw',
  Retreat = 'Retreat',
}

export interface VersusWssGameOver {
  hitDamage: number;
  enemyHitDamage: number;
  hp: number;
  enemyHp: number;
  outcome: VersusMatchOutcome;
  scoreChange: number;
  newScore: number;
  ratingChange: number;
  newRating: number;
  enemyHitMultiplier: HitForce;
  versusScoreChange: number;
  versusScoreRewards: VersusRewardType[];
  isTournament: boolean;
}

export interface VersusWssHitForce {
  hitMultiplier: HitForce;
}

export interface VersusMatchHitParams {
  time: number;
}

export interface VersusWssError {
  message: string;
}

export interface VersusOnboardingRewards {
  scoreChange: number;
  versusScoreChange: number;
  ratingChange: number;
  newRating: number;
}

export interface VersusWssQueuedData {
  user: {
    name: string;
    character: VersusWssCharacter;
    skills: VersusWssSkillData[];
    rating: VersusUserRating;
  };
}

export enum VersusWssVictoryRewardType {
  Score = 'Score',
  VersusScore = 'VersusScore',
  Medals = 'Medals',
  Ton = 'Ton',
  GoldenTicket = 'GoldenTicket',
  DiamondTicket = 'DiamondTicket',
}

export interface VersusWssVictoryReward {
  type: VersusWssVictoryRewardType;
  amount: number;
  isPrimary: boolean;
}

export interface VersusWssMatchState {
  user: VersusWssUser;
  enemy: VersusWssEnemy;
  hp: number;
  maxHp: number;
  enemyHp: number;
  round: number;
  startDate: string;
  endDate: string;
  serverDate: string;
  image: string;
  hitMultiplier: HitForce;
  victoryRewards: VersusWssVictoryReward[];
  isTournament: boolean;
}

export interface VersusUserRating {
  rank: number;
  progress: number;
  progressMax: number;
  league: string;
  leagueName: string;
  leagueIcon: string;
  leagueRank: number;
}

export interface VersusBattleResultData {
  leagueLevel: number;
  leagueProgress: number;
  leagueImage: string;
  score: number;
  balance: number;
  balanceAfterBattle: number;
  scoreChange: number;
  versusScore: number;
  versusScoreChange: number;
  rewards: VersusRewardType[];
  shouldNavigateToLeague: boolean;
}
export interface VersusLeagueRewardType {
  image: string;
  type: string;
  amount?: number;
  title: string;
  description: string;
}

export interface VersusLeagueRewards {
  rank: number;
  league: string;
  leagueName: string;
  leagueIcon: string;
  isClaimed: boolean;
  canClaim: boolean;
  rewards: VersusLeagueRewardType[];
}

export interface VersusSkillsLevels {
  [VersusWssSkill.Damage]: number;
  [VersusWssSkill.Resilience]: number;
  [VersusWssSkill.Reaction]: number;
}

export interface VersusWormData {
  code: string;
  name: string;
  color: string;
  rarity: number;
  price?: number;
  priceFull?: number;
  priceStars?: number;
  priceTon?: string;
  priceType?: VersusPriceType;
  skills?: VersusSkillUpgradable[];
  startSkills?: VersusSkillUpgradable[];
  isOwned?: boolean;
  isLimited?: boolean;
  isSoldOut?: boolean;
}

export enum VersusAccessoryBonusType {
  Cucumbers = 'Cucumbers',
  Tomatoes = 'Tomatoes',
}

export enum VersusAccessoryLocation {
  Head = 'Head',
  Face = 'Face',
  Body = 'Body',
}

export interface VersusAccessoryBonus {
  bonusType: VersusAccessoryBonusType;
  bonusAmount: number;
  description?: string;
}

export interface VersusAccessory {
  code: string;
  name: string;
  location: VersusAccessoryLocation;
  abilities: VersusAccessoryBonus[];
  price: number;
  priceFull?: number;
  priceStars?: number;
  priceTon?: string;
  priceType: VersusPriceType;
  isOwned: boolean;
}

export enum VersusPriceType {
  Tomato = 'Tomato',
  Ton = 'Ton',
  Stars = 'Stars',
  Medals = 'Medals',
}

export interface VersusShopItem {
  code: string;
  amount: number;
  name: string;
  price: number;
  priceFull?: number;
  priceTon?: string;
  priceStars?: number;
  priceType: VersusPriceType;
  isLimited?: boolean;
}

export enum ShopInvoiceType {
  Stars = 'Stars',
  Ton = 'Ton',
}

export interface ShopInvoice {
  id: string;
  type: ShopInvoiceType;
  itemCode: string;
  amount: string;
  invoiceLink: string;
  isPaid: boolean;
}

export enum ShopItemType {
  worm = 'worm',
  accessory = 'accessory',
  item = 'item',
}

export interface VersusUserCharacter {
  code: string;
  name: string;
  color: string;
  rarity: number;
  skills: VersusSkillUpgradable[];
  accessories: VersusAccessory[] | [];
}

export enum PhaseEntryTicket {
  SilverTicket = 'SilverTicket',
  GoldenTicket = 'GoldenTicket',
  DiamondTicket = 'DiamondTicket',
}

export interface PhaseEntryFee {
  type: PhaseEntryTicket;
  amount: number;
  code: string;
}

export enum TournamentStatus {
  Active = 'Active',
  Finished = 'Finished',
}

export enum TournamentRewardType {
  GoldenTicket = 'GoldenTicket',
  DiamondTicket = 'DiamondTicket',
  Medals = 'Medals',
  Ton = 'Ton',
}

export interface PhaseReward {
  amount: number;
  type: TournamentRewardType;
  description?: string;
}

export interface TournamentMatchEnemy {
  code: string;
  accessories: string[];
}

export interface TournamentMatch {
  stage?: number;
  outcome?: VersusMatchOutcome | null;
  enemy?: TournamentMatchEnemy | null;
}

export interface TournamentStage {
  stage: number;
  title: string;
  description?: string;
  rewardsDescription?: string;
  rewards: PhaseReward[];
  entranceFee: PhaseEntryFee[];
  matches: TournamentMatch[];
  isEntered: boolean;
  isCompleted: boolean;
  isCurrent: boolean;
}

export interface TournamentCharacter {
  code: string;
  skills: VersusSkillUpgradable[];
  accessories: string[];
}

export interface VersusTournament {
  status?: TournamentStatus;
  character?: TournamentCharacter;
  canChangeCharacter?: boolean;
  stages: TournamentStage[];
  claimableTonAmount?: number;
}

export interface VersusTournamentStatusRes {
  isActive: boolean;
  reason: string;
}

export enum VersusUserAssetType {
  SilverTicket = 'SilverTicket',
  GoldenTicket = 'GoldenTicket',
  DiamondTicket = 'DiamondTicket',
  Medals = 'Medals',
}

export interface VersusUserAsset {
  type: VersusUserAssetType;
  amount: number;
}

export enum CryptoWithdrawalStatus {
  Pending = 'Pending',
  Queued = 'Queued',
  Processing = 'Processing',
  ProcessingError = 'ProcessingError',
  Completed = 'Completed',
}

export enum CryptoWithdrawalType {
  TournamentWin = 'TournamentWin',
  Referral = 'Referral',
}

export interface CryptoWithdrawal {
  id: string;
  type: CryptoWithdrawalType;
  amount: number;
  unlocksAt: string;
  canWithdraw: boolean;
  status: CryptoWithdrawalStatus;
  walletAddress?: string;
  txHash?: string;
  createdAt: string;
}

export interface TournamentPastWinner {
  name: string;
  tonAmount: string;
  character: TournamentCharacter;
  leagueIcon: string;
}

export enum VersusLeagueType {
  common = 'common',
  rare = 'rare',
  epic = 'epic',
  legendary = 'legendary',
  mythic = 'mythic',
}
