import { useState } from 'react';
import { useTonConnect } from '@app/hooks';

import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg';
import { Button } from '@app/ui-kit';
import { shortTonAddress } from '@app/utils';
import tonWalletIcon from '@media/ton-wallet.png';
import { SettingsListItem, WalletConnectBottomSheet } from '@components';

import s from './ProfileSettingsWalletItem.module.scss';

interface ProfileSettingsWalletItemProps {
  className?: string;
}

export const ProfileSettingsWalletItem: React.FC<
  ProfileSettingsWalletItemProps
> = ({ className }) => {
  const { tonConnectUI, userFriendlyAddress } = useTonConnect();
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  const handleWalletButton = () => {
    if (userFriendlyAddress) {
      setIsWalletModalOpen(!isWalletModalOpen);
    } else {
      tonConnectUI.openModal();
    }
  };

  const walletButton = !userFriendlyAddress ? (
    <Button
      variant="tertiary"
      bgColor="var(--action-primary-default)"
      rightIcon={<ChevronRight width={16} height={16} />}
    >
      Connect
    </Button>
  ) : (
    <Button
      variant="unfilled"
      rightIcon={<ChevronRight width={24} height={24} />}
      gap="12px"
      className={s.buttonAddress}
    >
      {shortTonAddress(userFriendlyAddress, 4, 4)}
    </Button>
  );

  return (
    <>
      <SettingsListItem
        image={tonWalletIcon}
        title={'Wallet'}
        value={walletButton}
        className={className}
        onClick={handleWalletButton}
      />
      <WalletConnectBottomSheet
        isOpen={isWalletModalOpen}
        onClose={() => setIsWalletModalOpen(false)}
      />
    </>
  );
};
