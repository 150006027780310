import cn from 'classnames';
import { fromNano } from '@ton/core';

import { VERSUS_ITEM_PRICE, VERSUS_PHASE_ENTRY } from '@app/constants/versus';
import { PhaseEntryTicket, VersusPriceType } from '@app/types';
import { VersusPriceTag } from '@components';

import s from './TournamentEntryFee.module.scss';

interface TournamentEntryFeeProps {
  type: PhaseEntryTicket;
  variant?: 'default' | 'showPrice';
  priceTon?: string;
  priceStars?: number;
  priceTonDiscounted?: number;
  className?: string;
}

export const TournamentEntryFee: React.FC<TournamentEntryFeeProps> = ({
  type,
  variant = 'default',
  priceTon,
  priceStars,
  priceTonDiscounted,
  className,
}) => {
  const entryTicket = VERSUS_PHASE_ENTRY[type];

  return (
    <div className={cn(s.root, className)}>
      <img
        src={entryTicket.image}
        alt={entryTicket.title}
        className={s.entryImage}
      />
      {variant === 'default' && (
        <div className={s.entryInfo}>
          <div className={s.fee}>Entrance fee:</div>
          <div>{entryTicket.title}</div>
        </div>
      )}
      {variant === 'showPrice' && (
        <div className={s.priceInfo}>
          <span className={s.priceTitle}>{entryTicket.title}</span>
          <div className={s.priceWrapper}>
            {priceTon && (
              <VersusPriceTag
                price={+fromNano(priceTon)}
                priceFull={priceTonDiscounted}
                priceTon={priceTon}
              />
            )}
            {priceStars && (
              <div className={s.starsWrapper}>
                or
                <img
                  src={VERSUS_ITEM_PRICE[VersusPriceType.Stars].icon}
                  alt="stars"
                  className={s.starsIcon}
                />
                {priceStars}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
