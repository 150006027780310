import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button } from '@app/ui-kit';
import wormerineImage from '@media/wormerine.png';
import recruitsImage from '@media/buy-worms-card-recruits.png';
import pipesBg from '@media/buy-worms-card-bg-recruits.png';
import tournamentsImage from '@media/home-hero.png';
import managerImage from '@media/manager-hero.png';
import { useServiceState } from '@app/common/state';
import { versusTournamentService } from '@app/services';
import { ShopPageSections, ShopPageTabs } from '@app/pages/shop/ShopPage';

import s from './HomeHeroCard.module.scss';

interface HomeHeroCardProps {
  variant: 'earn' | 'becomeBoss';
  className?: string;
}

export const HomeHeroCard: React.FC<HomeHeroCardProps> = ({
  variant,
  className,
}) => {
  const { cryptoRewardAmount } = useServiceState(versusTournamentService, [
    'cryptoRewardAmount',
  ]);
  const navigate = useNavigate();

  const config = {
    earn: {
      title: `Earn ${cryptoRewardAmount} TON`,
      subtitle: 'SLAP Tournaments are LIVE!',
      image: tournamentsImage,
      imageConfig: { width: 303, height: 303, marginBottom: -2 },
      bgImage: pipesBg,
      buttonLabel: `Grab ${cryptoRewardAmount} ton!`,
      buttonAction: () => navigate('/tournament?tab=phase'),
    },
    becomeBoss: {
      title: `Become a Boss`,
      subtitle: 'Earn 20% from your slappers',
      image: managerImage,
      imageConfig: { width: 376, height: 317, marginBottom: -30 },
      bgImage: pipesBg,
      buttonLabel: `Earn Like a boss!`,
      buttonAction: () => navigate('/manager'),
    },
  };

  const currentConfig = config[variant];

  return (
    <div
      className={cn(s.root, className)}
      style={{
        backgroundImage: `url(${currentConfig.bgImage})`,
      }}
    >
      <h3 className={s.title}>{currentConfig.title}</h3>
      <h4 className={s.subtitle}>{currentConfig.subtitle}</h4>
      <img
        src={currentConfig.image}
        alt="worms"
        style={{
          width: `${currentConfig.imageConfig.width}px`,
          height: `${currentConfig.imageConfig.height}px`,
          marginBottom: `${currentConfig.imageConfig.marginBottom}px`,
        }}
        className={s.image}
      />

      <Button onClick={currentConfig.buttonAction} className={s.button}>
        {currentConfig.buttonLabel}
      </Button>
    </div>
  );
};
