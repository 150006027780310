import cn from 'classnames';

import s from './SettingsListCard.module.scss';
import { Fragment, ReactNode } from 'react';

interface SettingsListCardProps {
  title: string;
  children: ReactNode[];
  className?: string;
}

export const SettingsListCard: React.FC<SettingsListCardProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <h5 className={s.title}>{title}</h5>
      <div className={s.items}>
        {children.map((child, i) => (
          <Fragment>
            {child}
            {i !== children.length - 1 && <div className={s.divider} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
