import cn from 'classnames';

import heroImg from '@media/home-hero.png';
import { useServiceState } from '@app/common/state';
import { versusTournamentService } from '@app/services';
import { VersusPriceTag } from '../VersusPriceTag';
import { VersusPriceType } from '@app/types';
import { Button } from '@app/ui-kit';
import { ReactComponent as HelpIcon } from '@icons/help.svg';

import s from './TournamentsHeroBlock.module.scss';

interface TournamentsHeroBlockProps {
  className?: string;
}

export const TournamentsHeroBlock: React.FC<TournamentsHeroBlockProps> = ({
  className,
}) => {
  const { cryptoRewardAmount = 30 } = useServiceState(versusTournamentService, [
    'cryptoRewardAmount',
  ]);

  return (
    <div className={cn(s.root, className)}>
      <img src={heroImg} className={s.heroImg} alt="worm heroes" />
      <h1 className={s.title}>Slap Tournaments</h1>
      <div className={s.description}>
        Win
        <VersusPriceTag
          price={cryptoRewardAmount}
          priceType={VersusPriceType.Ton}
        />
        Every day
      </div>
      <Button
        btnType="link"
        href="https://telegra.ph/Slap-Tournament-Guide-11-26"
        target="_blank"
        rel="noreferrer"
        variant="unfilled"
        gap="4px"
        rightIcon={<HelpIcon />}
      >
        How it Works
      </Button>
    </div>
  );
};
