import React from 'react';
import cn from 'classnames';

import { ReactComponent as WarningIcon } from '@icons/warning.svg';
import { ReactComponent as InfoIcon } from '@icons/info.svg';

import s from './Input.module.scss';

interface InputProps {
  className?: string;
  errorMessage?: string;
  infoMessage?: string;
  value: string | undefined;
  isLoading?: boolean;
  onChange: (val: string) => void;
}

export const Input: React.FC<InputProps> = ({
  className,
  errorMessage,
  infoMessage,
  value = '',
  isLoading,
  onChange,
}) => {
  const renderMessage = (message: string) => {
    const parts = message.split(/(<b>.*?<\/b>)/gi);

    return parts.map((part, index) => {
      if (part.startsWith('<b>') && part.endsWith('</b>')) {
        const boldText = part.slice(3, -4);

        return <b key={index}>{boldText}</b>;
      }

      return part;
    });
  };

  return (
    <div className={cn(s.root, className)}>
      <input
        className={cn(s.input, {
          [s.error]: !!errorMessage,
          [s.loading]: isLoading,
        })}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {errorMessage && (
        <div className={s.errorMessage}>
          <WarningIcon width={20} height={20} color="#C8422C" />
          <span>{renderMessage(errorMessage)}</span>
        </div>
      )}
      {infoMessage && (
        <div className={s.infoMessage}>
          <InfoIcon width={20} height={20} color="#FFEBCC" />
          <span>{renderMessage(infoMessage)}</span>
        </div>
      )}
    </div>
  );
};
