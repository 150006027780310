import cn from 'classnames';

import { CryptoWithdrawal, CryptoWithdrawalStatus } from '@app/types';

import { CryptoPrizeItem } from './CryptoPrizeItem';
import { InfoBlock } from '@app/ui-kit';

import s from './WithdrawCryptoPrizeWidget.module.scss';
import { useServiceState } from '@app/common/state';
import { web3Service } from '@app/services';

interface WithdrawCryptoPrizeWidgetProps {
  items: CryptoWithdrawal[] | null;
  isSuspicious?: boolean;
  className?: string;
}

export const WithdrawCryptoPrizeWidget: React.FC<
  WithdrawCryptoPrizeWidgetProps
> = ({ items, isSuspicious, className }) => {
  const { tonPrice } = useServiceState(web3Service, ['tonPrice']);

  if (!items || !items?.length) {
    return null;
  }

  return (
    <div className={cn(s.root, className)}>
      <div className={s.label}>Withdraw your prize</div>
      <div className={s.items}>
        {items.map((prize) => (
          <CryptoPrizeItem
            key={prize.id}
            data={prize}
            tonPrice={tonPrice}
            isSuspicious={isSuspicious}
          />
        ))}
        {isSuspicious && (
          <InfoBlock
            message={suspiciousMsg}
            variant="warning"
            backgroundColor="var(--surface-accent-orange)"
          />
        )}
      </div>
    </div>
  );
};

const suspiciousMsg =
  "Our anti-cheat algorithm has flagged your account. Don't worry, if you weren't using any scripts, we'll release the prize shortly. Please contact us.";
