import { fromNano } from '@ton/core';

import { BottomSheet } from '@app/ui-kit';

import { PurchaseItemBlock } from '../PurchaseItemBlock';
import { VersusPriceType } from '@app/types';
import { ShopItemImagePreview } from '@components';

import s from './BuyTicketBottomSheet.module.scss';

interface BuyTicketBottomSheetProps {
  isOpen: boolean;
  itemCode: string;
  itemName: string;
  priceTon: string | undefined;
  priceStars: number | undefined;
  onClose: (result: boolean) => void;
}

export const BuyTicketBottomSheet: React.FC<BuyTicketBottomSheetProps> = ({
  isOpen,
  itemCode,
  itemName,
  priceTon,
  priceStars,
  onClose,
}) => {
  if (!priceTon || !priceStars) {
    return null;
  }

  return (
    <BottomSheet
      contentClassName={s.root}
      open={isOpen}
      bgColor="var(--surface-primary)"
      onClose={() => onClose(false)}
      insideCoverContent={
        isOpen ? (
          <ShopItemImagePreview code={itemCode} className={s.image} />
        ) : undefined
      }
    >
      <PurchaseItemBlock
        itemId={itemCode}
        itemName={itemName}
        price={+fromNano(priceTon)}
        priceTon={priceTon}
        priceStars={priceStars}
        priceType={VersusPriceType.Ton}
        className={s.purchaseItemBlock}
      />
    </BottomSheet>
  );
};
