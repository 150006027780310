import cn from 'classnames';

import wormCollectorCurrencyImage from '@media/worm-collector-currency.png';

import s from './WormCollectorPage.module.scss';
import { BackButton, WormsCollection } from '@app/components';
import { useServiceState } from '@app/common/state';
import { versusShopService } from '@app/services';
import { useNavigate } from 'react-router-dom';

interface WormCollectorPageProps {
  className?: string;
}

export const WormCollectorPage: React.FC<WormCollectorPageProps> = ({
  className,
}) => {
  const navigate = useNavigate();
  const { userCharacters } = useServiceState(versusShopService, [
    'userCharacters',
  ]);

  const totalRarity = userCharacters?.reduce(
    (prev, curr) => prev + curr.rarity,
    0,
  );

  return (
    <div className={cn(s.root, className)}>
      <BackButton onClick={() => navigate(-1)} />
      <h1 className={s.title}>Worm Collector</h1>
      <p className={s.description}>
        Exclusive worms and accessories available only for medals
      </p>
      <div className={s.totalWorms}>
        <img
          src={wormCollectorCurrencyImage}
          alt="currency"
          className={s.currency}
        />
        <span className={s.amount}>{totalRarity}</span>
      </div>

      <WormsCollection userCharacters={userCharacters} />
    </div>
  );
};
