import cn from 'classnames';

import { useState } from 'react';
import { versusCharacterService } from '@app/services';
import { ImageLoader } from '@components';

import s from './ShopItemImagePreview.module.scss';

interface ShopItemImagePreviewProps {
  code: string;
  className?: string;
}

export const ShopItemImagePreview: React.FC<ShopItemImagePreviewProps> = ({
  code,
  className,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const image = versusCharacterService.generateItemImage(code);

  return (
    <ImageLoader
      src={image}
      alt={code}
      className={cn(s.root, { [s.loaded]: imageLoaded }, className)}
      onLoad={setImageLoaded}
    />
  );
};
