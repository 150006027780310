import React from 'react';
import clsx from 'clsx';

import urlImgLock from '@media/lock.webp';
import checkMarkUrl from '@icons/check_mark.webp';
import tomatoIcon from '@media/tomato_coin.png';
import pendingIconUrl from '@icons/pending.svg';
import cucumberIcon from '@media/cucumber-icon.png';
import medalIcon from '@media/medal.png';

import styles from './BadgePoints.module.scss';
import { VERSUS_PHASE_ENTRY } from '@app/constants/versus';
import { PhaseEntryTicket } from '@app/types';

interface BadgePointsProps {
  className?: string;
  value: string | number;
  variant?:
    | 'default'
    | 'unfilled'
    | 'inactive'
    | 'completed'
    | 'pending'
    | 'header-cucumber'
    | 'header-tomato'
    | 'header-medals'
    | 'header-silver-tickets';
  size?: 'small' | 'large' | 'medium' | 'combined';
  img?: string;
}

export const BadgePoints: React.FC<BadgePointsProps> = ({
  className,
  value,
  variant = 'default',
  size = 'small',
}) => {
  const classNames = clsx(
    styles.root,
    className,
    styles[variant],
    styles[size],
  );
  const formattedNumber = value.toLocaleString('en-US');

  return (
    <div className={classNames}>
      {variant === 'inactive' && (
        <img className={styles.img} src={urlImgLock} alt="point" />
      )}
      {(variant === 'default' || variant === 'unfilled') && (
        <img className={styles.img} src={tomatoIcon} alt="point" />
      )}
      {variant === 'completed' && (
        <img className={styles.img} src={checkMarkUrl} alt="point" />
      )}

      {variant === 'pending' && (
        <img className={styles.img} src={pendingIconUrl} alt="point" />
      )}

      {variant === 'header-cucumber' && (
        <img className={styles.img} src={cucumberIcon} alt="point" />
      )}

      {variant === 'header-tomato' && (
        <img className={styles.img} src={tomatoIcon} alt="point" />
      )}
      {variant === 'header-silver-tickets' && (
        <img
          className={styles.img}
          src={VERSUS_PHASE_ENTRY[PhaseEntryTicket.SilverTicket].image}
          alt="point"
        />
      )}
      {variant === 'header-medals' && (
        <img className={styles.img} src={medalIcon} alt="point" />
      )}

      <span className={styles.value}>{formattedNumber}</span>
    </div>
  );
};
