import cn from 'classnames';

import rewardDiamondPhase from '@media/phase-diamond-reward.png';

import { BottomSheet, Button, InfoBlock } from '@app/ui-kit';

import s from './TournamentWinBottomSheet.module.scss';

interface TournamentWinBottomSheetProps {
  isOpen: boolean;
  amount: number;
  className?: string;
  onClose: () => void;
}

export const TournamentWinBottomSheet: React.FC<
  TournamentWinBottomSheetProps
> = ({ isOpen, amount, className, onClose }) => {
  return (
    <BottomSheet
      open={isOpen}
      withClose={false}
      contentClassName={s.bottomSheet}
      bgColor={'var(--surface-primary)'}
      className={cn(s.root, className)}
    >
      <img src={rewardDiamondPhase} alt="wallet" className={s.image} />
      <h2 className={s.title}>Congrats!</h2>

      <div className={s.description}>You’ve collected {amount} TON!</div>

      <InfoBlock
        className={s.info}
        message={
          'It will be available for withdraw in <b>24 hours</b> from now. We’re making sure everyone play a fair game and checking all the results manually'
        }
      />

      <Button onClick={onClose} variant="primary" className={s.button}>
        Cool! Thanks!
      </Button>
    </BottomSheet>
  );
};
