import React from 'react';
import cn from 'classnames';

import slapLogo from '@media/slap-logo.png';
import wormfareLogo from '@media/logo-vertical.png';
import noHamstersLogo from '@media/hamsters-not-allowed.png';
import { ProgressBar } from '../ProgressBar';

import s from './Preloader.module.scss';

interface PreloaderProps {
  progress: number;
  status: string;
  className?: string;
}

export const Preloader: React.FC<PreloaderProps> = ({
  progress,
  status,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <img src={wormfareLogo} alt="Wormfare logo" className={s.logoWormfare} />
      <img src={slapLogo} alt="Slap game logo" className={s.logoSlap} />
      <img src={noHamstersLogo} alt="no hamsters" className={s.noHamsters} />
      <div className={s.text}>Hamsters not allowed</div>
      <div className={s.status}>{status}</div>
      <ProgressBar
        minNumber={0}
        maxNumber={100}
        currentNumber={progress}
        fillBarColor={'var(--text-icn-accent-green)'}
        barBgColor={'var(--surface-tertiary)'}
        className={s.progress}
      />
    </div>
  );
};
