import { BottomSheet, Button } from '@app/ui-kit';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServiceState } from '@app/common/state';
import { userService } from '@app/services';
import { STREAK_BOTTOM_SHEET_SHOWN } from '@app/constants/general';

import s from './ClaimNicknameBottomSheet.module.scss';

export const ClaimNicknameBottomSheet: React.FC = () => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const streakShown =
      localStorage.getItem(STREAK_BOTTOM_SHEET_SHOWN) === 'true';

    if (!userProfile?.nickname && streakShown) {
      setIsOpen(true);
    }
  }, [userProfile?.nickname]);

  if (userProfile?.nickname) {
    return null;
  }

  return (
    <BottomSheet
      contentClassName={s.root}
      bgColor={'var(--surface-primary)'}
      withClose={false}
      open={isOpen}
    >
      <h2 className={s.title}>Claim Your Nickname</h2>
      <p className={s.description}>
        Choose a unique name to represent you in Slap Tournaments
      </p>
      <Button
        onClick={() => navigate('/change-nickname')}
        className={s.confirm}
      >
        Claim
      </Button>
      <Button variant="secondary" onClick={() => setIsOpen(false)}>
        Later
      </Button>
    </BottomSheet>
  );
};
