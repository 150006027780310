import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import cn from 'classnames';

import {
  BackButton,
  Page,
  PlayWormAvatar,
  SlapVersusMenu,
  TournamentIssuesBottomSheet,
  VersusHeader,
} from '@app/components';
import { isIOS } from '@app/utils';
import { InfoBlock } from '@app/ui-kit';
import { versusTournamentService } from '@app/services';
import { useServiceState } from '@app/common/state';

import s from './TournamentCharManagePage.module.scss';

export const TournamentCharManagePage: React.FC = () => {
  const navigate = useNavigate();
  const { tournamentData } = useServiceState(versusTournamentService, [
    'tournamentData',
  ]);
  const charCode = tournamentData?.character?.code;
  const accessoryName = tournamentData?.character?.accessories?.[0];

  useEffect(() => {
    if (tournamentData == null) {
      navigate('/tournament?tab=phase');
    }

    if (tournamentData?.canChangeCharacter === false) {
      navigate('/tournament-phase');
    }
  }, [navigate, tournamentData]);

  return (
    <Page
      isHaveBottomNav={false}
      className={s.root}
      style={{
        backgroundImage: `url(${versusTournamentService.getBattleBackground()})`,
      }}
    >
      <BackButton onClick={() => navigate(-1)} />
      <VersusHeader withShadow={false} />
      <div className={s.content}>
        <InfoBlock message={infoMessage} className={s.info} />
      </div>

      <PlayWormAvatar
        charCode={charCode}
        accessoryName={accessoryName}
        className={s.wormAvatar}
      />
      <SlapVersusMenu
        isTournament
        className={cn(s.menu, { [s.isIOS]: isIOS() })}
        changeButtonClassName={s.changeButton}
        equipButtonClassName={s.equipButton}
      />
      <TournamentIssuesBottomSheet />
    </Page>
  );
};

const infoMessage =
  'Attention. You can select, level up and equip your worm only now. When you enter the first battle, you’ll be with him until the end';
