import {
  Address,
  beginCell,
  storeMessage,
  TonClient,
  Transaction,
  Cell,
} from '@ton/ton';

interface WaitForTransactionOptions {
  address: string;
  boc: string;
  refetchInterval?: number;
  refetchLimit?: number;
  timeout?: number;
}

export const waitForTransaction = async (
  options: WaitForTransactionOptions,
  client: TonClient | undefined,
): Promise<Transaction | null> => {
  const { boc, refetchInterval = 2000, refetchLimit, address } = options;

  if (!client) {
    console.error('TON client is undefined');

    return null;
  }

  return new Promise((resolve, reject) => {
    let refetch = 0;
    const walletAddress = Address.parse(address);
    const interval = setInterval(async () => {
      refetch += 1;
      console.log('waiting transaction...');

      try {
        const transactions = await client.getTransactions(walletAddress, {
          limit: 10,
        });

        for (const tx of transactions) {
          const inMsg = tx.inMessage;

          if (inMsg?.info.type === 'external-in') {
            const inBOC = inMsg.body;

            if (typeof inBOC === 'undefined') {
              console.error('Invalid external message');
              continue;
            }

            const extHash = Cell.fromBase64(boc).hash().toString('hex');
            const inHash = beginCell()
              .store(storeMessage(inMsg))
              .endCell()
              .hash()
              .toString('hex');

            if (extHash === inHash) {
              clearInterval(interval);
              console.log('Transaction match found');
              resolve(tx);

              return;
            }
          }
        }
      } catch (error) {
        console.error('Error in client call:', error);
      }

      if (refetchLimit && refetch >= refetchLimit) {
        clearInterval(interval);
        console.warn('Transaction not found within refetch limit');
        resolve(null);
      }
    }, refetchInterval);
  });
};
