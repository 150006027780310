import cn from 'classnames';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { VersusAvatar, VersusPriceTag } from '@components';
import { referralService, versusCharacterService } from '@app/services';
import { VersusPriceType } from '@app/types';
import { useServiceState } from '@app/common/state';
import { Spinner } from '@app/ui-kit';

import s from './ManagerYourTopFighters.module.scss';

interface ManagerYourTopFightersProps {
  className?: string;
}

export const ManagerYourTopFighters: React.FC<ManagerYourTopFightersProps> = ({
  className,
}) => {
  const { referrals } = useServiceState(referralService, ['referrals']);

  useEffect(() => {
    if (!referrals) {
      referralService.getInitialReferrals();
    }
  }, [referrals]);

  const totalReferralsAmount = referrals?.total || 0;
  const currentReferralsAmount = referrals?.items?.length || 0;

  return (
    <div className={cn(s.root, className)}>
      <h5 className={s.sectionTitle}>Your top fighters</h5>
      {referrals === null && <Spinner className={s.initialLoader} />}

      <InfiniteScroll
        dataLength={referrals?.items?.length || 0}
        next={() => referralService.loadMoreReferrals()}
        hasMore={currentReferralsAmount < totalReferralsAmount}
        loader={<Spinner className={s.loader} />}
        className={s.items}
      >
        {referrals && currentReferralsAmount === 0 && (
          <div className={s.emptyList}>You don't have any fighters</div>
        )}
        {referrals?.items?.map((el, i) => (
          <Fighter
            key={i}
            code={el.charCode}
            leagueBadge={el.leagueIcon}
            name={el.username}
            wins={el.wins}
            loses={el.loses}
            ton={el.bonusTonForParentUser}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

interface FighterProps {
  code: string;
  leagueBadge: string;
  name: string;
  wins: number;
  loses: number;
  ton: number;
}

const Fighter: React.FC<FighterProps> = ({
  code,
  leagueBadge,
  name,
  wins,
  loses,
  ton,
}) => {
  const playerAssets = versusCharacterService.generateCharAssets(code);

  return (
    <div className={s.fighter}>
      <VersusAvatar
        wormImage={playerAssets?.avatarSm || ''}
        leagueImage={leagueBadge}
        className={s.avatar}
      />
      <div className={s.fighterInfo}>
        <span className={s.fighterName}>{name}</span>
        <span className={s.stat}>
          {wins} wins / {loses} loses
        </span>
      </div>
      <VersusPriceTag
        price={ton}
        priceType={VersusPriceType.Ton}
        tagClassName={cn(s.amountTag, { [s.grey]: ton <= 0 })}
      />
    </div>
  );
};
