import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button } from '@app/ui-kit';
import {
  TournamentStage,
  TournamentRewardType,
  PhaseEntryTicket,
} from '@app/types';
import { VERSUS_PHASE_REWARDS } from '@app/constants/versus';
import {
  GoldenPhaseCompletedReward,
  TonPhaseReward,
  TournamentWinBottomSheet,
} from '@components';
import { versusTournamentService } from '@app/services';
import diamondTicket from '@media/diamond-ticket-stage-reward.png';
import { useServiceState } from '@app/common/state';

import s from './PhaseReward.module.scss';

interface PhaseRewardProps {
  phaseData: TournamentStage;
  isFinalBattle: boolean;
  className?: string;
}

export const PhaseReward: React.FC<PhaseRewardProps> = ({
  phaseData,
  isFinalBattle,
  className,
}) => {
  const navigate = useNavigate();
  const { cryptoRewardAmount } = useServiceState(versusTournamentService, [
    'cryptoRewardAmount',
  ]);

  const [isWinTournament, setIsWinTournament] = useState(false);
  const { title, rewards, isCompleted, rewardsDescription } = phaseData;

  const rewardsToShow = [
    TournamentRewardType.GoldenTicket,
    TournamentRewardType.DiamondTicket,
    TournamentRewardType.Ton,
  ];

  const mainReward = rewards.find((reward) =>
    rewardsToShow.includes(reward.type),
  );

  const claimableTonAmount = mainReward?.amount || 0;
  const ticketReward = phaseData.rewards.find((el) =>
    el.type.toLowerCase().includes('ticket'),
  );
  const isExtendedGoldenPhaseMode =
    mainReward?.type === TournamentRewardType.Ton && ticketReward;

  console.log(claimableTonAmount);

  const collectReward = async () => {
    if (isFinalBattle) {
      setIsWinTournament(true);

      return;
    }

    await handleExitPhase();
  };

  const handleExitPhase = async () => {
    await versusTournamentService.getTournament();
    navigate('/tournament?tab=phase');
  };

  if (!mainReward) {
    return null;
  }

  const getRewardComponent = () => {
    if (mainReward.type === TournamentRewardType.GoldenTicket) {
      return (
        <img
          src={VERSUS_PHASE_REWARDS[TournamentRewardType.GoldenTicket].image}
          alt="golden ticket"
          className={s.rewardImage}
        />
      );
    }

    if (isExtendedGoldenPhaseMode && !isCompleted) {
      return (
        <div className={s.diamondReward}>
          <TonPhaseReward amount={claimableTonAmount} size={'small'} />
          <span>or</span>
          <img
            src={diamondTicket}
            alt="diamond ticket"
            className={s.diamondImage}
          />
        </div>
      );
    }

    if (
      mainReward.type === TournamentRewardType.DiamondTicket &&
      !isExtendedGoldenPhaseMode
    ) {
      return (
        <img
          src={VERSUS_PHASE_REWARDS[TournamentRewardType.DiamondTicket].image}
          alt="diamond ticket"
          className={s.rewardImage}
        />
      );
    }

    if (
      mainReward.type === TournamentRewardType.Ton &&
      !isExtendedGoldenPhaseMode
    ) {
      return <TonPhaseReward amount={mainReward.amount} size={'normal'} />;
    }

    return null;
  };

  const getCompletedPhaseComponent = () => {
    if (isCompleted && !isExtendedGoldenPhaseMode) {
      return <Button onClick={collectReward}>Collect</Button>;
    }

    if (isCompleted && phaseData.stage !== 2 && isExtendedGoldenPhaseMode) {
      return <Button onClick={collectReward}>Collect</Button>;
    }

    if (isCompleted && isExtendedGoldenPhaseMode) {
      return (
        <GoldenPhaseCompletedReward
          claimNowAmount={claimableTonAmount}
          ticketType={ticketReward.type as unknown as PhaseEntryTicket}
          winTonAmount={cryptoRewardAmount}
          onSuccess={handleExitPhase}
        />
      );
    }

    return (
      <div className={s.description}>
        {rewardsDescription || mainReward.description}
      </div>
    );
  };

  return (
    <div className={cn(s.root, className)}>
      <h1 className={s.phaseTitle}>{title}</h1>
      {getRewardComponent()}
      {getCompletedPhaseComponent()}
      <TournamentWinBottomSheet
        isOpen={isWinTournament}
        amount={cryptoRewardAmount}
        onClose={handleExitPhase}
      />
    </div>
  );
};
