import { BottomSheet, Button } from '@app/ui-kit';

import { useNavigate } from 'react-router-dom';
import s from './TournamentIssuesBottomSheet.module.scss';
import { configService } from '@app/services';

export const TournamentIssuesBottomSheet: React.FC = () => {
  const navigate = useNavigate();

  return (
    <BottomSheet
      contentClassName={s.root}
      bgColor={'var(--surface-primary)'}
      withClose={false}
      open={configService.tournamentUnavailable}
    >
      <h2 className={s.title}>Tournament unavailable</h2>
      <p className={s.description}>
        We are experiencing a high load of players at the moment. Please give us
        20 minutes to upgrade the power of our servers.
      </p>
      <Button onClick={() => navigate('/')} className={s.confirm}>
        Go Home
      </Button>
    </BottomSheet>
  );
};
