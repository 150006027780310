import rankProgressFrame from '@media/league/rank-progress-frame-battle-result.svg';
import matchmakingForeground from '@media/matchmaking-foreground.png';
import defeatHero from '@media/battle-result/defeat-hero.png';
import defeatText from '@media/battle-result/defeat-text.png';
import escapedHero from '@media/battle-result/escaped-hero.png';
import escapedText from '@media/battle-result/escaped-text.png';
import tieHero from '@media/battle-result/tie-hero.png';
import tieText from '@media/battle-result/tie-text.png';
import victoryHero from '@media/battle-result/victory-hero.png';
import victoryText from '@media/battle-result/victory-text.png';
import battleBackground from '@media/battle-background.png';
import timerCover from '@media/versus/timer-cover.png';
import wheelCover from '@media/versus/wheel-cover.png';
import arrowImg from '@media/versus/hand-arrow.png';
import iconPower from '@media/skills/power.png';
import iconReaction from '@media/skills/reaction.png';
import iconResilience from '@media/skills/resilience.png';
import slapImage from '@media/slap-2-card.png';
import tonWalletImg from '@media/ton-wallet.png';
import tournamentBg1 from '@media/tournament-background-ring.png';
import tournamentIndicatorCover1 from '@media/versus/wheel-cover-stage-1.png';

export const imagesToPreload = [
  rankProgressFrame,
  matchmakingForeground,
  defeatHero,
  defeatText,
  escapedHero,
  escapedText,
  tieHero,
  tieText,
  victoryHero,
  victoryText,
  battleBackground,
  timerCover,
  wheelCover,
  arrowImg,
  iconPower,
  iconReaction,
  iconResilience,
  slapImage,
  tonWalletImg,
  tournamentBg1,
  tournamentIndicatorCover1,
];
