import { useEffect, useRef } from 'react';
import { animate, m, useMotionValue, useTransform } from 'framer-motion';
import clsx from 'clsx';

import { numberWithCommas } from '@app/utils';

import jamIcon from '@media/jam.png';
import tomatoIcon from '@media/tomato_coin.png';
import cucumberIcon from '@media/cucumber-icon.png';
import medalsIcon from '@media/medal.png';

import s from './CoinsIndicator.module.scss';

interface CoinsIndicatorProps {
  className?: string;
  variant?: 'tomato' | 'cucumber' | 'medals';
  value: number;
  animateDuration?: number;
  animateOnChange?: boolean;
  fontSize?: number;
  zIndex?: number;
}

export const CoinsIndicator: React.FC<CoinsIndicatorProps> = ({
  className,
  variant = 'tomato',
  value,
  animateDuration = 0.5,
  animateOnChange = true,
  fontSize,
  zIndex,
}) => {
  const animatedTries = useMotionValue(0);
  const roundedAnimatedTries = useTransform(
    animatedTries,
    (latest) => `${numberWithCommas(Math.round(latest))}`,
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!animateOnChange && isFirstRender.current) {
      animatedTries.set(value);
    } else {
      const controls = animate(animatedTries, value, {
        duration: animateDuration,
      });

      return controls.stop;
    }

    isFirstRender.current = false;
  }, [value, animatedTries, animateOnChange, animateDuration]);

  const calculateDynamicWidth = (text: string) => {
    return text.split('').reduce((acc, char) => {
      return acc + (char === ',' ? 14 : char.match(/[0-9]/) ? 28 : 0);
    }, 0);
  };

  const minWidth = `${calculateDynamicWidth(roundedAnimatedTries.get()) / 48}em`;

  return (
    <div className={clsx(s.root, className, s[variant])} style={{ zIndex }}>
      <img src={icons[variant]} alt="currency" className={s.img} />
      <m.div className={s.coins} style={{ minWidth, fontSize }}>
        {roundedAnimatedTries}
      </m.div>
    </div>
  );
};

const icons = {
  tomato: tomatoIcon,
  cucumber: cucumberIcon,
  medals: medalsIcon,
};
