import cn from 'classnames';

import battleResultFrame from '@media/league/rank-progress-frame-battle-result.svg';
import menuFrame from '@media/league/rank-progress-frame-menu.svg';

import { ProgressBar } from '@components/ProgressBar';

import s from './RankProgressBar.module.scss';

interface RankProgressBarProps {
  currentProgress: number;
  variant: 'battleResult' | 'menu' | 'leagueProgress';
  min?: number;
  max?: number;
  showValues?: boolean;
  transitionDurationMs?: number;
  className?: string;
}

export const RankProgressBar: React.FC<RankProgressBarProps> = ({
  currentProgress,
  variant,
  min = 0,
  max = 7,
  showValues = true,
  transitionDurationMs,
  className,
}) => {
  const frames = {
    menu: menuFrame,
    battleResult: battleResultFrame,
    leagueProgress: battleResultFrame,
  };

  return (
    <div className={cn(s.root, s[variant], className)}>
      <ProgressBar
        fillBarColor={'var(--action-primary-default)'}
        barBgColor={'var(--surface-secondary)'}
        minNumber={min}
        maxNumber={max}
        currentNumber={currentProgress}
        variant="custom"
        transitionDurationMs={transitionDurationMs}
        className={s.bar}
      />
      <img src={frames[variant]} alt="frame" className={s.frame} />
      {showValues && (
        <div className={s.progressValues}>
          {currentProgress}/{max}
        </div>
      )}
    </div>
  );
};
