import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BackButton,
  BottomButton,
  CoinsIndicator,
  OnboardingAlert,
  Page,
  RankWithProgress,
  VersusBattleResultHero,
} from '@app/components';
import { onboardingService, userService, versusService } from '@app/services';
import { useServiceState } from '@app/common/state';

import s from './VersusBattleResultPage.module.scss';

export const VersusBattleResultPage: React.FC = () => {
  const [shouldAnimateBalance, setShouldAnimateBalance] = useState(false);
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { matchOutcome, battleResultInfo } = useServiceState(versusService, [
    'matchOutcome',
    'battleResultInfo',
  ]);

  const { onboardingMessage, isOnboarding } = useServiceState(
    onboardingService,
    ['onboardingMessage', 'isOnboarding'],
  );

  const maxProgress = userProfile?.versus?.rating.progressMax;

  const navigate = useNavigate();

  useEffect(() => {
    if (matchOutcome == null) {
      navigate('/play');
    }
  }, [matchOutcome, navigate]);

  useEffect(() => {
    setShouldAnimateBalance(true);
  }, []);

  const handleNext = () => {
    if (isOnboarding) {
      onboardingService.nextOnboardingStep();
    } else {
      navigate('/skills/battle-result');
    }
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={() => navigate('/play')} />
      {matchOutcome != null && (
        <VersusBattleResultHero battleResult={matchOutcome} />
      )}
      <div className={s.content}>
        <RankWithProgress
          variant="battleResult"
          level={battleResultInfo?.leagueLevel}
          currentProgress={battleResultInfo?.leagueProgress}
          max={maxProgress}
          showProgressValues={true}
          zIndex={onboardingMessage?.zIndexLevel}
          rankImage={battleResultInfo?.leagueImage}
          transitionDurationsMs={versusService.progressUpdateDelay + 20}
        />
        <div
          className={s.balance}
          style={{ zIndex: onboardingMessage?.zIndexBalance }}
        >
          Tomato Balance
        </div>
        <CoinsIndicator
          variant="tomato"
          value={battleResultInfo?.balance || 0}
          animateOnChange={shouldAnimateBalance}
          fontSize={48}
          zIndex={onboardingMessage?.zIndexBalance}
        />
      </div>
      <BottomButton onClick={handleNext} />
      <OnboardingAlert
        className={s.onboarding}
        onboardingMessage={onboardingMessage}
        onClick={() => onboardingService.nextOnboardingStep()}
      />
    </Page>
  );
};
