import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';
import { AuthService } from '../AuthService';
import { mapFriendRawToFriend } from './mappers/friend';
import type {
  UserProfile,
  Friend,
  FriendRaw,
  PaginatedResponse,
  Streak,
} from '@app/types';
import { CacheService } from '../CacheService';
import { ConfigService } from '../ConfigService';

@Injectable()
@MakeObservable
export class UserService {
  @observable
  public isAuth = false;

  @observable
  public isTgPremium = false;

  @observable
  public isProfileLoading = false;

  @observable
  public userProfile: UserProfile | null = null;

  @observable
  public streak: Streak | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly cacheService: CacheService,
    private readonly configService: ConfigService,
  ) {
    this.isAuth = this.authService.isAuth();
  }

  public async login(refUserId?: number): Promise<void> {
    const { accessToken } = await this.apiService.post<
      { accessToken: string },
      { initData: string; refUserId?: number }
    >('/auth/login', {
      initData: this.configService.initData,
      refUserId: refUserId || undefined,
    });

    this.authService.setCredentials(accessToken);
  }

  public async fetchProfile(): Promise<void> {
    this.isProfileLoading = true;
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');

    document.cookie = `referral=${this.userProfile.invitedByUserId}; path=/;`;
    document.cookie = `tomatoes_balance=${this.userProfile.score}; path=/;`;
    document.cookie = `cucumbers_balance=${this.userProfile.versus?.score ?? 0}; path=/;`;

    this.isProfileLoading = false;
  }

  public async connectWallet(address: string, hash: string): Promise<void> {
    await this.apiService.post<{ address: string; hash: string }>(
      '/user/connect-wallet',
      {
        address,
        hash,
      },
    );
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }

  public async disconnectWallet(): Promise<void> {
    await this.apiService.post('/user/disconnect-wallet');
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }
  public async authStreak(): Promise<void> {
    const streak = await this.apiService.post<Streak>('/auth-streak');

    this.streak = streak;
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }

  getRankProgressFromRating(rating: number) {
    const maxProgress = this.userProfile?.versus?.rating.progressMax ?? 7;
    const rank = Math.floor(rating / maxProgress);
    const progress = rating % maxProgress;

    return { rank, progress };
  }

  getRatingFromRankProgress(
    rank: number,
    progress: number,
    progressMax: number,
  ) {
    return rank * progressMax + progress;
  }

  updateProfile(profile: UserProfile) {
    this.userProfile = profile;
  }

  setTgIdForAnalytics() {
    const tgUserData = this.extractTgUserData();

    if (tgUserData) {
      document.cookie = `tg_user_id=${tgUserData.id}; path=/;`;
    }
  }

  extractTgUserData(): WebAppUser | null {
    const parsedInitData = new URLSearchParams(this.configService.initData);
    const user = parsedInitData.get('user');

    if (user) {
      return JSON.parse(decodeURIComponent(user));
    }

    return null;
  }

  getParseTgUserData() {
    const tgUserData = this.extractTgUserData();

    if (!tgUserData) {
      return;
    }

    const isTgPremium = Boolean(tgUserData?.is_premium);

    document.cookie = `is_tg_premium=${isTgPremium}; path=/;`;

    return tgUserData;
  }

  async updateServerTgUserData() {
    const tgData = this.getParseTgUserData();
    const isTgPremium = Boolean(tgData?.is_premium);
    const isServerPremium = this.userProfile?.isPremium;
    const tgUserName = tgData?.username;
    const serverTgUserName = this.userProfile?.username;

    if (isTgPremium !== isServerPremium || tgUserName !== serverTgUserName) {
      await this.login();
      await this.fetchProfile();
    }
  }

  async sendTonWalletAddress(tonAddress: string) {
    const storageKey = 'userTonAddress';
    const savedAddress = localStorage.getItem(storageKey);

    if (tonAddress === savedAddress) {
      return;
    }

    localStorage.setItem(storageKey, tonAddress);

    await this.apiService.patch('/user', { tonAddress }, { useCache: true });
  }

  async changeNickname(nickname: string) {
    this.userProfile = await this.apiService.patch<UserProfile>('/user', {
      nickname,
    });
  }

  async checkNicknameAvailability(nickname: string) {
    const isAvailable = await this.apiService.get<boolean>(
      `/user/is-nickname-available?nickname=${nickname}`,
    );

    return isAvailable;
  }
}
