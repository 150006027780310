import TagManager from 'react-gtm-module';

import { configService, userService } from './services';

export const setup = () => {
  if (!configService.gTag) {
    console.error('GTAG NOT PROVIDED ANALYTICS WILL NOT INIT');
  }

  userService.setTgIdForAnalytics();
  userService.getParseTgUserData();

  const tagManagerArgs = {
    gtmId: configService.gTag as string,
  };

  TagManager.initialize(tagManagerArgs);
};
