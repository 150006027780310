import cn from 'classnames';
import { useState } from 'react';

import {
  TonPhaseReward,
  TournamentEntryFee,
  TournamentWinBottomSheet,
} from '@components';
import { Button } from '@app/ui-kit';
import { PhaseEntryTicket } from '@app/types';
import { apiService, versusTournamentService } from '@app/services';

import s from './GoldenPhaseCompletedReward.module.scss';
import { VERSUS_PHASE_ENTRY } from '@app/constants/versus';
import { notifyError } from '@app/ui-kit/ToastNotifications';

interface GoldenPhaseCompletedRewardProps {
  claimNowAmount: number;
  ticketType: PhaseEntryTicket;
  winTonAmount: number;
  className?: string;
  onSuccess: () => void;
}

export const GoldenPhaseCompletedReward: React.FC<
  GoldenPhaseCompletedRewardProps
> = ({ claimNowAmount, ticketType, winTonAmount, className, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const entryTicket = versusTournamentService.getTicketPriceByType(ticketType);

  const collectTon = async () => {
    setIsLoading(true);

    try {
      await versusTournamentService.claimGoldenStageReward();
      setIsOpen(true);
    } catch (e) {
      apiService.notifyError(e);
    }

    setIsLoading(false);
  };

  const getTicket = () => {
    onSuccess();
  };

  return (
    <div className={cn(s.root, className)}>
      <TonPhaseReward amount={claimNowAmount} size={'small'} />
      <Button
        variant="tertiary"
        bgColor="var(--action-primary-default)"
        disabled={isLoading}
        onClick={collectTon}
        className={s.collect}
      >
        Collect
      </Button>
      <div className={s.or}>OR</div>
      <div className={s.card}>
        <div className={s.cardTitle}>
          Get {VERSUS_PHASE_ENTRY[ticketType].title} and <span>Win 30 TON</span>
        </div>
        <TonPhaseReward amount={winTonAmount} size={'small'} />
        <div className={s.priceWrapper}>
          <TournamentEntryFee
            type={ticketType}
            variant={'showPrice'}
            priceTon={'0'}
            priceTonDiscounted={entryTicket?.price}
          />
        </div>
        <Button onClick={getTicket}>
          Get ticket and win {winTonAmount} TON
        </Button>
      </div>
      <TournamentWinBottomSheet
        isOpen={isOpen}
        amount={claimNowAmount}
        onClose={onSuccess}
      />
    </div>
  );
};
