import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './polyfills';

import { AnimatePresence } from 'framer-motion';
import {
  GeneralLayout,
  TomatoHowItWorkPage,
  TomatoQuestsPage,
  ShopPage,
  SlapVersusPage,
  MatchMakingPage,
  VersusBattleResultPage,
  SkillsPage,
  LeagueProgressionPage,
  LeagueLeaderboardPage,
  EquipPage,
  HomePage,
  LeaderboardComingPage,
  SelectWormPage,
  ShopWormPreviewPage,
  ShopAccessoryPreviewPage,
  TournamentPage,
  ShopItemPreviewPage,
  TournamentCharManagePage,
  TournamentPhasePage,
  TournamentBattleResultPage,
  ProfileSettingsPage,
  ChangeNicknamePage,
  ManagerPage,
  WormCollectorPage,
} from '@app/pages';
import { LazyMotion, domAnimation } from 'framer-motion';
import { apiService, configService, userService } from './services';
import {
  DesktopStubScreen,
  Preloader,
  TomatoRewardBottomSheet,
  Maintenance,
} from '@components';
import { isMobile } from '@app/utils';
import { usePreloadResources } from './hooks';
import { useAuth } from './hooks/useAuth';
import { useServiceState } from './common/state';
import { StartParams, parseStartParams } from './utils/parseStartParams';
import { isAxiosError } from 'axios';
import { notifySuccess, notifyError } from '@ui-kit/ToastNotifications';
import { VersusBattlePage } from './pages/versus-battle';
import { VersusMusicPlayer } from './components/VersusMusicPlayer/VersusMusicPlayer';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';

const startParams = parseStartParams<StartParams>();

const refUserId = parseInt(startParams?.ref || '');
const walletAddress = startParams?.address || '';
const walletAddressHash = startParams?.hash || '';

const App: React.FC = () => {
  const { isLoading } = useAuth(refUserId);
  const location = useLocation();
  const { allResourcesLoaded, progress, loadingMessage } =
    usePreloadResources(isLoading);

  const { maintainSession } = useServiceState(apiService);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (walletAddress && walletAddressHash) {
      userService
        .connectWallet(walletAddress, walletAddressHash)
        .then(() => {
          notifySuccess('Wallet connected successfully!');
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            notifyError(error.response?.data.message || error.message, {
              autoClose: 5000,
            });
          } else {
            notifyError(
              `Something went wrong!\n${error.message}\nPlease, try to reload the page.`,
              { autoClose: 5000 },
            );
          }
        });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.Telegram?.WebApp.expand();
      window.Telegram.WebApp.disableVerticalSwipes();
    }, 0);
  }, []);

  if (!isMobile() && !configService.isDev) {
    return <DesktopStubScreen />;
  }

  if ((isLoading || !allResourcesLoaded) && !maintainSession) {
    return <Preloader progress={progress} status={loadingMessage} />;
  }

  if (maintainSession) {
    return <Maintenance timestamp={maintainSession} />;
  }

  return (
    <TonConnectUIProvider
      manifestUrl="https://clicker.wormfare.com/tonconnect-manifest.json"
      uiPreferences={{
        colorsSet: {
          [THEME.DARK]: {
            accent: 'var(--text-primary)',
            background: {
              primary: 'var(--surface-bg)',
              secondary: 'var(--surface-secondary)',
              segment: 'var(--text-primary)',
              tint: 'var(--surface-primary)',
            },
          },
        },
      }}
      actionsConfiguration={{
        returnStrategy: 'back',
        twaReturnUrl: configService.isLocal
          ? undefined
          : (`${configService.botLink}/start` as `${string}://${string}`),
      }}
    >
      <LazyMotion features={domAnimation}>
        <TomatoRewardBottomSheet />
        <VersusMusicPlayer>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<HomePage />} />
              <Route path="/battle" element={<VersusBattlePage />} />

              <Route path="/play" element={<SlapVersusPage />} />
              <Route path="/match-making" element={<MatchMakingPage />} />
              <Route
                path="/battle-result"
                element={<VersusBattleResultPage />}
              />
              <Route path="/skills/:mode" element={<SkillsPage />} />
              <Route
                path="/league-progression"
                element={<LeagueProgressionPage />}
              />
              <Route
                path="/league-leaderboard"
                element={<LeaderboardComingPage />}
              />
              <Route path="/equip/:mode" element={<EquipPage />} />
              <Route path="/select-worm/:mode" element={<SelectWormPage />} />
              <Route
                path="/shop-worm-preview/:code"
                element={<ShopWormPreviewPage />}
              />
              <Route
                path="/shop-accessory-preview/:id"
                element={<ShopAccessoryPreviewPage />}
              />
              <Route
                path="/shop-item-preview/:id/:navBackSection"
                element={<ShopItemPreviewPage />}
              />
              <Route path="/tournament" element={<TournamentPage />} />

              <Route
                path="/tournament-char-manage"
                element={<TournamentCharManagePage />}
              />
              <Route
                path="/tournament-phase"
                element={<TournamentPhasePage />}
              />
              <Route
                path="/tournament-battle-result"
                element={<TournamentBattleResultPage />}
              />
              <Route
                path="/profile-settings"
                element={<ProfileSettingsPage />}
              />
              <Route path="/change-nickname" element={<ChangeNicknamePage />} />
              <Route path="/manager" element={<ManagerPage />} />
              <Route path="/worm-collector" element={<WormCollectorPage />} />
              <Route element={<GeneralLayout />}>
                <Route
                  path="/tomato-how-it-work"
                  element={<TomatoHowItWorkPage />}
                />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/quests" element={<TomatoQuestsPage />} />
                <Route path="*" element={<HomePage />} />
              </Route>
            </Routes>
          </AnimatePresence>
        </VersusMusicPlayer>
      </LazyMotion>
    </TonConnectUIProvider>
  );
};

export default App;
