import cn from 'classnames';
import { ReactElement } from 'react';
import React from 'react';

import s from './SettingsListItem.module.scss';

interface SettingsListItemProps {
  image: string;
  title: string;
  value: string | number | null | ReactElement;
  className?: string;
  onClick?: () => void;
}

export const SettingsListItem: React.FC<SettingsListItemProps> = ({
  image,
  title,
  value,
  className,
  onClick,
}) => {
  const isComponent = React.isValidElement(value);

  return (
    <div
      className={cn(s.root, { [s.interactive]: Boolean(onClick) }, className)}
      onClick={onClick}
    >
      <img src={image} className={s.image} alt={title} />
      <span className={s.title}>{title}</span>
      {isComponent && value}
      {!isComponent && value !== null && (
        <span className={s.value}>{value}</span>
      )}
    </div>
  );
};
