import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as UrlAlertIconWarning } from '@icons/warning.svg';

import s from './ImageLoader.module.scss';
import { Spinner } from '@app/ui-kit';

interface ImageLoaderProps {
  src: string;
  alt: string;
  loaderColor?: string;
  className?: string;
  spinnerClassName?: string;
  onLoad?: (loaded: true) => void;
}

export const ImageLoader: React.FC<ImageLoaderProps> = ({
  src,
  alt,
  loaderColor,
  className,
  spinnerClassName,
  onLoad,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageLoad = () => {
    setLoading(false);
    onLoad?.(true);
  };

  const handleImageError = () => {
    setError(true);
    setLoading(false);
    onLoad?.(true);
  };

  useEffect(() => {
    const img = imgRef.current;

    if (!img?.complete) {
      setLoading(true);
    }
  }, [src]);

  return (
    <div className={cn(s.root, className)}>
      {loading && !error && (
        <Spinner
          className={cn(s.spinner, spinnerClassName)}
          color={loaderColor}
        />
      )}
      {!error && (
        <img
          ref={imgRef}
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          className={cn(s.image, { [s.visible]: !loading })}
        />
      )}
      {error && <UrlAlertIconWarning color="red" className={s.errorIcon} />}
    </div>
  );
};
