import cn from 'classnames';

import { versusCharacterService } from '@app/services';
import { VersusPriceTag } from '../VersusPriceTag';
import { VersusPriceType } from '@app/types';
import { useEffect, useState } from 'react';

import s from './TournamentWinner.module.scss';
import { fromNano } from '@ton/core';

interface TournamentWinnerProps {
  charCode: string;
  accessoryCode: string;
  cryptoAmount: string;
  name: string;
  place: number;
  className?: string;
}

export const TournamentWinner: React.FC<TournamentWinnerProps> = ({
  charCode,
  accessoryCode,
  cryptoAmount,
  name,
  place,
  className,
}) => {
  const [accessory, setAccessory] = useState<{
    blob: string;
    itemLocation: string;
    key: string;
  }>();
  const charAssets = versusCharacterService.generateCharAssets(charCode);

  useEffect(() => {
    const getAccessory = async () => {
      if (!accessoryCode) {
        return;
      }

      const accessoryData =
        await versusCharacterService.getItemFromIndexedDB(accessoryCode);

      setAccessory(accessoryData);
    };

    getAccessory();
  }, [accessoryCode]);

  return (
    <div className={cn(s.root, className)}>
      <div
        className={cn(s.worm, {
          [s.first]: place === 1,
          [s.second]: place === 2,
          [s.third]: place === 3,
        })}
        style={{ backgroundImage: `url(${charAssets?.avatarLg})` }}
      >
        {accessory && (
          <div
            style={{ backgroundImage: `url(${accessory.blob})` }}
            className={cn(
              s.accessory,
              s[accessory.itemLocation?.toLowerCase()],
            )}
          />
        )}
      </div>
      <div className={s.info}>
        <div className={s.name}>{name}</div>
        <VersusPriceTag
          price={+fromNano(cryptoAmount)}
          priceType={VersusPriceType.Ton}
        />
      </div>
    </div>
  );
};
